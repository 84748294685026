import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

const GuestProfile = () => {
    const { userId } = useParams();
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    
    useEffect(() => {
        const fetchBookingDetails = async () => {
            setLoading(true);
            let formData = new FormData();
            formData.append('id', userId);
    
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.getUserDetails}`,
                    data: formData
                });
    
                if (response.data.status === 'ok') {
                    setUserDetails(response.data.profile);
                } else {
                    setError('No guest profile found.');
                }
            } catch (err) {
                setError('An error occurred while fetching guest data.');
            } finally {
                setLoading(false);
            }
        };

        fetchBookingDetails();
    }, [userId]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>No Guest Profile Found.</p>;
    }

    return (
        <div>
            <Helmet>
                <title>Guest Details</title>
                <meta name="description" content="" />
            </Helmet>

            <div className='booking-detail-section'>
                {userDetails && (
                    <>
                        <div className='category-img-section' style={{border:'2px solid #A9A9A9'}}>
                            <div className='gdpimgb'>
                                <img src={userDetails.customer_pic_image_url} alt='' className='img-fluid' />
                            </div>
                        </div>
                        <span className='b-line'></span>

                        <div>
                            <div className='bd-box'>
                                <strong>Guest info</strong>
                                <div className='bd-sub-box'>
                                    <div className='bd-ssb-box'>
                                        <img src={'/assets/img/icons/profile_icon.gif'} alt="" className="bd-icon" />
                                        <p>{userDetails.customer_name}</p>
                                    </div>
                                </div>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/whatsapp_icon_r.svg'} alt="" className="bd-icon" />
                                    <p>{userDetails.customer_mobile_number}</p>
                                </div>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/workplace_icon.svg'} alt="" className="bd-icon" />
                                    <p>{userDetails.guest_workplace}</p>
                                </div>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/music_icon.svg'} alt="" className="bd-icon" />
                                    <p>{userDetails.guest_fav_song}</p>
                                </div>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/live_location_icon.svg'} alt="" className="bd-icon" />
                                    <p>{userDetails.guest_living_location}</p>
                                </div>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/school_icon.svg'} alt="" className="bd-icon" />
                                    <p>{userDetails.guest_school_name}</p>
                                </div>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/obsessed_icon.svg'} alt="" className="bd-icon" />
                                    <p>{userDetails.guest_obsessed}</p>
                                </div>
                                <div className='bd-sub-box'>
                                    <img src={'/assets/img/icons/birhtday_icon.svg'} alt="" className="bd-icon" />
                                    <p>{userDetails.guest_born_decade}</p>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </div>

        </div>
    );
};

export default GuestProfile;
