const Params = {
    weburl : 'http://localhost:3001',
    profileUrl : 'https://profile.wirestay.com/web/images',
    baseURL: 'https://app.statustag.com/sp/api/v1',
    endpoints: {
      countryList : '/country/list',
      stateList : '/country/state-list',
      cityList : '/country/city-list',
      createOtp : '/otp/create-otp',
      verifyOtp : '/otp/verify-otp',
      isUserExist : '/stays-host-profile/is-user-exists',
      saveProfile : '/stays-host-profile/save-profile',
      updateProfile : '/stays-host-profile/update-profile',
      getProfile : '/stays-host-profile/get-profile',
      updateExtraProfile : '/stays-host-extra-profile/save-profile',
      getExtraProfile : '/stays-host-extra-profile/get-profile',
      updateSpotlight : '/stays-host-extra-profile/save-spotlight',
      getSpotlight : '/stays-host-extra-profile/get-spotlight',
      getTripCategory : '/stays-host/trip-category',
      getMyBooking : '/stays-hotel/my-bookings',
      getMyCalendar : '/stays-hotel/my-calendar',
      getDaywisePrice : '/stays-host/my-daywise-price',
      updateDaywisePrice : '/stays-hotel/update-daywise-price',
      hotelDataList : '/stays-hotel/hotel-data-list',
      getRoomList : '/stays-hotel/room-list',
      removeRoom : '/stays-hotel/remove-room',
      getMyListings : '/stays-hotel/my-listings',
      checkAffiliate : '/stays-host/check-for-affiliate',
      createListing : '/stays-hotel/init-listing',
      updateListing : '/stays-hotel/update-listing',
      getListingData : '/stays-hotel/get-listing',
      updateImages : '/stays-hotel/update-featured-images',
      updateImageTags : '/stays-hotel/update-image-tags',
      makeItCover : '/stays-hotel/make-it-cover-image',
      getCategories : '/stays-host/categories',
      getHotelCategories : '/stays-hotel/hotel-categories',
      getPhotoTags : '/stays-hotel/photo-tags',
      getAmenities : '/stays-hotel/amanities',
      getRoomAmenities : '/stays-hotel/room-amanities',
      findPlaces : '/stays-host/find-places',
      findPlaceById : '/stays-host/find-place-by-id',
      getPendingListings : '/stays-hotel/pending-listings',
      verifyDocument : '/stays-host/verify-document',
      getChatdb : '/stay-chat/getdb',
      chatMyRequests : '/stay-chat/my-requests',
      latestMessages : '/stay-chat/latest-messages',
      sendMessage : '/stay-chat/send-message',
      notificationCategory : '/stay-notification/get-host-category',
      notificationList : '/stay-notification/notification-list',
      offerDataList : '/stays-hotel/offer-data-list',
      saveOffer : '/stays-hotel/save-offer',
      getOffers : '/stays-hotel/get-offers',
      getBankList : '/stays-hotel/bank-list',
      getUserDetails : '/stays-user-extra/guest-details',
      getBookings : '/stays-hotel/get-bookings',
      saveCheckin : '/stays-hotel/save-checkin',
      getBookingDetails : '/stays-hotel/get-booking-details',
      saveCheckout : '/stays-hotel/save-checkout',
      getEarnings : '/stays-hotel/get-earning',
      cancelBooking : '/stays-host/cancel-booking',
      saveVideo : '/stays-hotel/save-video',
    },
  };
  
  export default Params;
  