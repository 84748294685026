import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../ScrollToTop ';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Checkin = () => {
  const { bookingId } = useParams();
  const [bookingDetails, setBookingDetails] = useState(null);
  const [roomAssignments, setRoomAssignments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBookingDetails = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('confirmation_code', bookingId);

      setLoading(true);
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getBookings}`,
          data: formData,
        });

        if (response.data.status === 'found') {
          setBookingDetails(response.data.bookings);
          initializeRoomAssignments(response.data.bookings.no_of_rooms_booked);
        } else {
          setError('Booking not found.');
        }
      } catch (err) {
        setError('An error occurred while fetching booking details.');
      } finally {
        setLoading(false);
      }
    };

    fetchBookingDetails();
  }, [bookingId]);

  const initializeRoomAssignments = (noOfRooms) => {
    const assignments = [];
    for (let i = 0; i < noOfRooms; i++) {
      assignments.push({
        room_id: '',
        guests: [
          { guest_name: '', guest_identity_proof: null },
          { guest_name: '', guest_identity_proof: null },
        ],
      });
    }
    setRoomAssignments(assignments);
  };

  const handleRoomChange = (index, field, value) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    const updatedRooms = [...roomAssignments];
    updatedRooms[index][field] = numericValue;
    setRoomAssignments(updatedRooms);
  };

  const handleGuestChange = (roomIndex, guestIndex, field, value) => {
    const sanitizedValue = value.replace(/[^a-zA-Z\s-]/g, '');
    const updatedRooms = [...roomAssignments];
    updatedRooms[roomIndex].guests[guestIndex][field] = sanitizedValue;
    setRoomAssignments(updatedRooms);
  };

  const handleFileUpload = (e, roomIndex, guestIndex) => {
    const file = e.target.files[0];
    if (file) {
      const updatedRoomAssignments = [...roomAssignments];
      updatedRoomAssignments[roomIndex].guests[guestIndex].guest_identity_proof = file;
      setRoomAssignments(updatedRoomAssignments);
    }
  };

  const handleSubmit = async () => {

    const isValid = validateInputs();
    if (!isValid) return;

    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('stay_booking_id', bookingDetails.stay_booking_id);

    // Iterate over room assignments and guests
    roomAssignments.forEach((room, roomIndex) => {
      // Add room ID
      formData.append(`room_assignments[${roomIndex}][room_id]`, room.room_id);

      room.guests.forEach((guest, guestIndex) => {
        // Add guest name
        formData.append(
          `room_assignments[${roomIndex}][guests][${guestIndex}][guest_name]`,
          guest.guest_name
        );

        // Add guest identity proof (file) if provided
        if (guest.guest_identity_proof) {
          formData.append(
            `room_assignments[${roomIndex}][guests][${guestIndex}][guest_identity_proof]`,
            guest.guest_identity_proof
          );
        }
      });
    });
    
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.saveCheckin}`,
        data: formData,
      });
      if (response.data.status === 'success') {
        toast.success('Check-in successful.');
        navigate('/home');
      } else {
        setError('An error occurred while saving check-in details.');
      }
    } catch (err) {
      setError('Check-in failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const validateInputs = () => {
    for (let roomIndex = 0; roomIndex < roomAssignments.length; roomIndex++) {
      const room = roomAssignments[roomIndex];
      for (let guestIndex = 0; guestIndex < room.guests.length; guestIndex++) {
        const guest = room.guests[guestIndex];
        if (!guest.guest_name.trim()) {
          setError(`Guest ${guestIndex + 1} in Room ${roomIndex + 1} name is missing.`);
          return false;
        }
        if (!guest.guest_identity_proof) {
          setError(`Guest ${guestIndex + 1} in Room ${roomIndex + 1} must upload an identity proof.`);
          return false;
        }
      }
    }
    setError(null);
    return true;
  };

  return (
    <div>
      <Helmet>
        <title>Manage Check-in</title>
        <meta name="description" content="" />
      </Helmet>
      <ScrollToTop />
      <div className="manage-checkin">
        <div className="clp-header">
          <div className='iwtb mb-0'>
            <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
            <h1 className="ws-title m-0">Manage Check-in</h1>
          </div>
        </div>

        {loading && <p>Loading...</p>}

        {bookingDetails && (
          <div className="booking-details">
            <div className="clp-header">
              <div className='iwtb'>
                <p className="bd-title">Booking Details</p>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='iwtb bdbib'>
                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                    <p>Booking ID: {bookingDetails.confirmation_code}</p>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='iwtb bdbib'>
                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                    <p>Number of Guests: {bookingDetails.selectedCriterias.adults}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="room-assignments">
              {roomAssignments.map((room, roomIndex) => (
                <div key={roomIndex} className="roomasbox">
                  <div className="clp-header">
                    <div className='iwtb mb-0'>
                      <img src={'/assets/img/icons/room_icon_2.png'} alt="Profile" className="pointer-icon" />
                      <p className="bd-title">Room {roomIndex + 1}</p>
                    </div>
                  </div>
                  <div className="guest-details-box">
                    <div className="form-group gdsbx">
                        <label>
                        Room Number:
                          <div className='guest-name-box'>
                            <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="bd-icon" />
                            <input
                              type="text"
                              className="form-control"
                              value={room.room_id}
                              onChange={(e) => handleRoomChange(roomIndex, 'room_id', e.target.value)}
                            />
                          </div>
                        </label>
                    </div>
                  </div>

                  {room.guests.map((guest, guestIndex) => (
                    <div key={guestIndex} className="guest-details-box">
                      <div className="form-group gdsbx">
                        <label>
                          Guest {guestIndex + 1} Name:
                          <div className='guest-name-box'>
                            <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="bd-icon" />
                            <input
                              type="text"
                              className="form-control"
                              value={guest.guest_name}
                              onChange={(e) => handleGuestChange(roomIndex, guestIndex, 'guest_name', e.target.value)}
                            />
                          </div>
                        </label>
                      </div>
                      <div className="form-group gdsbx">
                        <label>
                          Identity Proof:
                          <div className='guest-name-box'>
                            <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="bd-icon" />
                            <input
                              type="file"
                              className="form-control"
                              onChange={(e) => handleFileUpload(e, roomIndex, guestIndex)}
                            />
                          </div>
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
            {error && <p className="error" style={{color:'red'}}>{error}</p>}

            <button onClick={handleSubmit} className="btn btn-black hlbtn" disabled={loading}>Submit</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkin;
