import React, { useState, useEffect } from "react";
import axios from "axios";
import params from "../../../config/Params";
import { getItemFromLocalStorage } from "../../../utils/storageUtils";
import { toast } from "react-toastify";
import ScrollToTop from "../../../ScrollToTop ";

const Amanities = ({ formData, nextStep, prevStep }) => {
  const { listingId } = formData;

  const [amenities, setAmenities] = useState([]);
  const [selectedAmenity, setSelectedAmenity] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState({}); 
  const [dropdownVisible, setDropdownVisible] = useState({});
  const [listingAmenity, setListingAmenity] = useState([]);
  const [highlightedAmenityIds, setHighlightedAmenityIds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchAmenities = async () => {
      const hostData = getItemFromLocalStorage("hostProfile");
      let formData = new FormData();
      formData.append("token", hostData.host_access_token);

      setLoading(true);
      try {
        const response = await axios({
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          url: `${params.baseURL}${params.endpoints.getAmenities}`,
          data: formData,
        });

        if (response.data.status === "ama") {
          const fetchedAmenities = response.data.ama_list;
          setAmenities(fetchedAmenities);
          if (fetchedAmenities.length > 0) {
            setSelectedAmenity(fetchedAmenities[0]);
          }
          setLoading(false);
        } else {
          setError("An error occurred while fetching data.");
        }
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage("hostProfile");
      let formData = new FormData();
      formData.append("token", hostData.host_access_token);
      formData.append("listing_id", listingId);

      try {
        const response = await axios({
          method: "post",
          headers: { "Content-Type": "multipart/form-data" },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });

        if (response.data.status === "ok") {
          setListingAmenity(response.data.updated_property_item.amanities);

          const savedCategories = response.data.updated_property_item.amanities.reduce((acc, item) => {
            acc[item.ama_id] = acc[item.ama_id] || {};
            acc[item.ama_id][item.ama_cat_id] = {
              value: item.ama_subcat_id ? "1" : "2",
              subCategory: item.ama_subcat_id || null,
            };
            return acc;
          }, {});
          setSelectedCategories(savedCategories);
          //console.log(savedCategories);
        } else {
          toast.error("An error occurred while fetching data.");
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchAmenities();
    fetchListingData();

  }, [listingId]);

  useEffect(() => {
    if (listingAmenity.length > 0) {
      const initialCategories = listingAmenity.reduce((acc, item) => {
        acc[item.ama_id] = acc[item.ama_id] || {};
        acc[item.ama_id][item.ama_cat_id] = {
          value: item.ama_cat_id_status === 1 ? "1" : "2",
          subCategory: item.ama_subcat_id || null,
        };
        return acc;
      }, {});
      setSelectedCategories(initialCategories);
      const highlightedAmenityIds = Object.keys(initialCategories);
      setHighlightedAmenityIds(highlightedAmenityIds);
      //console.log(highlightedAmenityIds);
  
      const initialDropdownVisible = {};
      Object.keys(initialCategories).forEach((amenityId) => {
        Object.keys(initialCategories[amenityId]).forEach((categoryId) => {
          const category = initialCategories[amenityId][categoryId];
          if (category.value === "1" && category.subCategory) {
            initialDropdownVisible[categoryId] = true;
          }
        });
      });
      setDropdownVisible(initialDropdownVisible);
    }
  }, [listingAmenity]);   

  const handleAmenityClick = (amenity) => {
    setSelectedAmenity(amenity);
    setDropdownVisible((prev) => {
      const newDropdownVisible = {};
      Object.keys(selectedCategories[amenity.amenity_id] || {}).forEach((categoryId) => {
        const category = selectedCategories[amenity.amenity_id][categoryId];
        newDropdownVisible[categoryId] = category.value === "1" && category.subCategory;
      });
      return newDropdownVisible;
    });
  };

  const handleCategorySelect = (amenityId, categoryId, value, hasSubcategories) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [amenityId]: {
        ...prev[amenityId],
        [categoryId]: {
          value,
          subCategory: value === "1" ? prev[amenityId]?.[categoryId]?.subCategory || null : null,
        },
      },
    }));

    setDropdownVisible((prev) => ({
      ...prev,
      [categoryId]: value === "1" && hasSubcategories,
    }));
  };

  const handleSubCategorySelect = (amenityId, categoryId, subCategoryId) => {
    setSelectedCategories((prev) => ({
      ...prev,
      [amenityId]: {
        ...prev[amenityId],
        [categoryId]: {
          ...prev[amenityId][categoryId],
          subCategory: subCategoryId,
        },
      },
    }));
  };  

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage("hostProfile");
    const ts = Date.now();

    const formattedAmenities = Object.keys(selectedCategories).flatMap((amenityId) =>
      Object.keys(selectedCategories[amenityId]).map((categoryId) => ({
        ama_id: amenityId,
        ama_cat_id: categoryId,
        ama_subcat_id: selectedCategories[amenityId][categoryId].subCategory || null,
        ama_cat_id_status: selectedCategories[amenityId][categoryId].value,
        stay_listing_id: listingId,
      }))
    );

    let catData = new FormData();
    catData.append("token", hostData.host_access_token);
    catData.append("listing_id", listingId);
    catData.append(
      "data",
      JSON.stringify({
        amanities: formattedAmenities,
      })
    );
    catData.append("ts", ts);

    try {
      const response = await axios({
        method: "post",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });

      if (response.data.status === "done") {
        nextStep();
      } else {
        toast.error("Failed to save data");
      }
    } catch (err) {
      toast.error("An error occurred while saving the listing");
    }
  };

  const handleNext = () => {
    if (Object.keys(selectedCategories).length === 0) {
      toast.error("Please select at least one category.");
      return;
    }
    saveListing();
  };

  const faqs = [
    {
      question: "What kind of amenities should I list?",
      answer: "Include essential amenities such as Wi-Fi, parking, pool, room service, and air conditioning."
    },
    {
      question: "How many amenities can I list?",
      answer: "There is no limit. However, only the most relevant ones will be displayed to guests."
    },
    {
      question: "Can I update the amenities later?",
      answer: "Yes, you can modify amenities anytime through the dashboard."
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      <ScrollToTop />
      <div className="hotel-register-section">
        <div className='row'>
          <div className="col-md-9">
            <div className="clp-header">
              <h4 className="ws-title">
                <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                Property Amenities
              </h4>
              <p className="ws-desc">
                <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                Please select all the amenities available at your property
              </p>
            </div>
            <div className="amen-section">
              <div
                style={{
                  width: "30%",
                  borderRight: "1px solid #ccc",
                  padding: "10px",
                  overflowY: "auto",
                }}
              >
                <ul style={{ listStyleType: "none", padding: 0 }}>
                  {amenities.map((amenity) => (
                    <li
                      key={amenity.amenity_id}
                      className="amen-list"
                      style={{
                        backgroundColor:
                          (selectedAmenity?.amenity_id === amenity.amenity_id ||
                            highlightedAmenityIds.includes(amenity.amenity_id.toString()))
                            ? "#cccccc"
                            : "transparent",
                      }}
                      onClick={() => handleAmenityClick(amenity)}
                    >
                      <div className="amen-icon-box">
                        <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cat-img" />
                      </div>
                      <span className="amen-title-box">{amenity.amenity_name}</span>
                    </li>
                  ))}
                </ul>
              </div>

              <div style={{ width: "70%", padding: "15px 10px" }}>
                {selectedAmenity ? (
                  <ul style={{ listStyleType: "none", padding: 0 }}>
                    {selectedAmenity.categories.map((category) => (
                      <li key={category.cat_id} className="amen-sublist">
                        <div className="amen-sublist-box">
                          <div className="amen-sublist-tcb">
                            <div className="amen-icon-box">
                              <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cat-img" />
                            </div>
                            <span>{category.cat_name}</span>
                          </div>
                          <div>
                            <div className="extra-bed-radio">
                              <div className="exdrs">
                                <label>
                                  <input
                                    type="radio"
                                    name={`category_${category.cat_id}`}
                                    value="2"
                                    checked={
                                      selectedCategories[selectedAmenity.amenity_id]?.[category.cat_id]?.value === "2"
                                    }
                                    onChange={() =>
                                      handleCategorySelect(
                                        selectedAmenity.amenity_id,
                                        category.cat_id,
                                        "2",
                                        false
                                      )
                                    }
                                  />
                                  <span>No</span>
                                </label>
                                <label>
                                  <input
                                    type="radio"
                                    name={`category_${category.cat_id}`}
                                    value="1"
                                    checked={
                                      selectedCategories[selectedAmenity.amenity_id]?.[category.cat_id]?.value === "1"
                                    }
                                    onChange={() =>
                                      handleCategorySelect(
                                        selectedAmenity.amenity_id,
                                        category.cat_id,
                                        "1",
                                        category.subcategories.length > 0
                                      )
                                    }
                                  />
                                  <span>Yes</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {dropdownVisible?.[category.cat_id] &&
                          selectedCategories?.[selectedAmenity?.amenity_id]?.[category.cat_id]?.value === "1" && (
                            <>
                              <div className="amen-visible-drop">
                                <select
                                  className="form-select hrifb"
                                  style={{ fontSize: "14px" }}
                                  value={
                                    selectedCategories[selectedAmenity.amenity_id]?.[category.cat_id]?.subCategory || ""
                                  }
                                  onChange={(e) =>
                                    handleSubCategorySelect(
                                      selectedAmenity.amenity_id,
                                      category.cat_id,
                                      e.target.value
                                    )
                                  }
                                >
                                  <option value="">Select</option>
                                  {category.subcategories.map((subcategory) => (
                                    <option
                                      key={subcategory.sub_cat_id}
                                      value={subcategory.sub_cat_id}
                                    >
                                      {subcategory.sub_cat_name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </>
                          )
                        }
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p>Please select an amenity to view categories.</p>
                )}
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="faq-sticky">
              <div className='fas-desc-box'>
                <div className='faq-title-box'>
                  <h4 className='faq-title-txt'>What to do here ?</h4>
                </div>
                <p className='faq-desc'>Show off what makes your property shine! List those awesome amenities.</p>
              </div>
              <hr></hr>
              <div className='faq-txt-box'>
                <img src='/assets/img/faq_banner_2.gif' className='img-fluid' alt='' />
              </div>
              {faqs.map((faq, index) => (
                <div key={index} className='faqs-list-section'>
                  <div
                    onClick={() => toggleAccordion(index)} className='faqs-qustn-box'
                    style={{
                      backgroundColor: activeIndex === index ? "#f7f7f7" : "#ffffff",
                      borderBottom: activeIndex === index ? "1px solid #ccc" : "none",
                    }}
                  >
                    {faq.question}
                    <i
                      className={`fas ${
                        activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                      }`}
                      style={{
                        transition: "transform 0.3s ease",
                      }}
                    ></i>
                  </div>
                  {activeIndex === index && (
                    <div className='faqs-ans-box'>
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="clp-footer">
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default Amanities;
