import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import params from '../../../config/Params';
import { getItemFromLocalStorage } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { debounce } from 'lodash';
import ScrollToTop from '../../../ScrollToTop ';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const defaultCenter = {
  lat: 28.64231,
  lng: 77.22229,
};

const Location = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [center, setCenter] = useState(defaultCenter);
  const [address, setAddress] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [placeSuggestions, setPlaceSuggestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [placeId, setPlaceId] = useState();
  const [placeDetails, setPlaceDetails] = useState();
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [pincode, setPincode] = useState('');
  const [district, setDistrict] = useState('');
  const [nearby, setNearby] = useState('');
  const [street, setStreet] = useState('');
  const [flatNo, setFlatNo] = useState('');
  const [countryList, setCountryList] = useState([]);
  const [countryId, setCountryId] = useState([]);
  const mapRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(null);

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyC4xmFbX2XiP2dbZW5LUoSjw2qR-o2utmU",
  });

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const userLat = position.coords.latitude;
          const userLng = position.coords.longitude;
          setCenter({ lat: userLat, lng: userLng });
          setLatitude(userLat);
          setLongitude(userLng);
        },
        (error) => {
          console.error('Error retrieving user location:', error);
        }
      );
    }
  }, []);

  const fetchAddress = useCallback(
    debounce((lat, lng) => {
      setIsLoading(true);
      fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyC4xmFbX2XiP2dbZW5LUoSjw2qR-o2utmU`)
        .then((response) => response.json())
        .then((data) => {
          if (data.results && data.results[0]) {
            const addressComponents = data.results[0].address_components;
            let state_name = '';
            let country_name = '';
            let postalCode = '';
            let city = '';
            let district = '';

            addressComponents.forEach((component) => {
              if (component.types.includes('administrative_area_level_1')) {
                state_name = component.long_name;
              }
              if (component.types.includes('administrative_area_level_2')) {
                district = component.long_name;
              }
              if (component.types.includes('locality')) {
                city = component.long_name;
              }
              if (component.types.includes('country')) {
                country_name = component.long_name;
              }
              if (component.types.includes('postal_code')) {
                postalCode = component.long_name;
              }
            });
            setAddress(data.results[0].formatted_address);
            setDistrict(district);
            setCity(city);
            setState(state_name);
            setCountry(country_name);
            setPincode(postalCode);
          } else {
            setAddress('Address not found');
          }
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error fetching the address:', error);
          setAddress('Error retrieving address');
          setIsLoading(false);
        });
    }, 500),
    []
  );

  const fetchPlaceSuggestions = useCallback(
    debounce(async (text) => {
      if (!text) return;
      const hostData = getItemFromLocalStorage('hostProfile');
      const country = 'in';

      let locData = new FormData();
      locData.append('token', hostData.host_access_token);
      locData.append('country', country);
      locData.append('text', text);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
          url: `${params.baseURL}${params.endpoints.findPlaces}`,
          data: locData,
        });

        if (response.data.status === 'result') {
          const suggestions = response.data.places_list;
          setPlaceSuggestions(suggestions);
        } else if (response.data.status === 'error') {
          response.data.list.forEach((errorMsg) => {
              toast.error(errorMsg);
          });
        } else {
          toast.error('Failed to fetch data');
        }
      } catch (err) {
        console.log('An error occurred while saving the listing');
      }
    }, 500),
    []
  );

  const fetchPlaceDetails = async (placeId) => {
    const hostData = getItemFromLocalStorage('hostProfile');
    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('id', placeId);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.findPlaceById}`,
        data: formData,
      });

      if (response.data.status === 'found') {
        const details = response.data.property_location;
        setPlaceDetails(details);
        setAddress(details.state);
        setCenter({ lat: details.lat, lng: details.lng });
        setLatitude(details.lat);
        setLongitude(details.lng);
        setCountry(details.country_name);
        setState(details.state);
        setCity(details.city);
        setNearby(details.nearby);
        setPincode(details.pincode);
      } else if (response.data.status === 'error') {
        response.data.list.forEach((errorMsg) => {
            toast.error(errorMsg);
        });
      } else {
        toast.error('An error occurred while fetching data.');
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleInputChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length > 3) fetchPlaceSuggestions(inputText);
  };

  const handleSuggestionClick = (suggestion) => {
    setPlaceId(suggestion.id);
    fetchPlaceDetails(suggestion.id);
    setIsFormVisible(true);
    setPlaceSuggestions([]);
  };

  const prevCoords = useRef({ lat: null, lng: null });

  const handleMapIdle = () => {
    if (mapRef.current) {
      const mapCenter = mapRef.current.getCenter();
      const lat = mapCenter.lat();
      const lng = mapCenter.lng();

      if (
        Math.abs(lat - prevCoords.current.lat) > 0.001 ||
        Math.abs(lng - prevCoords.current.lng) > 0.001
      ) {
        prevCoords.current = { lat, lng };
        setLatitude(lat);
        setLongitude(lng);
        fetchAddress(lat, lng);
      }
    }
  };

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case 'country':
        setCountryId(value);
        break;
      case 'flatNo':
        setFlatNo(value);
        break;
      case 'street':
        setStreet(value);
        break;
      case 'nearby':
        setNearby(value);
        break;
      case 'district':
        setDistrict(value);
        break;
      case 'city':
        setCity(value);
        break;
      case 'state':
        setState(value);
        break;
      case 'pincode':
        setPincode(value);
        break;
      default:
        break;
    }
  }; 

  useEffect(() => {
    const fetchCounryList = async () => { 
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.countryList}`,
        });
  
        if (response.data.status === 'ok') {
          setCountryList(response.data.country_list);
        } else {
          console.log('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;

          if (listingData.street_address) {
            setAddress(listingData.street_address);
            setCenter({ lat: listingData.listing_lat, lng: listingData.longitude });
            setLatitude(listingData.listing_lat);
            setLongitude(listingData.longitude);
          } else {
            if (latitude && longitude) {
              setCenter({ lat: latitude, lng: longitude });
              fetchAddress(latitude, longitude);
            }
          }
  
          setState(listingData.state_ut);
          setCity(listingData.city_town);
          setNearby(listingData.nearby_landmark);
          setPincode(listingData.pincode);
          setFlatNo(listingData.flat_house_info);
          setDistrict(listingData.district);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
    fetchCounryList();
  }, [listingId, latitude, longitude, fetchAddress]);  

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const updatedPropertyItem = {
      country_id: countryId,
      flat_house_info: flatNo,
      street_address: street,
      nearby_landmark: nearby,
      district: district,
      city_town: city,
      state_ut: state,
      pincode: pincode,
      listing_lat: latitude,
      listing_lng: longitude,
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        nextStep();
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handleEditAddress = () => {
    setIsFormVisible(true);
  }

  const handleNext = () => {
    saveListing();
  };

  useEffect(() => {
    return () => {
      fetchAddress.cancel();
      fetchPlaceSuggestions.cancel();
    };
  }, [fetchAddress, fetchPlaceSuggestions]);

  if (!isLoaded) {
    return <div>Loading Google Maps...</div>;
  }

  const faqs = [
    {
      question: "How do I enter my property’s location?",
      answer: "Use the map tool to pin your property’s location or enter the address manually."
    },
    {
      question: "What if my property is in a remote area not listed on the map?",
      answer: "Enter the nearest landmark and provide additional details in the description box."
    },
    {
      question: "Can I list multiple properties under the same account?",
      answer: "Yes, but each property must have a separate listing."
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      <ScrollToTop />
      <div className="hotel-register-section">
        <div className='row'>
          <div className='col-md-9'>
            <div className="clp-header">
              <h4 className="ws-title">Where’s your property located?</h4>
              <p className='ws-desc'>Display your specific location clearly to guests. Please note, we share your address only after they book.</p>
            </div>

            <div className="st1-desc-box row">
              <div className='col-md-6'>
                {isLoading ? (
                  <div className="selected-address">
                    <p>Loading...</p>
                  </div>
                ) : (
                  address && (
                    <div className="selected-address" onClick={handleEditAddress}>
                      <p>{address}</p>
                    </div>
                  )
                )}

                  <div className="search-address-box" style={{position:'relative',padding:'0'}}>
                    <input
                      name="find-place"
                      className="form-control"
                      id="find-place"
                      placeholder="Enter your address"
                      onChange={handleInputChange}
                      autoComplete='off'
                    />

                    {placeSuggestions.length > 0 && (
                      <ul className="suggestions-list">
                        {placeSuggestions.map((suggestion, index) => (
                          <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            <p className='loc-title'>{suggestion.title}</p>
                            <p className='loc-desc'>{suggestion.description}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
              </div>

              <div className='col-md-6'>
                <div className="map-container">
                  <div style={containerStyle} id="googleMap">
                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      center={center}
                      zoom={10}
                      onLoad={(map) => (mapRef.current = map)}
                      onIdle={handleMapIdle}
                    />
                    <div
                      style={{
                        position: 'absolute',
                        top: '70%',
                        left: '50%',
                        transform: 'translate(-50%, -100%)',
                        pointerEvents: 'none',
                      }}
                    >
                      <img src={'/assets/img/icons/location_marker.svg'} style={{ width: '40px' }} alt="Map Marker" />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                {isFormVisible && (
                  <div className='location-details-box'>
                    
                    <div className="form-group col-md-12 mb-3">
                      <div className='form-flex'>
                        <div className='form-icon'>
                          <img 
                            src='/assets/img/dummy_amenity_icon.svg'
                            className='form-icon img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='form-txt'>
                          <div className="form-floating">
                            <select
                              className="form-control country-code"
                              name="country"
                              value={countryId || ""}
                              onChange={(e) => setCountryId(parseInt(e.target.value, 10))}
                            >
                              <option value="" disabled>
                                Select a country
                              </option>
                              {countryList.map((code) => (
                                <option key={code.country_id} value={code.country_id}>
                                  {code.country_name} - {code.country_iso}
                                </option>
                              ))}
                            </select>
                            <label htmlFor="country">Country/region</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <div className='form-flex'>
                        <div className='form-icon'>
                          <img 
                            src='/assets/img/dummy_amenity_icon.svg'
                            className='form-icon img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='form-txt'>
                          <div className="form-floating">
                            <input className={'form-control'} type="text" id="flatNo" name="flatNo" value={flatNo} onChange={handleFormChange} />
                            <label htmlFor="flatNo">Flat No</label>
                          </div>
                        </div>
                      </div> 
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <div className='form-flex'>
                        <div className='form-icon'>
                          <img 
                            src='/assets/img/dummy_amenity_icon.svg'
                            className='form-icon img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='form-txt'> 
                          <div className="form-floating">
                            <input className={'form-control'} type="text" id="street" name="street" value={street} onChange={handleFormChange} />
                            <label htmlFor="street">Street address</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <div className='form-flex'>
                        <div className='form-icon'>
                          <img 
                            src='/assets/img/dummy_amenity_icon.svg'
                            className='form-icon img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='form-txt'>
                          <div className="form-floating">
                            <input className={'form-control'} type="text" id="nearby" name="nearby" value={nearby} onChange={handleFormChange} />
                            <label htmlFor="nearby">Nearby landmark</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <div className='form-flex'>
                        <div className='form-icon'>
                          <img 
                            src='/assets/img/dummy_amenity_icon.svg'
                            className='form-icon img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='form-txt'>
                          <div className="form-floating">
                            <input className={'form-control'} type="text" id="district" name="district" value={district} onChange={handleFormChange} />
                            <label htmlFor="nearby">District/Locality</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <div className='form-flex'>
                        <div className='form-icon'>
                          <img 
                            src='/assets/img/dummy_amenity_icon.svg'
                            className='form-icon img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='form-txt'>
                          <div className="form-floating">
                            <input className={'form-control'} type="text" id="city" name="city" value={city} onChange={handleFormChange} />
                            <label htmlFor="nearby">City/Town</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <div className='form-flex'>
                        <div className='form-icon'>
                          <img 
                            src='/assets/img/dummy_amenity_icon.svg'
                            className='form-icon img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='form-txt'>
                          <div className="form-floating">
                            <input className={'form-control'} type="text" id="state" name="state" value={state} onChange={handleFormChange} />
                            <label htmlFor="nearby">State</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-group col-md-12 mb-3">
                      <div className='form-flex'>
                        <div className='form-icon'>
                          <img 
                            src='/assets/img/dummy_amenity_icon.svg'
                            className='form-icon img-fluid'
                            alt=''
                          />
                        </div>
                        <div className='form-txt'>
                          <div className="form-floating">
                            <input className={'form-control'} type="text" id="pincode" name="pincode" value={pincode} onChange={handleFormChange} />
                            <label htmlFor="nearby">Pincode</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='clp-footer'>
                      <button className="btn btn-blank" onClick={() => setIsFormVisible(false)}>Back</button>
                      <button className='btn btn-black' onClick={handleNext}>Next</button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className="faq-sticky">
              <div className='fas-desc-box'>
                <div className='faq-title-box'>
                  <h4 className='faq-title-txt'>What to do here ?</h4>
                </div>
                <p className='faq-desc'>Pin your property on the map—make it easy for guests to find you!</p>
              </div>
              <hr></hr>
              <div className='faq-txt-box'>
                <img src='/assets/img/faq_banner_2.gif' className='img-fluid' alt='' />
              </div>
              {faqs.map((faq, index) => (
                <div key={index} className='faqs-list-section'>
                  <div
                    onClick={() => toggleAccordion(index)} className='faqs-qustn-box'
                    style={{
                      backgroundColor: activeIndex === index ? "#f7f7f7" : "#ffffff",
                      borderBottom: activeIndex === index ? "1px solid #ccc" : "none",
                    }}
                  >
                    {faq.question}
                    <i
                      className={`fas ${
                        activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                      }`}
                      style={{
                        transition: "transform 0.3s ease",
                      }}
                    ></i>
                  </div>
                  {activeIndex === index && (
                    <div className='faqs-ans-box'>
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
        
      <div className='clp-footer'>
        <button className='btn btn-blank' onClick={prevStep}>Back</button>
        <button className='btn btn-black' onClick={nextStep}>Next</button>
      </div>

    </div>
  );
};

export default Location;
