import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../../../config/Params';
import { getItemFromLocalStorage } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';
import ScrollToTop from '../../../ScrollToTop ';
import { MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText } from '@mui/material';

const Policies = ({ formData, nextStep, prevStep }) => {
  const { listingId } = formData;
  const [isOpen, setIsOpen] = useState(false);
  const [checkInTimeList, setCheckInTimeList] = useState([]);
  const [checkOutTimeList, setCheckOutTimeList] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [description, setDescription] = useState('');
  const [charLimit] = useState(3000);
  const [checkInTime, setCheckInTime] = useState("");
  const [checkOutTime, setCheckOutTime] = useState("");
  const [cancellationPolicy, setCancellationPolicy] = useState(1);
  const [unmarriedCouplesAllowed, setUnmarriedCouplesAllowed] = useState(2);
  const [maleOnlyGroupsAllowed, setMaleOnlyGroupsAllowed] = useState(2);
  const [ageRestriction, setAgeRestriction] = useState(2);
  const [acceptableIdentityProofs, setAcceptableIdentityProofs] = useState([]);
  const [sameCityIdAllowed, setSameCityIdAllowed] = useState('');
  const [smokingAllowed, setSmokingAllowed] = useState(2);
  const [partiesAllowed, setPartiesAllowed] = useState(2);
  const [visitorAccessAllowed, setVisitorAccessAllowed] = useState(2);
  const [wheelchairAccessible, setWheelchairAccessible] = useState(2);
  const [petAllowed, setPetAllowed] = useState(2);
  const [propertyPet, setPropertyPet] = useState(2);
  const [flexibleCheckin, setFlexibleCheckin] = useState(2);
  const [extraAdultBed, setExtraAdultBed] = useState(2);
  const [extraKidsBed, setExtraKidsBed] = useState(2);
  const [breakfastPrice, setBreakfastPrice] = useState(0.00);
  const [lunchPrice, setLunchPrice] = useState(0.00);
  const [dinnerPrice, setDinnerPrice] = useState(0.00);
  const [errors, setErrors] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpenCheckOut, setDropdownOpenCheckOut] = useState(false);

  useEffect(() => {
    const fetchPolicies = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append("token", hostData.host_access_token);
      formData.append("listing_id", listingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });

        if (response.data.status === "ok") {
          const data = response.data.updated_property_item.policies;
          setCheckInTime(data.checkin_time);
          setCheckOutTime(data.checkout_time);
          setCancellationPolicy(data.cancellation_policy);
          setUnmarriedCouplesAllowed(data.is_unmarried_couples_allowed);
          setMaleOnlyGroupsAllowed(data.is_only_male_group_allowed);
          setAgeRestriction(data.is_minor_guest_allowed);
          setAcceptableIdentityProofs(data.acceptable_id_proofs);
          setSameCityIdAllowed(data.is_same_city_id_allowed);
          setSmokingAllowed(data.is_smoking_allowed);
          setPartiesAllowed(data.is_private_party_allowed);
          setVisitorAccessAllowed(data.is_outside_visitor_allowed);
          setWheelchairAccessible(data.is_wheelchair_guest_accessible);
          setPetAllowed(data.is_pet_allowed);
          setPropertyPet(data.is_pet_in_property);
          setFlexibleCheckin(data.is_flexible_checkin_allowed);
          setExtraAdultBed(data.extra_bed_for_adults);
          setExtraKidsBed(data.extra_bed_for_kids);
          setDescription(data.custom_policy);
          setBreakfastPrice(data.breakfast_price);
          setLunchPrice(data.lunch_price);
          setDinnerPrice(data.dinner_price);
        } else {
          toast.error("Failed to fetch policies data.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    const fetchHotelData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.hotelDataList}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const dataList = response.data.list;
          const checkInOptions = dataList.find(item => item.check_in_time)?.check_in_time || [];
          const checkOutOptions = dataList.find(item => item.check_out_time)?.check_out_time || [];
          setCheckInTimeList(checkInOptions);
          setCheckOutTimeList(checkOutOptions);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };

    fetchPolicies();
    fetchHotelData();
  }, [listingId, params]);

  useEffect(() => {
    if (acceptableIdentityProofs && typeof acceptableIdentityProofs === 'string') {
      const selectedProofs = acceptableIdentityProofs.split(',').map(Number);
      setSelectedItems(selectedProofs);
    }
  }, [acceptableIdentityProofs]);
  

  const validateFields = () => {
    const validationErrors = {};
    if (!checkInTime) validationErrors.checkInTime = 'Please select a check in time';
    if (!checkOutTime) validationErrors.checkOutTime = 'Please select a check out time';
    if (!description || description.trim() === '') validationErrors.description = 'Please enter description.';
    //if (!breakfastPrice || breakfastPrice.trim() === '') validationErrors.breakfastPrice = 'Please enter breakfast price.';
    //if (!lunchPrice || lunchPrice.trim() === '') validationErrors.lunchPrice = 'Please enter lunch price.';
    //if (!dinnerPrice || dinnerPrice.trim() === '') validationErrors.dinnerPrice = 'Please enter dinner price.';

    setErrors(validationErrors);
    console.log(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const savePolicies = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const acceptableIdentityProofs = selectedItems.join(',');
    const policyData = [{
      stay_listing_id : listingId,
      checkin_time : checkInTime,
      checkout_time : checkOutTime,
      cancellation_policy : cancellationPolicy,
      is_unmarried_couples_allowed : unmarriedCouplesAllowed,
      is_minor_guest_allowed : ageRestriction,
      is_only_male_group_allowed : maleOnlyGroupsAllowed,
      acceptable_id_proofs : acceptableIdentityProofs,
      is_same_city_id_allowed : sameCityIdAllowed,
      is_smoking_allowed : smokingAllowed,
      is_private_party_allowed : partiesAllowed,
      is_outside_visitor_allowed : visitorAccessAllowed,
      is_wheelchair_guest_accessible : wheelchairAccessible,
      is_pet_allowed : petAllowed,
      is_pet_in_property : propertyPet,
      is_flexible_checkin_allowed : flexibleCheckin,
      extra_bed_for_adults : extraAdultBed,
      extra_bed_for_kids : extraKidsBed,
      custom_policy : description,
      breakfast_price : breakfastPrice,
      lunch_price : lunchPrice,
      dinner_price : dinnerPrice,
    }];

    let formData = new FormData();
    formData.append("token", hostData.host_access_token);
    formData.append("listing_id", listingId);
    formData.append("data", JSON.stringify({policy: policyData}));

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: formData,
      });

      if (response.data.status === "done") {
        //toast.success("Policies saved successfully.");
        nextStep();
      } else {
        toast.error("Failed to save policies.");
      }
    } catch (error) {
      toast.error("An error occurred while saving policies.");
      console.error(error);
    }
  };

  const handleNext = () => {
    if (validateFields()) {
      savePolicies();
    }
  };

  const handleDescriptionChange = (e) => {
    const value = e.target.value;
    if (value.length <= charLimit) {
      setDescription(value);
    }
    if (errors.description && e.target.value.trim() !== '') {
      const updatedErrors = { ...errors };
      delete updatedErrors.description;
      setErrors(updatedErrors);
    }
  };

  const options = [
    { id: 1, name: "Passport" },
    { id: 2, name: "Aadhar" },
    { id: 3, name: "Driving License" },
    { id: 4, name: "Govt. ID" },
  ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handlePriceChange = (value, setPrice) => {
    const numericValue = value.replace(/[^0-9]/g, '');
    setPrice(numericValue);
  };

  const faqs = [
    {
      question: "What policies should I include?",
      answer: "Mention check-in/check-out times, cancellation policies, house rules, and pet policies (if applicable)."
    },
    {
      question: "Are there predefined templates for policies?",
      answer: "Yes, you can choose from templates or customize your own."
    },
    {
      question: "Can I update policies after listing?",
      answer: "Yes, but changes will apply to new bookings only."
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
        <ScrollToTop />
        <div className="hotel-register-section">

          <div className='row'>
            <div className='col-md-9'>
              <div className="clp-header">
                  <h4 className='ws-title'>Policies</h4>
                  <p className="ws-desc">Mention all the policies applicable at your property.</p>
              </div>

              <div className="room-detail-box">
                <div>
                  <p className='rdb-title mb-0'>Check-in & Check-out Time</p>
                  <p className="label-hint">Specify the check-in & check-out time at your property</p>
                </div>
                <div className='row'>
                  {/*<div className='col-md-6'>
                    <div class="form-box">
                      <label for="checkInTime" class="form-label">Check-in Time</label>
                      <select class="form-select custom-select" value={checkInTime} 
                        onChange={(e) => {
                          setCheckInTime(e.target.value);
                          if (errors.checkInTime && e.target.value.trim() !== '') {
                            const updatedErrors = { ...errors };
                            delete updatedErrors.checkInTime;
                            setErrors(updatedErrors);
                          }
                        }}
                      >
                        <option value="">select</option>
                        {checkInTimeList.map((list) => (
                          <option key={list.id} value={list.id}>
                            {list.title}
                          </option>
                        ))}
                      </select>
                      {errors.checkInTime && <div className="error-text">{errors.checkInTime}</div>}
                    </div>
                  </div>*/}

                  <div className='col-md-6'>
                    <div className="form-box">
                      <label htmlFor="checkInTime" className="form-label">Check-in Time</label>
                      <div className="custom-select">
                        <div
                          className="selected-option"
                          onClick={() => setDropdownOpen(!dropdownOpen)}
                        >
                          <img
                            src={'https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg'}
                            alt=""
                            className="option-icon"
                          />
                          {checkInTimeList.find((item) => item.id === checkInTime)?.title || "Select"}
                        </div>
                        {dropdownOpen && (
                          <ul className="options-list">
                            {checkInTimeList.map((list) => (
                              <li
                                key={list.id}
                                className="option-item"
                                onClick={() => {
                                  setCheckInTime(list.id);
                                  setDropdownOpen(false);
                                }}
                              >
                                <img src={'https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg'} alt="" className="option-icon" />
                                {list.title}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {errors.checkInTime && <div className="error-text">{errors.checkInTime}</div>}
                    </div>
                  </div>
                  <div className='col-md-6'>
                    {/*<div class="form-box">
                      <label for="checkOutTime" class="form-label">Check-out Time</label>
                      <select class="form-select" value={checkOutTime}
                        onChange={(e) => {
                          setCheckOutTime(e.target.value);
                          if (errors.checkOutTime && e.target.value.trim() !== '') {
                            const updatedErrors = { ...errors };
                            delete updatedErrors.checkOutTime;
                            setErrors(updatedErrors);
                          }
                        }}
                      >
                        <option value="">select</option>
                        {checkOutTimeList.map((list) => (
                          <option key={list.id} value={list.id}>
                            {list.title}
                          </option>
                        ))}
                      </select>
                      {errors.checkOutTime && <div className="error-text">{errors.checkOutTime}</div>}
                    </div>*/}
                    <div className="form-box">
                      <label htmlFor="checkOutTime" className="form-label">
                        Check-out Time
                      </label>
                      <div className="custom-select">
                        <div
                          className="selected-option"
                          onClick={() => setDropdownOpenCheckOut(!dropdownOpenCheckOut)}
                        >
                          <img
                            src={'https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg'}
                            alt=""
                            className="option-icon"
                          />
                          {checkOutTimeList.find((item) => item.id === checkOutTime)?.title || "Select"}
                        </div>
                        {dropdownOpenCheckOut && (
                          <ul className="options-list">
                            {checkOutTimeList.map((list) => (
                              <li
                                key={list.id}
                                className="option-item"
                                onClick={() => {
                                  setCheckOutTime(list.id);
                                  setDropdownOpenCheckOut(false);
                                  if (errors.checkOutTime && list.id) {
                                    const updatedErrors = { ...errors };
                                    delete updatedErrors.checkOutTime;
                                    setErrors(updatedErrors);
                                  }
                                }}
                              >
                                <img src={'https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg'} alt="" className="option-icon" />
                                {list.title}
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                      {errors.checkOutTime && <div className="error-text">{errors.checkOutTime}</div>}
                    </div>
                  </div>
                </div>
              </div>

              <div className="room-detail-box">
                <div>
                  <p className='rdb-title mb-0'>Cancellation Policy</p>
                  <p className="label-hint">Select a suitable cancellation policy</p>
                </div>
                <div className='row'>
                  <div className='col-md-12 custom-radio-box' style={{display:'block',border:'none'}}>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="cancellationPolicy" id="24hours" value="1" checked={cancellationPolicy === 1} onChange={() => setCancellationPolicy(1)} />
                      <label class="form-check-label" for="24hours">
                        Free cancellation upto 24 hrs
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="cancellationPolicy" id="48hours" value="2" checked={cancellationPolicy === 2} onChange={() => setCancellationPolicy(2)} />
                      <label class="form-check-label" for="48hours">
                        Free cancellation upto 48 hrs
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="cancellationPolicy" id="72hours" value="3" checked={cancellationPolicy === 3} onChange={() => setCancellationPolicy(3)} />
                      <label class="form-check-label" for="72hours">
                        Free cancellation upto 72 hrs
                      </label>
                    </div>
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="cancellationPolicy" id="nonRefundable" value="4" checked={cancellationPolicy === 4} onChange={() => setCancellationPolicy(4)} />
                      <label class="form-check-label" for="nonRefundable">
                        Non Refundable
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div className="room-detail-box">
                <div>
                  <p className='rdb-title mb-0'>Property Rules(optional)</p>
                  <p className="label-hint">Add property rules basis the requirement of your property listing</p>
                </div>
                
                <div className="polic-sub-section">
                  <div className="hpsb">
                    <p className="rdb-title">Guest Profile</p>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Do you accept unmarried couples?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="unmarriedCouple" value="2" checked={unmarriedCouplesAllowed === 2} onChange={() => setUnmarriedCouplesAllowed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="unmarriedCouple" value="1" checked={unmarriedCouplesAllowed === 1} onChange={() => setUnmarriedCouplesAllowed(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Do you allow guests under 18?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="minorGuest" value="2" checked={ageRestriction === 2} onChange={() => setAgeRestriction(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="minorGuest" value="1" checked={ageRestriction === 1} onChange={() => setAgeRestriction(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Are male-only groups allowed?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="maleGuest" value="2" checked={maleOnlyGroupsAllowed === 2} onChange={() => setMaleOnlyGroupsAllowed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="maleGuest" value="1" checked={maleOnlyGroupsAllowed === 1} onChange={() => setMaleOnlyGroupsAllowed(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="polic-sub-section">
                  <div className="hpsb">
                    <p className="rdb-title">Acceptable Identity Proofs</p>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-box">
                      <FormControl fullWidth>
                        <InputLabel>Acceptable Identity Proofs</InputLabel>
                        <Select
                          label="Acceptable Identity Proofs"
                          multiple
                          value={selectedItems}
                          onChange={(event) => setSelectedItems(event.target.value)}
                          renderValue={(selected) => {
                            const selectedOptions = options.filter((option) => selected.includes(option.id));
                            const displayedOptions = selectedOptions.slice(0, 2);
                            const remainingCount = selectedOptions.length - 2;

                            return (
                              <>
                                {displayedOptions.map((option) => option.name).join(', ')}
                                {remainingCount > 0 && ` +${remainingCount} more`}
                              </>
                            );
                          }}
                        >
                          {options.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              <Checkbox checked={selectedItems.includes(option.id)} />
                              <ListItemText primary={option.name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="dypxbs">
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Do you allow IDs from the same city as the property?</p>
                        </div>
                        <div className="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input
                                type="radio"
                                name="sameCityId"
                                value="2"
                                checked={sameCityIdAllowed === 2}
                                onChange={() => setSameCityIdAllowed(2)}
                              />
                              <span>No</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="sameCityId"
                                value="1"
                                checked={sameCityIdAllowed === 1}
                                onChange={() => setSameCityIdAllowed(1)}
                              />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="polic-sub-section">
                  <div className="hpsb">
                    <p className="rdb-title">Property Restrictions</p>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Is smoking allowed on the property?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="smookingAllowed" value="2" checked={smokingAllowed === 2} onChange={() => setSmokingAllowed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="smookingAllowed" value="1" checked={smokingAllowed === 1} onChange={() => setSmokingAllowed(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Are private parties or events permitted?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="privateParties" value="2" checked={partiesAllowed === 2} onChange={() => setPartiesAllowed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="privateParties" value="1" checked={partiesAllowed === 1} onChange={() => setPartiesAllowed(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Can guests invite outside visitors to their room?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="outsideVisitor" value="2" checked={visitorAccessAllowed === 2} onChange={() => setVisitorAccessAllowed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="outsideVisitor" value="1" checked={visitorAccessAllowed === 1} onChange={() => setVisitorAccessAllowed(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Is the property wheelchair accessible?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="wheelchairGuest" value="2" checked={wheelchairAccessible === 2} onChange={() => setWheelchairAccessible(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="wheelchairGuest" value="1" checked={wheelchairAccessible === 1} onChange={() => setWheelchairAccessible(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="polic-sub-section">
                  <div className="hpsb">
                    <p className="rdb-title">Pet Policy</p>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Are pets allowed?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="petAllowed" value="2" checked={petAllowed === 2} onChange={() => setPetAllowed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="petAllowed" value="1" checked={petAllowed === 1} onChange={() => setPetAllowed(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Do any pets live on the property?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="propertyPet" value="2" checked={propertyPet === 2} onChange={() => setPropertyPet(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="propertyPet" value="1" checked={propertyPet === 1} onChange={() => setPropertyPet(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="polic-sub-section">
                  <div className="hpsb">
                    <p className="rdb-title">Check-in and Check-out Policies</p>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Is 24-hour check-in available?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="flexibleCheckin" value="2" checked={flexibleCheckin === 2} onChange={() => setFlexibleCheckin(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="flexibleCheckin" value="1" checked={flexibleCheckin === 1} onChange={() => setFlexibleCheckin(1)} />
                              <span>Yes</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="polic-sub-section">
                  <div className="hpsb">
                    <p className="rdb-title">Extra Bed Policies</p>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div style={{width:'auto'}}>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Do you provide extra beds for adults?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="extraAdultBed" value="2" checked={extraAdultBed === 2} onChange={() => setExtraAdultBed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="extraAdultBed" value="1" checked={extraAdultBed === 1} onChange={() => setExtraAdultBed(1)} />
                              <span>Yes</span>
                            </label>
                            <label>
                              <input type="radio" name="extraAdultBed" value="3" checked={extraAdultBed === 3} onChange={() => setExtraAdultBed(3)} />
                              <span>Subject to Availability</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='col-md-12'>
                      <div className='dypxbs'>
                        <div style={{width:'auto'}}>
                          <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                          <p>Do you provide extra beds for kids?</p>
                        </div>
                        <div class="extra-bed-radio">
                          <div className="exdrs">
                            <label>
                              <input type="radio" name="extraKidBed" value="2" checked={extraKidsBed === 2} onChange={() => setExtraKidsBed(2)} />
                              <span>No</span>
                            </label>
                            <label>
                              <input type="radio" name="extraKidBed" value="1" checked={extraKidsBed === 1} onChange={() => setExtraKidsBed(1)} />
                              <span>Yes</span>
                            </label>
                            <label>
                              <input type="radio" name="extraKidBed" value="3" checked={extraKidsBed === 3} onChange={() => setExtraKidsBed(3)} />
                              <span>Subject to Availability</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="polic-sub-section">
                  <div className="hpsb">
                    <p className="rdb-title">Custom Policy</p>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='form-box'>
                        <label for="roomDesc" class="form-label">Custom Policy</label>
                        <textarea
                          className='form-control'
                          id='roomDesc'
                          rows={5}
                          value={description}
                          onChange={handleDescriptionChange}
                        />
                        <div className='hhtbs'>
                        {charLimit - (description?.length || 0)} characters available
                        </div>
                        {errors.description && <div className="error-text">{errors.description}</div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="polic-sub-section">
                  <div className="hpsb">
                    <p className="rdb-title">Meal rack prices</p>
                  </div>
                  <div className="form-box">
                    <div className="row">
                      <div className="col-sm-6 rmpl">
                        <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                        <label htmlFor="breakfast">Breakfast</label>
                      </div>
                      <div className="col-sm-6">
                        <input type="text" className="form-control" id="breakfast" value={breakfastPrice} onChange={(e) => handlePriceChange(e.target.value, setBreakfastPrice)} />
                      </div>
                    </div>
                  </div>
                  <div className="form-box">
                    <div className="row">
                      <div className="col-sm-6 rmpl">
                        <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                        <label htmlFor="lunch">Lunch</label>
                      </div>
                      <div className="col-sm-6">
                        <input type="text" className="form-control" id="lunch" value={lunchPrice} onChange={(e) => handlePriceChange(e.target.value, setLunchPrice)} />
                      </div>
                    </div>
                  </div>
                  <div className="form-box">
                    <div className="row">
                      <div className="col-sm-6 rmpl">
                        <img src={"/assets/img/dummy_amenity_icon.svg"} alt="" className="img-fluid cu-icon" />
                        <label htmlFor="dinner">Dinner</label>
                      </div>
                      <div className="col-sm-6">
                        <input type="text" className="form-control" id="dinner" value={dinnerPrice} onChange={(e) => handlePriceChange(e.target.value, setDinnerPrice)} />
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            <div className="col-md-3">
              <div className="faq-sticky">
                <div className='fas-desc-box'>
                  <div className='faq-title-box'>
                    <h4 className='faq-title-txt'>What to do here ?</h4>
                  </div>
                  <p className='faq-desc'>Set the ground rules because clear policies make happy guests!</p>
                </div>
                <hr></hr>
                <div className='faq-txt-box'>
                  <img src='/assets/img/faq_banner_2.gif' className='img-fluid' alt='' />
                </div>
                {faqs.map((faq, index) => (
                  <div key={index} className='faqs-list-section'>
                    <div
                      onClick={() => toggleAccordion(index)} className='faqs-qustn-box'
                      style={{
                        backgroundColor: activeIndex === index ? "#f7f7f7" : "#ffffff",
                        borderBottom: activeIndex === index ? "1px solid #ccc" : "none",
                      }}
                    >
                      {faq.question}
                      <i
                        className={`fas ${
                          activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                        style={{
                          transition: "transform 0.3s ease",
                        }}
                      ></i>
                    </div>
                    {activeIndex === index && (
                      <div className='faqs-ans-box'>
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>

        </div>

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep}>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Next</button>
        </div>

    </div>
  );
};

export default Policies;
