import React, { useState, useEffect } from "react";
import axios from 'axios';
import params from "../../config/Params";
import { getItemFromLocalStorage } from '../../utils/storageUtils';
import { useParams } from 'react-router-dom'; 
import { Helmet } from 'react-helmet';
import BasicInfo from "./components/BasicInfo";
import Location from "./components/Location";
import Amanities from "./components/Amanities";
import Rooms from "./components/Rooms";
import Photos from "./components/Photos";
import Policies from "./components/Policies";
import Legal from "./components/Legal";

const Onboarding = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([]);
  const { listingId } = useParams();
  const [formData] = useState({
    listingId: listingId || null,
  }); 

  //console.log(listingId);

  useEffect(() => {      
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          const data = response.data.updated_property_item;
          const stepsCompleted = [];
          if (data.listing_title) stepsCompleted.push(0);
          if (data.property_address) stepsCompleted.push(1);
          if (data.amanities && data.amanities.length > 0) stepsCompleted.push(2);
          if (data.rooms && data.rooms.length > 0) stepsCompleted.push(3);
          if (data.photos && data.photos.length > 0) stepsCompleted.push(4);
          if (data.policies) stepsCompleted.push(5);
          if (data.legal) stepsCompleted.push(6);
          setCompletedSteps(stepsCompleted);
          //console.log(stepsCompleted);
        } else {
          console.log('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchListingData();
  }, []);

  const steps = [
    { title: "Basic Info", component: 
      <BasicInfo formData={formData}
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)} 
      /> },
    { title: "Location", component: 
      <Location formData={formData}
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Amenities", component: 
      <Amanities formData={formData}
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Rooms", component: 
      <Rooms formData={formData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Photos and Videos", component: 
      <Photos formData={formData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Policies", component: 
      <Policies formData={formData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
    { title: "Finance & Legal", component: 
      <Legal formData={formData} 
        nextStep={() => setCurrentStep((prev) => prev + 1)}
        prevStep={() => setCurrentStep((prev) => prev - 1)}
      /> },
  ];

  const isStepComplete = () => {
    switch (currentStep) {
      default:
        return true;
    }
  };

  const handleTabClick = (index) => {
    if (index <= currentStep || completedSteps.includes(index)) {
        setCurrentStep(index);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Hotel Listing</title>
        <meta name='description' content='' />
      </Helmet>

      {/* Tab Navigation */}
      <div className="tabs">
        {steps.map((step, index) => (
          <button
            key={index}
            className={`tab ${currentStep === index ? "active" : ""} ${
                index > currentStep && !completedSteps.includes(index) ? "disabled" : ""
            }`}
            onClick={() => handleTabClick(index)}
            disabled={index > currentStep && !completedSteps.includes(index)}
          >
            {completedSteps.includes(index) && (
              <span className="tick">
                <img src={"/assets/img/icons/checked_icon.png"} alt="" className="img-fluid cat-img" />
              </span>
            )}
            {step.title}{" "}
          </button>
        ))}
      </div>

      {/* Step Content */}
      <div className="step-content">{steps[currentStep].component}</div>

    </div>
  );
};

export default Onboarding;
