import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../../config/Params';
import { getItemFromLocalStorage } from '../../utils/storageUtils';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../ScrollToTop ';
import { useNavigate } from "react-router-dom";

const Active = () => {
    const [offerList, setOfferList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
      document.body.style.width = "100%";
      document.body.style.height = "100%";
      document.body.style.backgroundImage = `
        linear-gradient(#ccc 1.5px, transparent 1.5px),
        linear-gradient(90deg, #ccc 1.5px, transparent 1.5px),
        linear-gradient(#ccc 1px, transparent 1px),
        linear-gradient(90deg, #ccc 1px, transparent 1px)
      `;
      document.body.style.backgroundPosition = "-1.5px -1.5px, -1.5px -1.5px, -1px -1px, -1px -1px";
      document.body.style.backgroundSize = "0 100px, 0 100px, 200px 200px, 200px 200px";
  
      return () => {
        document.body.style = "";
      };
    }, []);
  
    useEffect(() => {
      
        const fetchOfferData = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
        
            try {
              const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                url: `${params.baseURL}${params.endpoints.getOffers}`,
                data: formData,
              });
        
              if (response.data.status === 'ok') {
                setOfferList(response.data.offers);
              } else {
                toast.error('An error occurred while fetching data.');
              }
            } catch (err) {
              console.log(err.message);
            }
        };
  
        fetchOfferData();
    }, []);

    const editOffer = (offerId) => {
        navigate(`/hotel/promotions/edit-offer/${offerId}`);
    };

    const formatDate = (dateString) => {
      const date = new Date(dateString);
  
      const day = date.getDate();
      const month = date.toLocaleString("default", { month: "short" });
      const year = date.getFullYear();
  
      const getDaySuffix = (d) => {
        if (d > 3 && d < 21) return "th";
        switch (d % 10) {
          case 1:
            return "st";
          case 2:
            return "nd";
          case 3:
            return "rd";
          default:
            return "th";
        }
      };
  
      return `${day}${getDaySuffix(day)} ${month} ${year}`;
    };
  
    return (
        <div>
          <Helmet>
            <title>Active Promotions</title>
            <meta name="description" content="" />
          </Helmet>
          <ScrollToTop />
          <div className="hotel-register-section">

            <div className='clp-header' style={{display:'flex', 'justifyContent':'space-between'}}>
              <div>
                  <h1 className='ws-title'>Promotions List</h1>
                  <p className='ws-desc'>Amp up your bookings! Create, tweak, or double down on your irresistible offers right here!</p>
              </div>
              <a href='/hotel/promotions/new-offer'>
                  <span className='yl-create-btn'>
                      <i class="fas fa-plus"></i>
                  </span>
              </a>
            </div>
            
            <div className="row">
                <div className='col-md-12'>
                  {offerList.length > 0 ? (
                      offerList.map((offer) => (
                          <div key={offer.stay_listing_offer_id} className="offer-card">
                              <div className='offer-detail-box'>
                                  <div className='otxtbx'>
                                      <div className='oimgbx'>
                                        <img src={'/assets/img/icons/promotion_icon.png'} alt="Profile" className="img-fluid" />
                                      </div>
                                      <span className='pline'></span>
                                      <div>
                                        <h4>{offer.offer_name}</h4>
                                        <p>Created on: {formatDate(offer.offer_created_time)}</p>
                                        <p 
                                          style={{
                                            color: offer.offer_status === 1 ? "green" : "red",
                                          }}
                                        >
                                          Status: {offer.offer_status === 1 ? 'Active' : 'Inactive'}
                                        </p>
                                      </div>
                                  </div>
                                  <div className='oabstn'>
                                      <button onClick={() => editOffer(offer.stay_listing_offer_id)} className='offer-action-btn'> Modify </button>
                                  </div>
                              </div>
                          </div>
                      ))
                  ) : (
                      <div className="no-promotions">
                          <p>No promotions found. Please create one.</p>
                      </div>
                  )}
                </div>
            </div>
          </div>
        </div>
      );
    };
    
    export default Active;