import React, { useState, useEffect } from "react";
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';

const Earnings = () => {
  const [earnings, setEarnings] = useState([]);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [weeklyEarnings, setWeeklyEarnings] = useState(0);
  const [monthlyEarnings, setMonthlyEarnings] = useState(0);
  const [filters, setFilters] = useState({
    dateFrom: "",
    dateTo: "",
  });

  useEffect(() => {
    fetchEarnings();
  }, [filters]);

  const fetchEarnings = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('date_from', filters.dateFrom || '');
    formData.append('date_to', filters.dateTo || '');

    try {
      const response = await axios.post(
        `${params.baseURL}${params.endpoints.getEarnings}`,
        formData,
        { headers: { 'Content-Type': 'multipart/form-data' } }
      );

      if (response.data.status === 'success') {
        const data = response.data.data;

        const total = data.reduce((sum, item) => sum + parseFloat(item.total_earning || 0), 0);

        const week = data.reduce((sum, item) => {
          if (isThisWeek(item.booking_date)) {
            return sum + parseFloat(item.total_earning || 0);
          }
          return sum;
        }, 0);

        const month = data.reduce((sum, item) => {
          if (isThisMonth(item.booking_date)) {
            return sum + parseFloat(item.total_earning || 0);
          }
          return sum;
        }, 0);

        setEarnings(data);
        setTotalEarnings(total);
        setWeeklyEarnings(week);
        setMonthlyEarnings(month);
      } else {
        toast.error('Unable to fetch earnings. Try again.');
      }
    } catch (error) {
      console.error('Error fetching earnings:', error);
      toast.error('An error occurred while fetching earnings.');
    }
  };

  const handleFilterChange = (e) => {
    setFilters({
      ...filters,
      [e.target.name]: e.target.value,
    });
  };

  /*const handleSearch = () => {
    fetchEarnings();
  };*/

  const isThisWeek = (date) => {
    const today = new Date();
    const bookingDate = new Date(date);
    const startOfWeek = new Date(today.setDate(today.getDate() - today.getDay()));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);
    return bookingDate >= startOfWeek && bookingDate <= endOfWeek;
  };

  const isThisMonth = (date) => {
    const today = new Date();
    const bookingDate = new Date(date);
    return bookingDate.getMonth() === today.getMonth() && bookingDate.getFullYear() === today.getFullYear();
  };

  return (
    <div>
      <Helmet>
        <title>Earnings</title>
        <meta name="description" content="" />
      </Helmet>

      <div className="earning-section">

        <section class="earning-glass">
            <div class="balance-card">
                <span class="earning-label">Total Earnings</span>
                <h4 id="totalBalance">{totalEarnings.toFixed(2)} INR</h4>
                <div class="balance-stats">
                    <div class="stat">
                        <span class="earning-label">Monthly Earnings</span>
                        <span id="totalIncome" class="income">{monthlyEarnings.toFixed(2)} INR</span>
                    </div>
                    <div class="stat">
                        <span class="earning-label">Weekly Earnings</span>
                        <span id="totalExpenses" class="expense">{weeklyEarnings.toFixed(2)} INR</span>
                    </div>
                </div>
            </div>
        </section>

        <div class="quick-actions earning-glass">
            <div>
                <label htmlFor="dateFrom" class="form-label">From Date</label>
                <div className='iwtb'>
                    <img src={'/assets/img/icons/calendar_black.png'} alt="Profile" className="form-icon" />
                    <input
                        type="date"
                        name="dateFrom"
                        id="dateFrom"
                        class="glass-input"
                        value={filters.dateFrom}
                        onChange={handleFilterChange}
                    />
                </div>
            </div>
            <div>
                <label htmlFor="dateTo" class="form-label">To Date</label>
                <div className='iwtb'>
                    <img src={'/assets/img/icons/calendar_black.png'} alt="Profile" className="form-icon" />
                    <input
                        type="date"
                        name="dateTo"
                        id="dateTo"
                        class="glass-input"
                        value={filters.dateTo}
                        onChange={handleFilterChange}
                    />
                </div>
            </div>
            {/*<button id="addTransaction" class="btn-primary" onClick={handleSearch}>Search</button>*/}
        </div>

        <div class="dashboard-grid">
            <div class="spending-overview earning-glass">
                <h2 className="tnstxt">Daywise Transactions</h2>
                <div class="progress-container">
                    {earnings.map((earning) => (
                        <div key={earning.booking_date} class="progress-item">
                            <div class="progress-label">
                                <div className="iwtb">
                                    <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                                    <span>{earning.booking_date}</span>
                                </div>
                                <span id="mandatoryTotal">
                                    {earning?.total_earning ? parseFloat(earning.total_earning).toFixed(2) : "0.00"} INR
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            <div class="recent-transactions earning-glass">
                <h2 className="tnstxt">Recent Transactions</h2>
                <div id="transactionsList" class="transactions-list"></div>
            </div>

        </div>

      </div>

    </div>
  );
};

export default Earnings;
