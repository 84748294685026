import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ThemeWrapper from '../ThemeWrapper ';

const CalendarView = () => {
    const { listingId } = useParams();
    const { roomId } = useParams();
    const [affiliateId, setAffiliateId] = useState('');
    const [selectedDate, setSelectedDate] = useState(null);
    const [daywisePrice, setDaywisePrice] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedDates, setSelectedDates] = useState([]);
    const [isEditingPrice, setIsEditingPrice] = useState(false);
    const [selectedPriceDate, setSelectedPriceDate] = useState(null);
    const [newPrice, setNewPrice] = useState('');
    const [basePrice, setBasePrice] = useState(0);
    const [showMore, setShowMore] = useState(false);
    const numericPrice = parseFloat(newPrice) || 0;
    const guestServiceFee = (numericPrice * 0.1).toFixed(2);
    const guestPriceBeforeTaxes = (numericPrice + parseFloat(guestServiceFee)).toFixed(2);
    const userEarns = (numericPrice);
    const [isBlocked, setIsBlocked] = useState(false);
    const currentDate = new Date();
    const [categoryId, setCategoryId] = useState('');
    const [roomwisePrice, setRoomwisePrice] = useState({});

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const getFutureMonths = () => {
        const months = [];
        const now = new Date();
        for (let i = 0; i < 12; i++) {
            const date = new Date(now.getFullYear(), now.getMonth() + i, 1);
            months.push(date);
        }
        return months;
    };

    useEffect(() => {
        const fetchListingData = async () => {
          const hostData = getItemFromLocalStorage('hostProfile');
          let formData = new FormData();
          formData.append('token', hostData.host_access_token);
          formData.append('listing_id', listingId);
      
          try {
            const response = await axios({
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              url: `${params.baseURL}${params.endpoints.getListingData}`,
              data: formData,
            });
      
            if (response.data.status === 'ok') {
              const listingData = response.data.updated_property_item;
              setCategoryId(response.data.updated_property_item.category_id);
              try {
                setBasePrice(listingData.listing_price || 0);
                setAffiliateId(listingData.affiliate_id || '');
              } catch (error) {
                console.error('Parsing error:', error);
              }
            } else {
                console.error('An error occurred while fetching data.');
            }
          } catch (err) {
            console.log(err.message);
          }
        };
      
        fetchListingData();
    }, [listingId]); 

    useEffect(() => {
        const fetchDaywisePrice = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('listing_id', listingId);

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    url: `${params.baseURL}${params.endpoints.getDaywisePrice}`,
                    data: formData,
                });

                if (response.data.status === 'found') {
                    const prices = response.data.day_wise_prices.reduce((acc, price) => {
                        acc[price.price_date] = {
                            listing_price: parseFloat(price.listing_price),
                            status: price.price_date_status,
                            hotel_room_id:price.hotel_room_id,
                        };
                        return acc;
                    }, {});
                    setDaywisePrice(prices);

                    const roomPrices = response.data.day_wise_prices.reduce((acc, roomPrice) => {
                        const roomId = roomPrice.hotel_room_id;
                        acc[roomId] = acc[roomId] || {};
                        acc[roomId][roomPrice.price_date] = {
                        listing_price: parseFloat(roomPrice.listing_price),
                        status: roomPrice.price_date_status,
                        };
                        return acc;
                   }, {});
                   setRoomwisePrice(roomPrices);

                    setLoading(false);
                } else {
                    setError('An error occurred while fetching data.');
                }
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchDaywisePrice();
    }, [listingId]);

    useEffect(() => {
        if (selectedDate) {
          const dateStatus = daywisePrice[selectedDate]?.status;       
          console.log(dateStatus);
          setIsBlocked(dateStatus === 2);
        }
    }, [selectedDate, daywisePrice]);

    const tileClassName = useCallback(
        ({ date, view }) => {
            const utcDate = new Date(Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            ));
            
            const dateString = utcDate.toISOString().split('T')[0];
            if (view === 'month' && date.toDateString() === currentDate.toDateString()) {
                return 'highlight-today';
            }
            if (selectedDates.some(selected => selected.toDateString() === date.toDateString())) {
                return 'highlight-selected';
            }
            if (daywisePrice[dateString]?.status === "2") {
                return 'blocked-date';
            }
          return null;
        },
        [currentDate, selectedDates, daywisePrice]
    );

    const getPriceForDate = (date) => {
        const utcDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        ));
        
        const dateString = utcDate.toISOString().split('T')[0];
        return daywisePrice[dateString]?.listing_price || basePrice;
        //return roomwisePrice[roomId]?.[dateString]?.listing_price || basePrice;
    };

    const handleDateClick = (date) => {
        if (date < currentDate) return;
        const isDateSelected = selectedDates.some(selected => selected.toDateString() === date.toDateString());

        if (isDateSelected) {
            setSelectedDates(selectedDates.filter(selected => selected.toDateString() !== date.toDateString()));
        } else {
            setSelectedDates([...selectedDates, date]);
        }

        const utcDate = new Date(Date.UTC(
            date.getFullYear(),
            date.getMonth(),
            date.getDate()
        ));
        
        const dateString = utcDate.toISOString().split('T')[0];
        if (daywisePrice[dateString]?.status === '2') {
            setIsBlocked(true);
        } else {
            setIsBlocked(false);
        }
    };

    const handlePriceClick = (date) => {
        setIsEditingPrice(true);
        setSelectedPriceDate(date);
        setNewPrice(getPriceForDate(date));
    };

    const handleToggleChange = (status) => {
        setIsBlocked(status === 2);
        updatePricesBasedOnStatus(status);
    };

    const updatePricesBasedOnStatus = async (status) => {
        const hostData = getItemFromLocalStorage('hostProfile');
    
        const updatedPricingData = Object.keys(daywisePrice).map((priceDate) => {
            const priceValue = daywisePrice[priceDate];
            const normalizedPrice = typeof priceValue === 'object' ? priceValue.listing_price : priceValue;
    
            return {
                listing_guest_fees: (parseFloat(normalizedPrice) * 0.10).toFixed(2),
                listing_host_fees: 0.0, 
                listing_price: parseFloat(normalizedPrice), // Ensure it's a number
                price_date: priceDate,
                price_date_status: priceValue?.status || status, // Use status or fallback to priceValue status
                hotel_room_id: roomId,
            };
        });
    
        selectedDates.forEach(date => {
            const utcDate = new Date(Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            ));
            
            const dateString = utcDate.toISOString().split('T')[0];
            const price = getPriceForDate(date);
            const listingGuestFees = (parseFloat(price) * 0.10).toFixed(2);
    
            updatedPricingData.push({
                listing_guest_fees: listingGuestFees,
                listing_host_fees: 0.0,
                listing_price: parseFloat(price), // Ensure it's a number
                price_date: dateString,
                price_date_status: status, // Apply the provided status for selected dates
                hotel_room_id: roomId,
            });
        });
    
        const data = JSON.stringify({ pricing: updatedPricingData });
    
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);
        formData.append('listing_id', listingId);
        formData.append('data', data);
    
        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: `${params.baseURL}${params.endpoints.updateDaywisePrice}`,
                data: formData,
            });
    
            if (response.data.status === 'done') {
                const updatedPrices = { ...daywisePrice };
    
                selectedDates.forEach(date => {
                    const utcDate = new Date(Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                    ));
                    const dateString = utcDate.toISOString().split('T')[0];
                    updatedPrices[dateString] = {
                        listing_price: parseFloat(getPriceForDate(date)),
                        status: status.toString(), // Ensure status is a string
                    };
                });
    
                console.log(updatedPrices);
                setDaywisePrice(updatedPrices);
                setSelectedDates([]); // Clear the selected dates after updating
            } else {
                alert('Failed to update the price.');
            }
        } catch (error) {
            alert('Error updating price.');
        }
    };    
    
    const handlePriceUpdate = async () => {
        const hostData = getItemFromLocalStorage('hostProfile');
        const listingGuestFees = (parseFloat(newPrice) * 0.10).toFixed(2); 
    
        const updatedPricingData = Object.keys(daywisePrice).map((priceDate) => {
            // Check if the price is from selected dates
            if (selectedDates.some(date => date.toISOString().split('T')[0] === priceDate)) {
                return {
                    listing_guest_fees: listingGuestFees,
                    listing_host_fees: 0.0, 
                    listing_price: parseFloat(newPrice), // Ensure it's a number, not an object
                    price_date: priceDate,
                    price_date_status: 1,
                    hotel_room_id: roomId,
                };
            }
    
            // Handle non-selected dates (use the value directly if it's not an object)
            const priceValue = daywisePrice[priceDate];
            const normalizedPrice = typeof priceValue === 'object' ? priceValue.listing_price : priceValue;
            
            return {
                listing_guest_fees: (normalizedPrice * 0.10).toFixed(2),
                listing_host_fees: 0.0, 
                listing_price: normalizedPrice, // Ensure it's a number, not an object
                price_date: priceDate,
                price_date_status: 1,
                hotel_room_id: roomId,
            };
        });
    
        selectedDates.forEach(date => {
            const utcDate = new Date(Date.UTC(
                date.getFullYear(),
                date.getMonth(),
                date.getDate()
            ));
            
            const dateString = utcDate.toISOString().split('T')[0];
            updatedPricingData.push({
                listing_guest_fees: listingGuestFees,
                listing_host_fees: 0.0,
                listing_price: parseFloat(newPrice), // Ensure it's a number, not an object
                price_date: dateString,
                price_date_status: 1,
                hotel_room_id: roomId,
            });
        });
    
        const data = JSON.stringify({ pricing: updatedPricingData });
    
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);
        formData.append('listing_id', listingId);
        formData.append('data', data);
    
        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                url: `${params.baseURL}${params.endpoints.updateDaywisePrice}`,
                data: formData,
            });
    
            if (response.data.status === 'done') {
                const updatedDaywisePrice = { ...daywisePrice };
                selectedDates.forEach(date => {
                    const utcDate = new Date(Date.UTC(
                        date.getFullYear(),
                        date.getMonth(),
                        date.getDate()
                    ));
                    const dateString = utcDate.toISOString().split('T')[0];
                    updatedDaywisePrice[dateString] = {
                        listing_price: parseFloat(newPrice), // Ensure it's a number, not an object
                        status: updatedDaywisePrice[dateString]?.status,
                    };
                });
                setDaywisePrice(updatedDaywisePrice);
                setIsEditingPrice(false);
            } else {
                alert('Failed to update the price.');
            }
        } catch (error) {
            alert('Error updating price.');
        }
    };
    
    const handleCancel = () => {
        setIsEditingPrice(false);
    };

    const handlePriceChange = (value) => {
        value = parseInt(value || "0", 10);
        value = Math.min(Math.max(0, value), 50000);
        setNewPrice(value);
    };

    const formatDateRange = () => {
        if (selectedDates.length === 0) return null;
        const sortedDates = [...selectedDates].sort((a, b) => a - b);

        let groupedDates = [];
        let rangeStart = sortedDates[0];
        let rangeEnd = rangeStart;

        for (let i = 1; i < sortedDates.length; i++) {
            const current = sortedDates[i];
            const previous = sortedDates[i - 1];
            const differenceInDays = (current - previous) / (1000 * 60 * 60 * 24);
            if (differenceInDays === 1) {
                rangeEnd = current;
            } else {
                groupedDates.push([rangeStart, rangeEnd]);
                rangeStart = current;
                rangeEnd = current;
            }
        }
        groupedDates.push([rangeStart, rangeEnd]);

        return groupedDates.map(range => {
            const start = range[0].toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
            const end = range[1].toLocaleDateString('en-GB', { day: 'numeric', month: 'short' });
            return start === end ? start : `${start} - ${end}`;
        }).join(', ');
    };

    const calculateTotalNights = () => selectedDates.length;

    const calculateTotalPrice = () => {
        return selectedDates.reduce((total, date) => getPriceForDate(date), 0);
    };

    const getSidebarContent = () => {
        if (isEditingPrice && selectedPriceDate) {

            return (
                <>
                    <div className='cvsp-date'>
                        <p>Update price</p>
                    </div>
                    <div className='cvsp-price'>
                    <input
                        type="number"
                        value={newPrice}
                        onChange={(e) => handlePriceChange(e.target.value)}
                        placeholder="Enter new price"
                        min="0"
                        max="50000"
                    />
                    </div>
                    <div className='clspb-div'>
                        <div className='clspb-box'>
                            <span>Base Price</span>
                            <span>{numericPrice.toFixed(2)}</span>
                        </div>
                        <div className='clspb-box'>
                            <span>Guest Service Fee</span>
                            <span>{guestServiceFee}</span>
                        </div>
                        <div className='clspb-box'>
                            <span>Guest Price Before Taxes</span>
                            <span>{guestPriceBeforeTaxes}</span>
                        </div>
                    </div>
                    {showMore && (
                        <div className='clspb-div'>
                            <div className='clspb-box border-0'>
                                <span>You Earn</span>
                                <span>{userEarns}</span>
                            </div>
                            { affiliateId && (
                                <div className='affiliate-calculation-box'>
                                <p className='aff-calc-title'>Your earning after Affiliate Charges</p>
                                <div className='affiliate-calc'>
                                    <div className='aff-calc-img'>
                                    <img src={'/assets/img/icons/wsac_1.svg'} alt="Notification" className="wsac-icon" />
                                    <span>month 10% of {userEarns} = {(userEarns * 0.1).toFixed(2)}</span>
                                    </div>
                                    <span>{(userEarns - userEarns * 0.1).toFixed(2)}</span>
                                </div>
                                <div className='affiliate-calc'>
                                    <div className='aff-calc-img'>
                                    <img src={'/assets/img/icons/wsac_2.svg'} alt="Notification" className="wsac-icon" />
                                    <span>month 5% of {userEarns} = {(userEarns * 0.05).toFixed(2)}</span>
                                    </div>
                                    <span>{(userEarns - userEarns * 0.05).toFixed(2)}</span>
                                </div>
                                <div className='affiliate-calc'>
                                    <div className='aff-calc-img'>
                                    <img src={'/assets/img/icons/wsac_3.svg'} alt="Notification" className="wsac-icon" />
                                    <span>month 3% of {userEarns} = {(userEarns * 0.03).toFixed(2)}</span>
                                    </div>
                                    <span>{(userEarns - userEarns * 0.03).toFixed(2)}</span>
                                </div>
                                </div>
                            )}
                        </div>
                    )}
                    <span onClick={toggleShowMore} className="show-more-btn">
                        {showMore ? 'Show Less' : 'Show More'}
                    </span>

                    <button className='cvsp-edit-btn' onClick={handlePriceUpdate}>save</button>
                    <button className='cvsp-cancel-btn' onClick={handleCancel}>Cancel</button>
                </>
            );
        } else if (selectedDates.length > 0) {
        
            return (
                <>
                    {/*<div className='cvsp-date'>
                        <p>{formatDateRange()}</p>
                    </div>*/}
                    <div className='cvsp-date'>
                        <p>{calculateTotalNights()} night(s)</p>
                    </div> 
                    <div>
                        <div id="firstFilter" class="filter-switch">
                            <input 
                                id="openDate" name="openClose" type="radio"
                                checked={!isBlocked}
                                onClick={() => handleToggleChange(1)}
                            />
                            <label class="option" for="openDate">Open</label>
                            <input 
                                id="blockDate" name="openClose" type="radio" 
                                checked={isBlocked}
                                onClick={() => handleToggleChange(2)}
                            />
                            <label class="option" for="blockDate">Block night</label>
                            <span class="background"></span>
                        </div>
                    </div>
                    <div className='cvsp-price'>
                        <p><i class="fas fa-rupee-sign"></i>  {calculateTotalPrice()}</p>
                    </div>
                    <button className='cvsp-edit-btn' onClick={() => handlePriceClick(selectedDates[0])}>
                        Edit Price
                    </button>
                </>
            );
        } else {
            
            return (
                <>
                    <div className='cvsp-date'>
                        <p>{currentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })}</p>
                    </div>                   
                    <div className='cvsp-price'>
                        <p><i class="fas fa-rupee-sign"></i> {getPriceForDate(currentDate)}</p>
                    </div>

                    <div>
                        <p>Disclaimer- The prices you set here will be displayed to guests while booking. Please review carefully to ensure they are accurate and up-to-date.</p>
                    </div>
                </>
            );
        }
    };

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading listing: {error.message}</p>;
    }

    return (
        <ThemeWrapper categoryId={categoryId}>
            <div>
                <Helmet>
                    <title>Edit Price</title>
                    <meta name='description' content='' />
                </Helmet>
                <p className='ws-desc'>Flex your hosting skills! Fine-tune prices and open doors on your terms.</p>

                <div className={`calendar-view ${selectedDates.length > 0 ? 'with-side-panel' : ''}`}>
                    <div className={`calendar-section ${selectedDates.length > 0 ? 'calendar-narrow' : 'calendar-full'}`}>
                        <div className="weekdays-header">
                            <div>Sun</div>
                            <div>Mon</div>
                            <div>Tue</div>
                            <div>Wed</div>
                            <div>Thu</div>
                            <div>Fri</div>
                            <div>Sat</div>
                        </div>

                        <div className="scrollable-calendar-container">
                            {getFutureMonths().map((month, index) => (
                                <div className="month-container" key={index}>
                                    <h3>{month.toLocaleString('default', { month: 'long', year: 'numeric' })}</h3>
                                    <Calendar
                                        key={`${month}`}
                                        value={month}
                                        locale="en-US"
                                        tileContent={({ date }) => (
                                            <div className="price-tile">
                                                <i class="fas fa-rupee-sign"></i>
                                                {getPriceForDate(date)}
                                            </div>
                                        )}
                                        onClickDay={handleDateClick}
                                        tileClassName={tileClassName}
                                        showNeighboringMonth={false}
                                        minDetail="month"
                                        maxDetail="month"
                                        showNavigation={false}
                                        showFixedNumberOfWeeks={true}
                                        prevLabel={null}
                                        nextLabel={null}
                                        minDate={currentDate}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>

                    <div className="side-panel">
                        {getSidebarContent()}
                    </div>
                </div>
            </div>
        </ThemeWrapper>
    );
};

export default CalendarView;
