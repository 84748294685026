import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import params from '../config/Params';
import { getItemFromLocalStorage, setWithExpiry } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';

const Login = () => {
  const [countryList, setCountryList] = useState([]);
  const [countryCode, setCountryCode] = useState('91');
  const [mobileNumber, setMobileNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationInProgress, setVerificationInProgress] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resendDisabled, setResendDisabled] = useState(true);
  const [countdown, setCountdown] = useState(120);
  const [showTimer, setShowTimer] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const localHostId = getItemFromLocalStorage('host_id');

    if (localHostId) {
      navigate('/home');
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    let timer;
    if (otpSent && showTimer) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown > 0) {
            return prevCountdown - 1;
          } else {
            clearInterval(timer);
            setShowTimer(false);
            setResendDisabled(false);
            return 0;
          }
        });
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [otpSent, showTimer]);

  useEffect(() => {
    const fetchCounryList = async () => { 
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.countryList}`,
        });
  
        if (response.data.status === 'ok') {
          setCountryList(response.data.country_list);
        } else {
          console.log('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchCounryList();
  }, []); 

  const handleSendOtp = async (e) => {
    e.preventDefault();
    try {
      setVerificationInProgress(true);
      let item = { generated_for: 'stays', generated_for_mobile_cc: countryCode, generated_for_mobile: mobileNumber };
      const response = await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: `${params.baseURL}${params.endpoints.createOtp}`,
        data: item
      });
      if (response.data.status === 'ok') {
        setSuccessMessage('OTP sent successfully!');
        setOtpSent(true);
        setResendDisabled(true);
        setShowTimer(true);
        setCountdown(120);
      } else if (response.data.status === 'error') {
        setErrorMessage('Please enter valid mobile number');
      } else {
        console.log(response);
        setErrorMessage('Failed to send OTP. Please try again.');
      }
    } catch (error) {
      console.error('Error sending OTP:', error);
      setErrorMessage('Failed to send OTP. Please try again later.');
    } finally {
      setVerificationInProgress(false);
    }
  };

  const handleResendOtp = async (e) => {
    e.preventDefault();
    setSuccessMessage('');
    setErrorMessage('');
    await handleSendOtp(e);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      let otpItem = { generated_otp: otp, generated_for: 'stays', generated_for_mobile_cc: countryCode, generated_for_mobile: mobileNumber };
      const response = await axios({
        method: 'post',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        url: `${params.baseURL}${params.endpoints.verifyOtp}`,
        data: otpItem
      });
      if (response.data.status === 'ok') {
        const fullMobileNumber = countryCode + mobileNumber;
        let userItem = { source: 'OTP', source_id: fullMobileNumber };
        const userResponse = await axios({
          method: 'post',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          url: `${params.baseURL}${params.endpoints.isUserExist}`,
          data: userItem
        });

        if (userResponse.data.status === 'exists') {
          setSuccessMessage('Login successful!');
          setWithExpiry('hostProfile', JSON.stringify(userResponse.data.profile_data));
          setWithExpiry('host_id', userResponse.data.registered_profile_id);
          navigate('/home');
        } else {
          navigate('/register', { state: { mobileNumber } });
        }
      } else {
        setErrorMessage('Login failed. Please check your OTP and try again.');
      }
    } catch (error) {
      console.error('Login error:', error);
      setErrorMessage('Login failed. Please try again later.');
    }
  };

  const handleChange = (e) => {
    setCountryCode(e.target.value);
  };

  return ( 
  <>
    <Helmet>
      <title>Wire Stay Hotel Login | Manage Your Listings & Bookings</title>
      <meta name="description" content="Log in to Wire Stay Hotel to enlist your property, manage bookings, and grow your business effortlessly. Simplify property management with a user-friendly dashboard designed for your success." />
    </Helmet>
    <section className='login-page'>
        <div className='oauth-app'>
            <div className='art-wrapper'>
                <img className="oauth-banner" src={'/assets/img/hotel_login_banner_1.png'} alt='wire stay banner' />
            </div>
            <div className="content-wrapper">
                <div className='w-100'>
                    <img className="img-fluid login-logo" src={'assets/img/wirestay_hotel_login.svg'} alt='logo' />
                    <div className=''>
                    <div>
                        {!otpSent && (
                        <>
                            <form id="mobileForm" autoComplete="off">
                            <div className="login-form">
                                <label className='login-title mb-2'>SIGN UP / LOGIN WITH YOUR MOBILE NUMBER</label>
                                <div className="input-box d-flex" style={{ gap:'5px' }}>
                                  <select
                                    className="form-control country-code"
                                    value={countryCode}
                                    onChange={handleChange}
                                  >
                                    {countryList.map((code, index) => (
                                      <option key={index} value={code.country_code}>
                                        + {code.country_code}
                                      </option>
                                    ))}
                                  </select>
                                  <input value={mobileNumber} onChange={e => setMobileNumber(e.target.value)} disabled={verificationInProgress} className="form-control" type="number" name="number" placeholder="Enter Your Number" />
                                </div>

                                <div className="button">
                                <button className="btn btn-primary btn-block login-btn" onClick={handleSendOtp} disabled={verificationInProgress}>
                                    {verificationInProgress ? 'Sending OTP...' : 'Continue'}
                                </button>
                                </div>

                                <div className="text-left">
                                <span className="d-block terms-block">BY CLICKING ON CONTINUE YOU AGREE TO OUR&nbsp;
                                    <a href={'https://wirestay.com/legal/hosting-agreement'} target='_blank' rel="noreferrer">TERMS</a>&nbsp; & &nbsp; 
                                    <a href={'https://wirestay.com/legal/privacy'} target='_blank' rel="noreferrer">PRIVACY POLICY</a>
                                </span>
                                </div>
                            </div>
                            </form>
                        </>
                        )}

                        {successMessage && <div className='message-box' style={{ background: 'green', marginBottom: '1rem' }}>{successMessage}</div>}
                        {errorMessage && <div className='message-box' style={{ background: 'red', marginTop: '1rem' }}>{errorMessage}</div>}

                        {otpSent && (
                        <>
                            <form id="otpForm" autoComplete="off">
                            <div className="login-form">
                                <label htmlFor="mobile" style={{ textAlign: 'center' }}>PLEASE ENTER VERIFICATION CODE</label>
                                <input value={otp} onChange={(e) => setOtp(e.target.value)} disabled={verificationInProgress} className="form-control" type="text" />
                                <div className="text-center mb-4">
                                {showTimer ? (             
                                    <div className="countdown resend-text">Expire in {countdown > 0 ? `${countdown} seconds` : ''}</div>
                                ) : (
                                    <div className="button">
                                    <span className="d-block resend-text">DIDN'T RECEIVE THE CODE ?</span>
                                    <button id='resendOtp' className="btn btn-primary btn-block login-btn" onClick={handleResendOtp} disabled={resendDisabled}>
                                        RESEND
                                    </button>
                                    </div>
                                )}
                                </div>
                                <div className="button">
                                <button className="btn btn-primary btn-block login-btn" onClick={handleLogin} disabled={verificationInProgress}>
                                    {verificationInProgress ? 'Verifying...' : 'VERIFY'}
                                </button>
                                </div>
                            </div>
                            </form>
                        </>
                        )}
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  </>
  );
};

export default Login;
