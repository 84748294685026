import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import { toast } from 'react-toastify';
import Chat from './Chat';

function Messages() {
    const [chatRequests, setChatRequests] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedChat, setSelectedChat] = useState(null);

    useEffect(() => {
        const fetchMyRequests = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('sender_type', 2);
            setLoading(true);
            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data',
                    },
                    url: `${params.baseURL}${params.endpoints.chatMyRequests}`,
                    data: formData,
                });

                if (response.data.items && Array.isArray(response.data.items)) {
                    setChatRequests(response.data.items);
                    setLoading(false);
                } else {
                    toast.error('An error occurred while fetching data.');
                }
            } catch (err) {
                console.log(err);
            } finally {
                setLoading(false);
            }
        };

        fetchMyRequests();
    }, []);

    const openChat = (chatData) => {
        setSelectedChat(chatData);
    };

    const closeChat = () => {
        setSelectedChat(null);
    };

    return (
        <div>
            <Helmet>
                <title>Messages</title>
                <meta name="description" content="" />
            </Helmet>

            <div className="row">
                {/* Chat List Section */}
                <div className="col-md-4 message-list-part">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        <div className="messages-list-section">
                            {chatRequests.length > 0 ? (
                                chatRequests.map((list) => (
                                    <div className="message-list-box" key={list.request_id} onClick={() => openChat(list)}>
                                        <div className="mrib">
                                            <img
                                                className="mru-pic mru-guest"
                                                src={list.request_from_pic !== null ? list.request_from_pic : '/assets/img/ws_user_icon.png'}
                                                alt=""
                                            />
                                            <img
                                                className="mru-pic mru-host"
                                                src={list.cover_photo !== null ? list.cover_photo : '/assets/img/icons/property_default_icon_2.png'}
                                                alt=""
                                            />
                                        </div>
                                        <span className='mitls'></span>
                                        <div className="mrncb">
                                            <div>
                                                <p>{list.listing_title !== null ? list.listing_title : 'Sky Hotel'} - {list.request_from_name}</p>
                                                <div className='last-message-box'>
                                                    <img 
                                                        src={
                                                            list.latestMessage?.receiver_type === 1
                                                                ? '/assets/img/icons/ws_chat_received.png'
                                                                : '/assets/img/icons/ws_chat_send.png'
                                                        } 
                                                        alt='' className='img-fluid lm-icon' 
                                                    />
                                                    <span>{list.latestMessage?.message_text || "No messages yet"}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="empty-section ems">
                                    <p>Your inbox is on a coffee break – no messages from guests just yet!</p>
                                </div>
                            )}
                        </div>
                    )}
                </div>

                {/* Chat Content Section */}
                <div className="col-md-8">
                    {selectedChat ? (
                        <Chat chatData={selectedChat} onClose={closeChat} />
                    ) : (
                        <div className="empty-chat-section ems">
                            <p>Select a message to start a chat.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Messages;
