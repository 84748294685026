import './App.css';
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import BlankLayout from './layouts/BlankLayout';
import MainLayout from './layouts/MainLayout';
import ProtectedRoute from './ProtectedRoute';
import Default from './pages/Default';
import Register from './pages/Register';
import Login from './pages/Login';
import Home from './pages/Home';
import Logout from './pages/Logout';
import MyProfile from './pages/MyProfile';
import MyListings from './pages/MyListings';
import MyCalendar from './pages/MyCalendar';
import Plans from './pages/Plans';
import MySubscription from './pages/MySubscription';
import CreateListing from './pages/CreateListing';
import CalendarView from './pages/CalendarView';
import ProfileInformation from './profile/ProfileInformation';
import Overview from './modules/create-listing/Overview';
import Messages from './pages/Messages';
import Chat from './pages/Chat';
import Notifications from './pages/Notifications';
import BookingDetails from './pages/BookingDetails';
import Index from './modules/hotel/Index';
import Onboarding from './modules/hotel/Onboarding';
import Category from './modules/hotel/Category';
import RoomsPrice from './pages/RoomsPrice';
import EditListing from './modules/hotel/EditListing';
import ScrollToTop from './ScrollToTop ';
import GuestDetails from './pages/GuestDetails';
import Spotlight from './profile/Spotlight';
import NewOffer from './modules/promotions/NewOffer';
import Active from './modules/promotions/Active';
import EditOffer from './modules/promotions/EditOffer';
import GuestProfile from './pages/GuestProfile';
import Checkin from './pages/Checkin';
import Checkout from './pages/Checkout';
import Earnings from './pages/Earnings';
import AddVideo from './modules/videos/AddVideo';

function App() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      const smallScreen = window.innerWidth < 1200;
      setIsSmallScreen(smallScreen);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  if (isSmallScreen) {
    return (
      <div className="small-screen-message">
        <a href="/">
          {/*<img src={'/assets/img/fs_mobile_view.png'} alt="fyre search" className="img-fluid w-100" />*/}
          <p>Not available</p>
        </a>
      </div>
    );
  }

  return (
    <Router>
      <ScrollToTop />
       <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <BlankLayout>
                <Default />
              </BlankLayout>
            </ProtectedRoute>
          }
         />
         <Route 
          path="/login" 
          element={
            <BlankLayout>
              <Login />
            </BlankLayout>
          } 
         />
         <Route 
          path="/register" 
          element={
            <BlankLayout>
              <Register />
            </BlankLayout>
          } 
         />
         <Route
          path="/home"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Home />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/booking-details/:bookingId/:categoryId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <BookingDetails />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/guest-details/:bookingId/:categoryId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <GuestDetails />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/my-profile"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MyProfile />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/profile/profile-information"
          element={
            <ProtectedRoute>
              <MainLayout>
                <ProfileInformation />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/profile/spotlight"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Spotlight />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/my-calendar"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MyCalendar />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/rooms-price/:listingId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <RoomsPrice />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/calendar-view/:listingId/:roomId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <CalendarView />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/create-listing/overview"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Overview />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/create-listing/:listingId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <CreateListing />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/my-listings"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MyListings />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/plans"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Plans />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/my-subscription"
          element={
            <ProtectedRoute>
              <MainLayout>
                <MySubscription />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/messages"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Messages />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/chat"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Chat />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/guest-profile/:userId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <GuestProfile />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Notifications />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/logout"
          element={
            <ProtectedRoute>
              <Logout />
            </ProtectedRoute>
          }
         />
         <Route
          path="/hotel/index"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Index />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/hotel/category/:listingId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Category />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/hotel/onboarding/:listingId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Onboarding />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/hotel/edit-listing/:listingId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditListing />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/hotel/promotions/active"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Active />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/hotel/promotions/new-offer"
          element={
            <ProtectedRoute>
              <MainLayout>
                <NewOffer />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/hotel/promotions/edit-offer/:offerId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <EditOffer />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/hotel/videos/add-video"
          element={
            <ProtectedRoute>
              <MainLayout>
                <AddVideo />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/checkin/:bookingId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Checkin />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/checkout/:bookingId"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Checkout />
              </MainLayout>
            </ProtectedRoute>
          }
         />
         <Route
          path="/earnings/"
          element={
            <ProtectedRoute>
              <MainLayout>
                <Earnings />
              </MainLayout>
            </ProtectedRoute>
          }
         />
       </Routes>
   </Router>
  );
}

export default App;
