import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../../../config/Params';
import { getItemFromLocalStorage } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ScrollToTop from '../../../ScrollToTop ';
import { MenuItem, FormControl, InputLabel, Select, Checkbox, ListItemText } from '@mui/material';

const ItemType = 'IMAGE';

const ImageItem = ({ image, index, moveImage, removeImage, isCover, setAsCover, onTagClick }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index },
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveImage(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
  });

  return (
    <div ref={(node) => ref(drop(node))}>
      <div className='image-preview'>
        {isCover ? (
          <span className='cover-photo-label'>Cover Photo</span>
        ) : (
          <button className='set-cover-btn' onClick={() => setAsCover(index)}>
            Set as Cover
          </button>
        )}
        <div className='tooltip-wrapper'>
          <img onClick={() => onTagClick(image.tagname)} src={image.pic || image.preview} alt={`preview-${index}`} className='preview-img' />
          {image.all_tag_name?.length > 0 && (
            <div className="img-tooltip">
              {image.all_tag_name.map((tag, idx) => (
                <span key={idx} className="tooltip-tag">{tag}</span>
              ))}
              <i className="fas fa-caret-down tooltip-arrow"></i>
            </div>
          )}
        </div>
        <a href='javascript:void(0);' className='tag-name-link' onClick={() => onTagClick(image.tagname)}>
          {image.all_tag_name && image.all_tag_name.length > 0 ? (
            <>
              {image.all_tag_name[0]}
              {image.all_tag_name.length > 1 && (
                <> +{image.all_tag_name.length - 1} more</>
              )}
            </>
          ) : (
            <span>No Tags</span>
          )}
        </a>
        <button type='button' className='remove-image-btn' onClick={() => removeImage(index)}>
          <i className="fas fa-minus"></i>
        </button>
      </div>
    </div>
  );
};

const Photos = ({ nextStep, prevStep, formData }) => {
  const { listingId } = formData;
  const [images, setImages] = useState([]);
  const [photoTags, setPhotoTags] = useState([]);
  const [coverPhotoIndex, setCoverPhotoIndex] = useState(0);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [imageTags, setImageTags] = useState({});
  const [selectedTag, setSelectedTag] = useState(null);
  const [filteredImages, setFilteredImages] = useState([]);
  const [isTaggedPopupOpen, setIsTaggedPopupOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedImageTags, setSelectedImageTags] = useState({}); 
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });

        if (response.data.status === 'ok') {
          const listingData = response.data.updated_property_item;
          const { photos = [], cover_photo } = listingData;

          const photoData = photos.map((photo) => ({
            id: photo.stay_listing_photo_id,
            pic: photo.pic,
            tagId: photo.photo_tag,
            tagname: photo.tag_name,
            all_tag_name: photo.all_tag_name,
            isCover: photo.is_cover === 1,
          }));

          setImages(photoData);

          const initialImageTags = {};
          photoData.forEach((photo) => {
            if (photo.tagId) {
              initialImageTags[photo.id] = photo.tagId.split(',').map((id) => parseInt(id, 10));
            }
          });
          setSelectedImageTags(initialImageTags);
          //console.log(initialImageTags);

          const coverIndex = photoData.findIndex((photo) => photo.isCover);
          if (coverIndex !== -1) setCoverPhotoIndex(coverIndex);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
        toast.error('An error occurred while fetching data.');
      }
    };

    const fetchPhototags = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getPhotoTags}`,
          data: formData,
        });
  
        if (response.data.status === 'tags') {
          setPhotoTags(response.data.photo_tag_list);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        toast.error(err.message);
      }
    };

    fetchListingData();
    fetchPhototags();
  }, [listingId]);

  const handleTagClick = (tagname) => {
    const filteredImages = images.filter((image) => image.tagname === tagname);
    setSelectedTag(tagname);
    setFilteredImages(filteredImages); 
    setIsTaggedPopupOpen(true); 
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const newImages = files.map((file, index) => ({
      file,
      preview: URL.createObjectURL(file),
      id: `img${images.length + index + 1}`,
      isNewlyUploaded: true,
    }));

    setImages((prevImages) => {
      const updatedImages = [...prevImages, ...newImages];
      if (prevImages.length === 0 && newImages.length > 0) {
        setCoverPhotoIndex(0);
      }
      return updatedImages;
    });

    setIsPopupOpen(true);
  };

  const handleCloseUploadImagePopup = () => {
    const existingImages = images.filter(image => !image.isNewlyUploaded);
    setImages(existingImages);
    setIsPopupOpen(false);
  }

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  const handleTagChange = (imageId) => (event) => {
    const selectedValues = event.target.value;
  
    setImages((prevImages) =>
      prevImages.map((image) =>
        image.id === imageId
          ? {
              ...image,
              selectedTags: selectedValues,
              all_tag_name: photoTags
                .filter((tag) => selectedValues.includes(tag.photo_tag_id))
                .map((tag) => tag.tag_name),
            }
          : image
      )
    );
  
    setSelectedImageTags((prev) => ({
      ...prev,
      [imageId]: selectedValues,
    }));
  };  

  const handleTaggedChange = (imageId, newTagId) => {
    setFilteredImages((prevImages) =>
      prevImages.map((image) =>
        image.id === imageId ? { ...image, tagId: newTagId } : image
      )
    );
  
    setImages((prevImages) =>
      prevImages.map((image) =>
        image.id === imageId ? { ...image, tagId: newTagId } : image
      )
    );
  
    setImageTags((prevTags) => ({
      ...prevTags,
      [imageId]: newTagId,
    }));
  };  

  const moveImage = (fromIndex, toIndex) => {
    const updatedImages = [...images];
    const [movedImage] = updatedImages.splice(fromIndex, 1);
    updatedImages.splice(toIndex, 0, movedImage);
    setImages(updatedImages);

    if (coverPhotoIndex === fromIndex) {
      setCoverPhotoIndex(toIndex);
    } else if (coverPhotoIndex === toIndex) {
      setCoverPhotoIndex(fromIndex);
    }
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);

    if (index === coverPhotoIndex) {
      setCoverPhotoIndex(0);
    } else if (index < coverPhotoIndex) {
      setCoverPhotoIndex((prev) => prev - 1);
    }
  };

  const setAsCover = (index) => {
    setCoverPhotoIndex(index);
  };

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
  
    const formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('listing_id', listing_id);
  
    const existingImageIds = [];
  
    images.forEach((image) => {
      if (image.file) {
        formData.append(image.id, image.file);
        const tags = selectedImageTags[image.id] || [];
        formData.append(`${image.id}_tag`, tags.join(','));
      } else {
        existingImageIds.push(image.id);
      }
    });
  
    if (existingImageIds.length > 0) {
      formData.append('ids', existingImageIds.join(','));
    }
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.updateImages}`,
        data: formData,
      });
  
      if (response.data.status === 'completed') {
        await saveAsCover(coverPhotoIndex);
        nextStep();
      } else if (response.data.status === 'error') {
        response.data.list.forEach((errorMsg) => {
          toast.error(errorMsg);
        });
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };  

  const saveAsCover = async (index) => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const imageId = images[index].id;

    const formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('id', imageId);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.makeItCover}`,
        data: formData,
      });

      if (response.data.status === 'completed') {
        console.log('Cover photo updated successfully');
      } else {
        console.log('Failed to update cover photo');
      }
    } catch (error) {
      console.log('An error occurred while updating the cover photo');
    }
  };

  const updateImageTags = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
  
    const formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('listing_id', listing_id);
  
    Object.entries(selectedImageTags).forEach(([imageId, tagIds]) => {
      formData.append(`${imageId}_tag`, tagIds.join(','));
    });
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.updateImageTags}`,
        data: formData,
      });
  
      if (response.data.status === 'completed') {
        const listingData = response.data.updated_property_item;
        const { photos = [], cover_photo } = listingData;

        const photoData = photos.map((photo) => ({
          id: photo.stay_listing_photo_id,
          pic: photo.pic,
          tagId: photo.photo_tag,
          tagname: photo.tag_name,
          all_tag_name: photo.all_tag_name,
          isCover: photo.is_cover === 1,
        }));

        setImages(photoData);

        const initialImageTags = {};
        photoData.forEach((photo) => {
          if (photo.tagId) {
            initialImageTags[photo.id] = photo.tagId.split(',').map((id) => parseInt(id, 10));
          }
        });
        setSelectedImageTags(initialImageTags);

        const coverIndex = photoData.findIndex((photo) => photo.isCover);
        if (coverIndex !== -1) setCoverPhotoIndex(coverIndex);
        toast.success('Image tags updated successfully!');
      } else if (response.data.status === 'error') {
        response.data.list.forEach((errorMsg) => {
          toast.error(errorMsg);
        });
      } else {
        toast.error('Failed to update image tags');
      }
    } catch (err) {
      toast.error('An error occurred while updating image tags');
    }
  };  

  const handleNext = () => {
    saveListing();
  };

  const handleTaggedPhoto = () => {
    updateImageTags();
    setIsTaggedPopupOpen(false);
  }

  const faqs = [
    {
      question: "What kind of media can I upload?",
      answer: "High-quality images and videos of your property, rooms, and amenities are required."
    },
    {
      question: "Are there any specific guidelines for images?",
      answer: "Images should be in landscape mode, at least 1024x768 resolution, and free of watermarks."
    },
    {
      question: "Can I add videos of my property?",
      answer: "Yes, videos should be in MP4 format and under 100 MB."
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <ScrollToTop />
      <div className='hotel-register-section'>
        <div className='row'>
          <div className='col-md-9'>
            <div className="clp-header">
              <h4 className='ws-title itwac'>
                <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                <span>Upload photos of your place</span>
              </h4>
              <p className="ws-desc itwac">
                <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                <span>Start with 5 photos; you can add more or make changes later.</span>
              </p>
            </div>

            <div className='st1-desc-box'>
              {images.length > 0 && (
                <div className='cover-photo-box'>
                  <h6 className='cp-label'>Cover Photo</h6>
                  <img
                    src={images[coverPhotoIndex].preview || images[coverPhotoIndex].pic}
                    alt="cover-photo"
                    className="cover-photo-preview"
                  />
                </div>
              )}

              <div className='image-upload-container'>
                <div className='image-preview-border'>
                  <div className='image-preview-container'>
                    {images.map((image, index) => (
                      <ImageItem
                        key={index}
                        index={index}
                        image={image}
                        moveImage={moveImage}
                        removeImage={removeImage}
                        isCover={index === coverPhotoIndex}
                        setAsCover={setAsCover}
                        onTagClick={handleTagClick}
                      />
                    ))}
                  </div>
                </div>

                <label htmlFor="file" className="custum-file-upload">
                  <div className="icon">
                    <div className="text">
                      <span><i className="fas fa-plus"></i></span>
                    </div>
                    <input id="file" type='file' accept='image/*' multiple onChange={handleImageChange} />
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="faq-sticky">
              <div className='fas-desc-box'>
                <div className='faq-title-box'>
                  <h4 className='faq-title-txt'>What to do here ?</h4>
                </div>
                <p className='faq-desc'>A picture’s worth a thousand bookings! Upload stunning visuals here.</p>
              </div>
              <hr></hr>
              <div className='faq-txt-box'>
                <img src='/assets/img/faq_banner_2.gif' className='img-fluid' alt='' />
              </div>
              {faqs.map((faq, index) => (
                <div key={index} className='faqs-list-section'>
                  <div
                    onClick={() => toggleAccordion(index)} className='faqs-qustn-box'
                    style={{
                      backgroundColor: activeIndex === index ? "#f7f7f7" : "#ffffff",
                      borderBottom: activeIndex === index ? "1px solid #ccc" : "none",
                    }}
                  >
                    {faq.question}
                    <i
                      className={`fas ${
                        activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                      }`}
                      style={{
                        transition: "transform 0.3s ease",
                      }}
                    ></i>
                  </div>
                  {activeIndex === index && (
                    <div className='faqs-ans-box'>
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep}>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Next</button>
        </div>

        {isPopupOpen && (
          <div className="photos-popup">
            <div className="photos-popup-content">
              <p>Add tags to the below items</p>
              <div className="popup-images">
                {images
                  .filter((image) => image.file)
                  .map((image) => (
                    <div key={image.id} className="popup-image-item">
                      <img src={image.preview || image.pic} alt={image.id} className="popup-preview-img" />
                      <div className='w-100'>
                        <FormControl fullWidth>
                          <InputLabel>Add Tags</InputLabel>
                          <Select
                            label="Add Tags"
                            multiple
                            value={selectedImageTags[image.id] || []}
                            onChange={handleTagChange(image.id)}
                            renderValue={(selected) => {
                              const selectedTags = photoTags.filter((tag) => selected.includes(tag.photo_tag_id));
                              const displayedTags = selectedTags.slice(0, 2);
                              const remainingTagsCount = selectedTags.length - 2;
                        
                              return (
                                <>
                                  {displayedTags.map((tag) => tag.tag_name).join(', ')}
                                  {remainingTagsCount > 0 && ` +${remainingTagsCount} more`}
                                </>
                              );
                            }}
                          >
                            {photoTags.map((tag) => (
                              <MenuItem key={tag.photo_tag_id} value={tag.photo_tag_id}>
                                <Checkbox checked={selectedImageTags[image.id]?.includes(tag.photo_tag_id) || false} />
                                <ListItemText primary={tag.tag_name} />
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </div>
                  ))}
              </div>
              <div className='hl-popup-footer'>
                <button onClick={handleCloseUploadImagePopup} className="btn btn-black hlbtn">Cancel</button>
                <button onClick={() => setIsPopupOpen(false)} className="btn btn-black hlbtn">Done</button>
              </div>
            </div>
          </div>
        )}

        {isTaggedPopupOpen && (
          <div className="photos-popup">
            <div className="photos-popup-content">
              <p>Images with tag: {selectedTag}</p>
              <div className="popup-images">
                {filteredImages.map((image) => (
                  <div key={image.id} className="popup-image-item">
                    <img src={image.preview || image.pic} alt={image.id} className="popup-preview-img" />
                    <div className='w-100'>
                      <FormControl fullWidth>
                        <InputLabel>Tags Added</InputLabel>
                        <Select
                          label="Tags Added"
                          multiple
                          className='tags-dropdown'
                          value={selectedImageTags[image.id] || []}
                          onChange={handleTagChange(image.id)}
                          renderValue={(selected) => {
                            const selectedTags = photoTags.filter((tag) => selected.includes(tag.photo_tag_id));
                            const displayedTags = selectedTags.slice(0, 2);
                            const remainingTagsCount = selectedTags.length - 2;
                      
                            return (
                              <>
                                {displayedTags.map((tag) => tag.tag_name).join(', ')}
                                {remainingTagsCount > 0 && ` +${remainingTagsCount} more`}
                              </>
                            );
                          }}
                        >
                          {photoTags.map((tag) => (
                            <MenuItem key={tag.photo_tag_id} value={tag.photo_tag_id}>
                              <Checkbox checked={selectedImageTags[image.id]?.includes(tag.photo_tag_id) || false} />
                              <ListItemText primary={tag.tag_name} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                ))}
              </div>

              <div className='hl-popup-footer'>
                <button onClick={() => setIsTaggedPopupOpen(false)} className="btn btn-black hlbtn">Cancel</button>
                <button onClick={() => handleTaggedPhoto()} className="btn btn-black hlbtn">Update</button>
              </div>
            </div>
          </div>
        )}

      </div>
    </DndProvider>
  );
};

export default Photos;
