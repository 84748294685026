import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../../../config/Params';
import { getItemFromLocalStorage } from '../../../utils/storageUtils';
import { toast } from 'react-toastify';
import ScrollToTop from '../../../ScrollToTop ';

const BasicInfo = ({ formData, nextStep, prevStep }) => {
    const { listingId } = formData;
    const [hotelStarList, setHotelStarList] = useState([]);
    const [propertyBuiltYearList, setPropertyBuiltYearList] = useState([]);
    const [acceptingYearList, setAcceptingYearList] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [starRating, setStarRating] = useState('');
    const [builtYear, setBuiltYear] = useState('');
    const [bookingSince, setBookingSince] = useState('');
    const [emailId, setEmailId] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');
    const [landlineNumber, setLandlineNumber] = useState('');
    const [errors, setErrors] = useState({});
    const [activeIndex, setActiveIndex] = useState(null);
    const [dropdownOpenRating, setDropdownOpenRating] = useState(false);
    const [dropdownBuiltYear, setDropdownBuiltYear] = useState(false);
    const [dropdownBookingSince, setDropdownBookingSince] = useState(false);

      useEffect(() => {      
        const fetchListingData = async () => {
          const hostData = getItemFromLocalStorage('hostProfile');
          let formData = new FormData();
          formData.append('token', hostData.host_access_token);
          formData.append('listing_id', listingId);
      
          try {
            const response = await axios({
              method: 'post',
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              url: `${params.baseURL}${params.endpoints.getListingData}`,
              data: formData,
            });
      
            if (response.data.status === 'ok') {
              setPropertyName(response.data.updated_property_item.listing_title);
              setStarRating(response.data.updated_property_item.hotel_star_rating);
              setBuiltYear(response.data.updated_property_item.property_built_year);
              setBookingSince(response.data.updated_property_item.accepting_booking_since);
              setEmailId(response.data.updated_property_item.hotel_email_id);
              setMobileNumber(response.data.updated_property_item.hotel_contact_number);
              setLandlineNumber(response.data.updated_property_item.hotel_landline_number);
            } else {
              toast.error('An error occurred while fetching data.');
            }
          } catch (err) {
            console.log(err.message);
          }
        };

        const fetchHotelData = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
            formData.append('listing_id', listingId);
        
            try {
              const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                url: `${params.baseURL}${params.endpoints.hotelDataList}`,
                data: formData,
              });
        
              if (response.data.status === 'ok') {
                const dataList = response.data.list;
                const hotelStarRatingOptions = dataList.find(item => item.hotel_star_rating)?.hotel_star_rating || [];
                const propertyBuiltYearOptions = dataList.find(item => item.property_built_year)?.property_built_year || [];
                const acceptingBookingYearOptions = dataList.find(item => item.accepting_booking_year)?.accepting_booking_year || [];
                setHotelStarList(hotelStarRatingOptions);
                setPropertyBuiltYearList(propertyBuiltYearOptions);
                setAcceptingYearList(acceptingBookingYearOptions);
              } else {
                toast.error('An error occurred while fetching data.');
              }
            } catch (err) {
              console.log(err.message);
            }
        };
      
        fetchListingData();
        fetchHotelData();
      }, []);

      const validateFields = () => {
        const validationErrors = {};
        if (!propertyName || propertyName.trim() === '') validationErrors.propertyName = 'Please fill this input field';
        if (!starRating) validationErrors.starRating = 'Please select a hotel star rating';
        if (!builtYear) validationErrors.builtYear = 'Please select a year';
        if (!bookingSince) validationErrors.bookingSince = 'Please select a year';
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailId || emailId.trim() === '') {
            validationErrors.emailId = 'Please enter an email ID';
        } else if (!emailRegex.test(emailId)) {
            validationErrors.emailId = 'Please enter a valid email ID';
        }
        const mobileNumberRegex = /^[0-9]{10}$/;
        if (!mobileNumber || mobileNumber.trim() === '') {
            validationErrors.mobileNumber = 'Please enter a mobile number';
        } else if (!mobileNumberRegex.test(mobileNumber)) {
            validationErrors.mobileNumber = 'Please enter a valid 10-digit mobile number';
        }
    
        setErrors(validationErrors);
        console.log(validationErrors);
        return Object.keys(validationErrors).length === 0;
      };

      const handleEmailChange = (e) => {
        const value = e.target.value;
        setEmailId(value);
    
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (value.trim() !== '' && !emailRegex.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            emailId: 'Please enter a valid email ID',
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.emailId;
            return updatedErrors;
          });
        }
      };
      
      const handleMobileChange = (e) => {
        const value = e.target.value;
        setMobileNumber(value);
    
        const mobileNumberRegex = /^[0-9]{10}$/;
        if (value.trim() !== '' && !mobileNumberRegex.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            mobileNumber: 'Please enter a valid 10-digit mobile number',
          }));
        } else {
          setErrors((prevErrors) => {
            const updatedErrors = { ...prevErrors };
            delete updatedErrors.mobileNumber;
            return updatedErrors;
          });
        }
      };      

      const saveListing = async () => {
        const hostData = getItemFromLocalStorage('hostProfile');
        const listing_id = listingId;
        const ts = Date.now();
      
        const updatedPropertyItem = {
            listing_title: propertyName,
            hotel_star_rating: starRating,
            property_built_year: builtYear,
            accepting_booking_since: bookingSince,
            hotel_email_id: emailId,
            hotel_contact_number: mobileNumber,
            hotel_landline_number: landlineNumber
        };
      
        let catData = new FormData();
        catData.append('token', hostData.host_access_token);
        catData.append('listing_id', listing_id);
        catData.append('data', JSON.stringify(updatedPropertyItem)); 
        catData.append('ts', ts); 
      
        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
            url: `${params.baseURL}${params.endpoints.updateListing}`,
            data: catData,
          });
      
          if (response.data.status === 'done') {
            nextStep();
          } else {
            toast.error('Failed to save data');
          }
        } catch (err) {
          toast.error('An error occurred while saving the listing');
        }
      };
      
      const handleNext = () => {
        if (validateFields()) {
          saveListing();
        }
      };

      const faqs = [
        {
          question: "What basic information do I need to provide?",
          answer: "You’ll need to provide your name, property name, contact details, property history, etc."
        },
        {
          question: "Can I edit the information later?",
          answer: "Yes, you can edit basic details from the dashboard after completing the registration."
        },
        {
          question: "Is it mandatory to specify the star category of my property?",
          answer: "Yes, providing the star category helps categorize your property for better visibility."
        },
      ];
    
      const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
      };

    return (
      <div>
        <ScrollToTop />
        <div className="hotel-register-section">
          <div className='row'>
            <div className='col-md-9'>
              <div className="clp-header">
                <h4 className='ws-title'>Basic Information</h4>
              </div>

              <div className="hotel-form-box">
                  <div className="hfbfsp">
                      <div className="row hlibs">
                          <label htmlFor="propertyName" className="col-sm-6 hrltb">
                              <div className='form-icon'>
                                  <img src='/assets/img/icons/legal_name_icon.svg' className='img-fluid' alt='' />
                              </div>
                              <div>
                                  <p>Name of the Property</p>
                                  <p className="label-hint">Enter the name as on the property documents</p>
                              </div>
                          </label>
                          <div className="col-sm-6">
                              <input
                                  type="text"
                                  className="form-control hrifb"
                                  id="propertyName"
                                  value={propertyName}
                                  onChange={(e) => {
                                    setPropertyName(e.target.value);
                                    if (errors.propertyName && e.target.value.trim() !== '') {
                                      const updatedErrors = { ...errors };
                                      delete updatedErrors.propertyName;
                                      setErrors(updatedErrors);
                                    }
                                  }}
                              />
                              {errors.propertyName && <div className="error-text">{errors.propertyName}</div>}
                          </div>
                      </div>
                      <div className="row hlibs">
                          <label htmlFor="hotelRating" className="col-sm-6 hrltb">
                              <div className='form-icon'>
                                  <img src='/assets/img/icons/gst_icon.svg' className='img-fluid' alt='' />
                              </div>
                              <div>
                                  <p>Hotel Star Rating</p>
                              </div>
                          </label>
                          <div className="col-sm-6">
                            <div className="custom-select">
                              <div
                                className="selected-option hrifb"
                                onClick={() => setDropdownOpenRating(!dropdownOpenRating)}
                              >
                                <img
                                  src={'https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg'}
                                  alt=""
                                  className="option-icon"
                                />
                                {hotelStarList.find((item) => item.id === starRating)?.title || "Select"}
                              </div>
                              {dropdownOpenRating && (
                                <ul className="options-list">
                                  {hotelStarList.map((list) => (
                                    <li
                                      key={list.id}
                                      className="option-item"
                                      onClick={() => {
                                        setStarRating(list.id);
                                        setDropdownOpenRating(false);
                                        if (errors.starRating && list.id) {
                                          const updatedErrors = { ...errors };
                                          delete updatedErrors.starRating;
                                          setErrors(updatedErrors);
                                        }
                                      }}
                                    >
                                      <img src={'https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg'} alt="" className="option-icon" />
                                      {list.title}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                            {errors.starRating && <div className="error-text">{errors.starRating}</div>}
                          </div>
                      </div>
                      <div className="row hlibs">
                          <label for="propertyBuilt" className="col-sm-6 hrltb">
                              <div className='form-icon'>
                                  <img src='/assets/img/icons/gst_icon.svg' className='img-fluid' alt='' />
                              </div>
                              <div>
                                  <p>When was the property built?</p>
                              </div>
                          </label>
                          <div className="col-sm-6">
                            <div className="custom-select">
                              <div
                                className="selected-option hrifb"
                                onClick={() => setDropdownBuiltYear(!dropdownBuiltYear)}
                              >
                                <img
                                  src={'https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg'}
                                  alt=""
                                  className="option-icon"
                                />
                                {propertyBuiltYearList.find((item) => item.id === Number(builtYear))?.title || "select a year"}
                              </div>
                              {dropdownBuiltYear && (
                                <ul className="options-list">
                                  {propertyBuiltYearList.map((list) => (
                                    <li
                                      key={list.id}
                                      className="option-item"
                                      onClick={() => {
                                        setBuiltYear(list.id);
                                        setDropdownBuiltYear(false);
                                        if (errors.builtYear && list.id) {
                                          const updatedErrors = { ...errors };
                                          delete updatedErrors.builtYear;
                                          setErrors(updatedErrors);
                                        }
                                      }}
                                    >
                                      <img src={'https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg'} alt="" className="option-icon" />
                                      {list.title}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </div>
                            {errors.builtYear && <div className="error-text">{errors.builtYear}</div>}
                          </div>
                      </div>
                      <div className="row hlibs">
                          <label for="propertyBuilt" className="col-sm-6 hrltb">
                              <div className='form-icon'>
                                  <img src='/assets/img/icons/gst_icon.svg' className='img-fluid' alt='' />
                              </div>
                              <div>
                                  <p>Accepting booking since?</p>
                                  <p className="label-hint">Since when is this property available for guests to book</p>
                              </div>
                          </label>
                          <div className="col-sm-6">
                            <div className="custom-select">
                            <div
                              className={`selected-option hrifb ${!builtYear ? "disabled" : ""}`}
                              onClick={() => {
                                if (builtYear) setDropdownBookingSince(!dropdownBookingSince);
                              }}
                            >
                              <img
                                src="https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg"
                                alt=""
                                className="option-icon"
                              />
                              {acceptingYearList.find((item) => item.id === Number(bookingSince))?.title || "Select a year"}
                            </div>
                            {dropdownBookingSince && builtYear && (
                              <ul className="options-list">
                                {acceptingYearList
                                  .filter((list) => list.id >= builtYear)
                                  .map((list) => (
                                    <li
                                      key={list.id}
                                      className="option-item"
                                      onClick={() => {
                                        setBookingSince(list.id);
                                        setDropdownBookingSince(false);
                                        if (errors.bookingSince && list.id) {
                                          const updatedErrors = { ...errors };
                                          delete updatedErrors.bookingSince;
                                          setErrors(updatedErrors);
                                        }
                                      }}
                                    >
                                      <img
                                        src="https://hotel.wirestay.com/assets/img/icons/pointer_icon.svg"
                                        alt=""
                                        className="option-icon"
                                      />
                                      {list.title}
                                    </li>
                                  ))}
                              </ul>
                            )}
                            </div>
                            {errors.bookingSince && <div className="error-text">{errors.bookingSince}</div>}
                          </div>
                      </div>
                  </div>
                  <div className="hfb_line"></div>
                  <div className="hfbfsp">
                      <div className="hbf-title-box">
                          <p className="hbf-title">How can guests contact the property?</p>
                          <p className="label-hint">These contact details will be shared with the guests when they make a booking</p>
                      </div>
                      <div className="row hlibs">
                          <label for="email" className="col-sm-6 hrltb">
                              <div className='form-icon'>
                                  <img src='/assets/img/icons/mail_icon.svg' className='img-fluid' alt='' />
                              </div>
                              <div>
                                  <p>Email ID</p>
                              </div>
                          </label>
                          <div className="col-sm-6">
                              <input type="text" class="form-control hrifb" id="email" value={emailId} 
                                onChange={handleEmailChange} 
                              />
                              {errors.emailId && <div className="error-text">{errors.emailId}</div>}
                          </div>
                      </div>
                      <div className="row hlibs">
                          <label for="number" className="col-sm-6 hrltb">
                              <div className='form-icon'>
                                  <img src='/assets/img/icons/whatsapp_icon_r.svg' className='img-fluid' alt='' />
                              </div>
                              <div>
                                  <p>Mobile Number</p>
                              </div>
                          </label>
                          <div className="col-sm-6">
                              <input type="text" class="form-control hrifb" id="number" value={mobileNumber} 
                                onChange={handleMobileChange}
                              />
                              {errors.mobileNumber && <div className="error-text">{errors.mobileNumber}</div>}
                          </div>
                      </div>
                      <div className="row hlibs">
                          <label for="landline" className="col-sm-6 hrltb">
                              <div className='form-icon'>
                                  <img src='/assets/img/icons/whatsapp_icon_r.svg' className='img-fluid' alt='' />
                              </div>
                              <div>
                                  <p>Landline Number (optional)</p>
                              </div>
                          </label>
                          <div className="col-sm-6">
                              <input type="text" class="form-control hrifb" id="landline" value={landlineNumber} onChange={(e) => setLandlineNumber(e.target.value)} />
                          </div>
                      </div>
                  </div>
              </div>
            </div>
            <div className='col-md-3'>
              <div className="faq-sticky">
                <div className='fas-desc-box'>
                  <div className='faq-title-box'>
                    <h4 className='faq-title-txt'>What to do here ?</h4>
                  </div>
                  <p className='faq-desc'>Tell us about yourself and your fabulous property!</p>
                </div>
                <hr></hr>
                <div className='faq-txt-box'>
                  {/*<h4 className='faq-title-txt'>Frequently Asked Questions</h4>*/}
                  <img src='/assets/img/faq_banner_2.gif' className='img-fluid' alt='' />
                </div>
                {faqs.map((faq, index) => (
                  <div key={index} className='faqs-list-section'>
                    <div
                      onClick={() => toggleAccordion(index)} className='faqs-qustn-box'
                      style={{
                        backgroundColor: activeIndex === index ? "#f7f7f7" : "#ffffff",
                        borderBottom: activeIndex === index ? "1px solid #ccc" : "none",
                      }}
                    >
                      {faq.question}
                      <i
                        className={`fas ${
                          activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                        style={{
                          transition: "transform 0.3s ease",
                        }}
                      ></i>
                    </div>
                    {activeIndex === index && (
                      <div className='faqs-ans-box'>
                        {faq.answer}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className='clp-footer'>
          <button className='btn btn-blank' onClick={prevStep} disabled>Back</button>
          <button className='btn btn-black' onClick={handleNext}>Next</button>
        </div>

    </div>
  );
};

export default BasicInfo;
