import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import Drawer from '@mui/material/Drawer';

function Spotlight() {
    const [workplace, setWorkplace] = useState('');
    const [favSong, setFavSong] = useState('');
    const [liveLocation, setLiveLocation] = useState('');
    const [schoolName, setSchoolName] = useState('');
    const [obsessedWith, setObsessedWith] = useState('');
    const [bornDecade, setBornDecade] = useState('');
    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [languageList, setLanguageList] = useState([]);
    const [languagesName, setLanguagesName] = useState('');
    const [visibleSection, setVisibleSection] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        fetchHotelData();
        fetchProfileData();
    }, []); 

    const fetchHotelData = async () => {
        const hostData = getItemFromLocalStorage('hostProfile');
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);
        formData.append('listing_id', 1);
    
        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.hotelDataList}`,
            data: formData,
          });
    
          if (response.data.status === 'ok') {
            const dataList = response.data.list;
            const languagesOptions = dataList.find(item => item.languages)?.languages || [];
            setLanguageList(languagesOptions);
          } else {
            toast.error('An error occurred while fetching data.');
          }
        } catch (err) {
          console.log(err.message);
        }
    };

    const fetchProfileData = async () => { 
        const hostData = getItemFromLocalStorage('hostProfile');
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);

        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.getSpotlight}`,
            data: formData
          });
    
          if (response.data.status === 'found') {
            const profile_data = response.data.profile_data;
            setWorkplace(profile_data.working_place || '');
            setFavSong(profile_data.favourite_song || '');
            setLiveLocation(profile_data.living_location || '');
            setSchoolName(profile_data.school_name || '');
            setObsessedWith(profile_data.obsessed_with || '');
            setBornDecade(profile_data.born_decade || '');
            setSelectedLanguages(profile_data.languages || '');
            setLanguagesName(profile_data.languages_name || '');
          } else {
            console.log('An error occurred while fetching data.');
          }
        } catch (err) {
          console.log(err.message);
        }
    };

    const validateForm = () => {
        const errors = {};
        if (!workplace) errors.workplace = true;
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'workplace':
                setWorkplace(value);
                break;
            case 'favSong':
                setFavSong(value);
                break;
            case 'liveLocation':
                setLiveLocation(value);
                break;
            case 'schoolName':
                setSchoolName(value);
                break;
            case 'obsessedWith':
                setObsessedWith(value);
                break;
            case 'bornDecade':
                setBornDecade(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        const hostData = getItemFromLocalStorage('hostProfile');
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);
        switch (visibleSection) {
            case 'workplace':
                formData.append('working_place', workplace);
                break;
            case 'favoriteSong':
                formData.append('favourite_song', favSong);
                break;
            case 'locationForm':
                formData.append('living_location', liveLocation);
                break;
            case 'schoolForm':
                formData.append('school_name', schoolName);
                break;
            case 'obsessedForm':
                formData.append('obsessed_with', obsessedWith);
                break;
            case 'dobForm':
                formData.append('born_decade', bornDecade);
                break;
            case 'languageForm':
                formData.append('languages', selectedLanguages.join(','));
                break;
            default:
                break;
        }
        
        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.updateSpotlight}`,
            data: formData
          });
    
          if (response.data.status === 'ok') {
            const profile_data = response.data.profile_data;
            setLanguagesName(profile_data.languages_name || '');
            toast.success('Spotlight data updated successfully.');
          } else {
            console.log('An error occurred while fetching data.');
          }
        } catch (err) {
          console.log(err.message);
        }
        closeDrawer();
    };

    const openDrawer = (section) => {
        setVisibleSection(section);
    };

    const closeDrawer = () => {
        setVisibleSection(null);
    };

    const handleLanguageChange = (e) => {
        const { value, checked } = e.target;
        setSelectedLanguages((prev) =>
            checked ? [...prev, value] : prev.filter((lang) => lang !== value)
        );
    };    

    const getDrawerContent = () => {
        switch (visibleSection) {
            case 'workplace':
                return { title: 'What do you do for work?', value: workplace, name: 'workplace', label: 'My Work' };
            case 'favoriteSong':
                return { title: 'Your favorite song in high school?', value: favSong, name: 'favSong', label: 'Favorite Song' };
            case 'locationForm':
                return { title: 'Where do you live?', value: liveLocation, name: 'liveLocation', label: 'Live Location' };
            case 'schoolForm':
                return { title: 'Where did you go to school?', value: schoolName, name: 'schoolName', label: 'School Name' };
            case 'obsessedForm':
                return { title: "What are you obsessed with?", value: obsessedWith, name: 'obsessedWith', label: 'Obsessed With' };
            case 'dobForm':
                return { title: 'What decade were you born in?', value: bornDecade, name: 'bornDecade', label: 'Born Decade' };
            case 'languageForm':
                return { title: 'Select your preferred language(s)', value: selectedLanguages, name: 'language', label: 'Languages' };
            default:
                return {};
        }
    };

    const drawerContent = getDrawerContent();

    return (
        <div>
            <Helmet>
                <title>Spotlight Information</title>
                <meta name='description' content='' />
            </Helmet>
            <div className="personal-section">
                <div className="row">
                    <div className='col-md-6 split-section' style={{ backgroundImage: "url('/assets/img/listing/step21.png')" }}>

                    </div>
                    <div className='col-md-6 offset-md-6 split-second-part'>
                        <div className="profile-data-box">
                            {[
                                { section: 'workplace', icon: 'workplace_icon', text: `My work: ${workplace}` },
                                { section: 'favoriteSong', icon: 'music_icon', text: `Favourite song: ${favSong}` },
                                { section: 'locationForm', icon: 'live_location_icon', text: `Lives in: ${liveLocation}` },
                                { section: 'schoolForm', icon: 'school_icon', text: `School: ${schoolName}` },
                                { section: 'obsessedForm', icon: 'obsessed_icon', text: `Obsessed with: ${obsessedWith}` },
                                { section: 'dobForm', icon: 'birthday_icon', text: `Born in: ${bornDecade}` },
                                { section: 'languageForm', icon: 'language_icon', text: `Languages: ${languagesName}` },
                            ].map(({ section, icon, text }) => (
                                <>
                                <div key={section} className="pdtb trigger-button" onClick={() => openDrawer(section)}>
                                    <div className="pdtbip">
                                        <img src={`/assets/img/icons/${icon}.svg`} alt="" className="pd-icon" />
                                        <p>{text}</p>
                                    </div>
                                    <div>
                                        <img src={'/assets/img/icons/profile-edit.svg'} className="img-fluid pd-icon" />
                                    </div>
                                </div>
                                <span className="line"></span>
                                </>
                            ))}
                        </div>
                    </div>
                </div>
            </div>

            <Drawer anchor="bottom" className="drawer" open={!!visibleSection} onClose={closeDrawer}>
                {visibleSection && (
                    <div className="drawer-content post-popup">
                        <div className="post-popup-box" style={{ padding: '50px 10px', width: '500px', maxWidth: '500px' }}>
                            <div>
                                <span className="drawer-close-btn" onClick={closeDrawer}>
                                    <img src={'/assets/img/icons/close_icon.svg'} className="w-100" alt="" />
                                </span>
                            </div>
                            <div className="popup-title" style={{ marginBottom: '1rem', fontWeight: '600' }}>
                                {drawerContent.title}
                            </div>
                            <div className="popup-options">
                                {visibleSection === 'languageForm' ? (
                                <div>
                                    <div class="form-group" style={{height:'200px',overflow:'auto'}}>
                                        {languageList.map((lang) => (
                                            <div class="form-check" key={lang.language_id}>
                                                <input 
                                                    class="form-check-input" 
                                                    type="checkbox" 
                                                    name="selected_languages[]" 
                                                    value={lang.language_id}
                                                    onChange={handleLanguageChange}
                                                    checked={selectedLanguages.includes(lang.language_id.toString())}
                                                />
                                                <label class="form-check-label">
                                                    {lang.language_name}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            id={drawerContent.name}
                                            name={drawerContent.name}
                                            className="form-control"
                                            value={drawerContent.value}
                                            onChange={handleChange}
                                            required
                                        />
                                        <label htmlFor={drawerContent.name}>{drawerContent.label}</label>
                                    </div>
                                </div>
                            )}
                                <div className="coupon-drawer-footer">
                                    <button className="btn btn-success" onClick={handleSubmit}>
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Drawer>

        </div>
    )
}

export default Spotlight