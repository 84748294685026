import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';

function ProfileInformation() {
    const [preferredFirstName, setPreferredFirstName] = useState('');
    const [hostPhoneNumber, setHostPhoneNumber] = useState('');
    const [hostEmail, setHostEmail] = useState('');
    const [hostAddressStreet, setHostAddressStreet] = useState('');
    const [hostApartment, setHostApartment] = useState('');
    const [hostCountry, setHostCountry] = useState('');
    const [hostState, setHostState] = useState('');
    const [hostCity, setHostCity] = useState('');
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [visibleSection, setVisibleSection] = useState(null);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        fetchProfileData();
        fetchCounryList();
    }, []); 

    const fetchProfileData = async () => { 
        const hostData = getItemFromLocalStorage('hostProfile');
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);

        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.getExtraProfile}`,
            data: formData
          });
    
          if (response.data.status === 'found') {
            const profile_data = response.data.profile_data;
            setPreferredFirstName(profile_data.preferred_first_name || '');
            setHostPhoneNumber(profile_data.host_phone_number_multiple || '');
            setHostEmail(profile_data.host_email || '');
            setHostAddressStreet(profile_data.host_address_street || '');
            setHostApartment(profile_data.host_apartment || '');
            setHostCountry(profile_data.host_country || '');
            setHostState(profile_data.host_state || '');
            setHostCity(profile_data.host_city || '');
          } else {
            console.log('An error occurred while fetching data.');
          }
        } catch (err) {
          console.log(err.message);
        }
    };
    
    const fetchCounryList = async () => { 
        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.countryList}`,
          });
    
          if (response.data.status === 'ok') {
            setCountryList(response.data.country_list);
          } else {
            console.log('An error occurred while fetching data.');
          }
        } catch (err) {
          console.log(err.message);
        }
    };

    const fetchStateList = async (countryId) => { 
        let formData = new FormData();
        formData.append('country_id', countryId);

        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.stateList}`,
            data: formData
          });
    
          if (response.data.status === 'ok') {
            setStateList(response.data.state_list);
            setCityList([]);
            setHostState('');
            setHostCity('');
          } else {
            console.log('An error occurred while fetching data.');
          }
        } catch (err) {
          console.log(err.message);
        }
    };

    const fetchCityList = async (stateId) => { 
        let formData = new FormData();
        formData.append('country_id', hostCountry);
        formData.append('state_id', stateId);
        
        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.cityList}`,
            data: formData
          });
    
          if (response.data.status === 'ok') {
            setCityList(response.data.city_list);
          } else {
            console.log('An error occurred while fetching data.');
          }
        } catch (err) {
          console.log(err.message);
        }
    };

    const handleCountryChange = (e) => {
        const countryId = e.target.value;
        setHostCountry(countryId);
        fetchStateList(countryId);
    };

    const handleStateChange = (e) => {
        const stateId = e.target.value;
        setHostState(stateId);
        fetchCityList(stateId);
    };

    const validateForm = () => {
        const errors = {};
        if (!preferredFirstName) errors.preferredFirstName = true;
        return errors;
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        
        switch (name) {
          case 'preferredFirstName':
            setPreferredFirstName(value);
            break;
          case 'hostPhoneNumber':
            setHostPhoneNumber(value);
            if (!value) setFormErrors((prev) => ({ ...prev, hostPhoneNumber: true }));
            else setFormErrors((prev) => ({ ...prev, hostPhoneNumber: false }));
            break;
          case 'hostEmail':
            setHostEmail(value);
            if (!value) setFormErrors((prev) => ({ ...prev, hostEmail: true }));
            else setFormErrors((prev) => ({ ...prev, hostEmail: false }));
            break;
          case 'addressStreet':
            setHostAddressStreet(value);
            if (!value) setFormErrors((prev) => ({ ...prev, addressStreet: true }));
            else setFormErrors((prev) => ({ ...prev, addressStreet: false }));
            break;
          default:
            break;
        }
    };

    const toggleSection = (section) => {
        setVisibleSection(visibleSection === section ? null : section);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const errors = validateForm();
        setFormErrors(errors);

        const hostData = getItemFromLocalStorage('hostProfile');
        let formData = new FormData();
        formData.append('token', hostData.host_access_token);
        formData.append('preferred_first_name', preferredFirstName);
        formData.append('host_email', hostEmail);
        formData.append('host_address_street', hostAddressStreet);
        formData.append('host_apartment', hostApartment);
        formData.append('host_country', hostCountry);
        formData.append('host_state', hostState);
        formData.append('host_city', hostCity);
        formData.append('host_phone_number_multiple', hostPhoneNumber);
        
        try {
          const response = await axios({
            method: 'post',
            headers: {
              'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.updateExtraProfile}`,
            data: formData
          });
    
          if (response.data.status === 'ok') {
            toast.success('Profile saved successfully');
          } else {
            console.log('An error occurred while fetching data.');
          }
        } catch (err) {
          console.log(err.message);
        }
    };
    return (
        <div>
            <Helmet>
                <title>Profile Information</title>
                <meta name='description' content='' />
            </Helmet>
            <div className="personal-section">
                <div className="row">
                    <div className='col-md-6 split-section' style={{ backgroundImage: "url('/assets/img/listing/step21.png')" }}>

                    </div>
                    <div className='col-md-6 offset-md-6 split-second-part'>
                        <form id="personal-information-form" onSubmit={handleSubmit}>
                            {/*<div className="banner-msg">
                                <img className="pi-banner img-fluid" src={`${params.profileUrl}/guest/banners/profile_information_banner.png`} alt="" />
                            </div>*/}

                            <div className="txt-msg btxt hcode-msg mb-5">
                                <p className="pid">Wire Stay collects your personal information to verify your identity and ensure a smooth and fast approval process for onboarding to the Wire Stay host platform.</p>
                            </div>

                            <div className="section">
                                <div className="pidbox" id="legalSection">
                                    <div className="pidtxt">
                                        <img class="pid-img" src={`${params.profileUrl}/guest/icons/legal_name_icon_1.svg`} alt="" />
                                        <p>Spotlight</p>
                                    </div>
                                    <a href="/profile/spotlight" className="edit-btn">
                                        <img src={`${params.profileUrl}/guest/icons/edit_circle_icon.svg`} alt="" />
                                    </a>
                                </div>
                                <span class="line"></span>
                            </div>

                            <div className="section">
                                <div className="pidbox" id="preferred">
                                    <div className="pidtxt">
                                        <img class="pid-img" src={`${params.profileUrl}/guest/icons/legal_name_icon_1.svg`} alt="" />
                                        <p>Host Preferred Name</p>
                                    </div>
                                    <a href="javascript:void(0);" className="edit-btn" onClick={() => toggleSection('preferred')}>
                                        <img src={`${params.profileUrl}/guest/icons/edit_circle_icon.svg`} alt="" />
                                    </a>
                                </div>
                                {visibleSection === 'preferred' && (
                                    <div className='form-fields mt-4'>
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input className={`form-control ${formErrors.preferredFirstName ? 'is-invalid' : ''}`} type="text" id="preferredFirstName" name="preferredFirstName" value={preferredFirstName} onChange={handleChange} onKeyUp={handleChange} />
                                                <label htmlFor="preferredFirstName">Host Preferred Name</label>
                                            </div>
                                        </div>
                                        <div className="txt-msg hcode-msg">
                                            <p className="pid">The first name or business name that you'd like to go by on Wirestay.</p>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="wt-btn">Save</button>					
                                        </div>
                                    </div>
                                )}
                                <span class="line"></span>
                            </div>

                            <div className="section">
                                <div className="pidbox" id="contact">
                                    <div className="pidtxt">
                                        <img class="pid-img" src={`${params.profileUrl}/guest/icons/contact_icon.svg`} alt="" />
                                        <p>Phone Number</p>
                                    </div>
                                    <a href="javascript:void(0);" className="edit-btn" onClick={() => toggleSection('contact')}>
                                        <img src={`${params.profileUrl}/guest/icons/edit_circle_icon.svg`} alt="" />
                                    </a>
                                </div>
                                {visibleSection === 'contact' && (
                                    <div className='form-fields mt-4'>
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input className={`form-control ${formErrors.hostPhoneNumber ? 'is-invalid' : ''}`} type="text" id="hostPhoneNumber" name="hostPhoneNumber" value={hostPhoneNumber} onChange={handleChange} onKeyUp={handleChange} />
                                                <label htmlFor="hostPhoneNumber">Number</label>
                                            </div>
                                        </div>
                                        <div className="txt-msg hcode-msg">
                                            <p className="pid">Contact number (for Wire Stay to get in touch).</p>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="wt-btn">Save</button>					
                                        </div>
                                    </div>
                                )}
                                <span class="line"></span>
                            </div> 

                            <div className="section">
                                <div className="pidbox" id="email">
                                    <div className="pidtxt">
                                        <img class="pid-img" src={`${params.profileUrl}/guest/icons/mail_icon.svg`} alt="" />
                                        <p>Email</p>
                                    </div>
                                    <a href="javascript:void(0);" className="edit-btn" onClick={() => toggleSection('email')}>
                                        <img src={`${params.profileUrl}/guest/icons/edit_circle_icon.svg`} alt="" />
                                    </a>
                                </div>
                                {visibleSection === 'email' && (
                                    <div className='form-fields mt-4'>
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input className={`form-control ${formErrors.hostEmail ? 'is-invalid' : ''}`} type="text" id="hostEmail" name="hostEmail" value={hostEmail} onChange={handleChange} onKeyUp={handleChange} />
                                                <label htmlFor="hostEmail">Email</label>
                                            </div>
                                        </div>
                                        <div className="txt-msg hcode-msg">
                                            <p className="pid">Use an address you'll always have access to.</p>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="wt-btn">Save</button>					
                                        </div>
                                    </div>
                                )}
                                <span class="line"></span>
                            </div> 

                            <div className="section">
                                <div className="pidbox" id="address">
                                    <div className="pidtxt">
                                        <img class="pid-img" src={`${params.profileUrl}/guest/icons/address_icon.svg`} alt="" />
                                        <p>Address</p>
                                    </div>
                                    <a href="javascript:void(0);" className="edit-btn" onClick={() => toggleSection('address')}>
                                        <img src={`${params.profileUrl}/guest/icons/edit_circle_icon.svg`} alt="" />
                                    </a>
                                </div>
                                {visibleSection === 'address' && (
                                    <div className='form-fields mt-4'>
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input className={`form-control ${formErrors.hostAddressStreet ? 'is-invalid' : ''}`} type="text" id="hostAddressStreet" name="hostAddressStreet" value={hostAddressStreet} onChange={handleChange} onKeyUp={handleChange} />
                                                <label htmlFor="hostAddressStreet">Street</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <input className={`form-control ${formErrors.hostApartment ? 'is-invalid' : ''}`} type="text" id="hostApartment" name="hostApartment" value={hostApartment} onChange={handleChange} onKeyUp={handleChange} />
                                                <label htmlFor="hostApartment">Apt Suite (optional)</label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <select
                                                    className="form-control country-code"
                                                    id='hostCountry'
                                                    name='hostCountry'
                                                    value={hostCountry}
                                                    onChange={handleCountryChange}
                                                >
                                                    <option value="">Select Country</option>
                                                    {countryList.map((code, index) => (
                                                    <option key={index} value={code.country_id}>
                                                        {code.country_name}
                                                    </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="hostCountry">Country</label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <select
                                                    id="hostState"
                                                    name="hostState"
                                                    value={hostState}
                                                    onChange={handleStateChange}
                                                    className="form-control"
                                                    disabled={!hostCountry}
                                                >
                                                    <option value="">Select State</option>
                                                    {stateList.map((state) => (
                                                        <option key={state.state_id} value={state.state_id}>
                                                            {state.state_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="hostState">State</label>
                                            </div>
                                        </div>

                                        <div className="form-group">
                                            <div className="form-floating mb-3">
                                                <select
                                                    id="hostCity"
                                                    name="hostCity"
                                                    value={hostCity}
                                                    onChange={(e) => setHostCity(e.target.value)}
                                                    className="form-control"
                                                    disabled={!hostState}
                                                >
                                                    <option value="">Select City</option>
                                                    {cityList.map((city) => (
                                                        <option key={city.city_id} value={city.city_id}>
                                                            {city.city_name}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor="hostCity">City</label>
                                            </div>
                                        </div>

                                        <div className="txt-msg hcode-msg">
                                            <p className="pid">Kindly provide your communication address for future correspondence.</p>
                                        </div>
                                        <div className="form-group">
                                            <button type="submit" className="wt-btn">Save</button>					
                                        </div>
                                    </div>
                                )}
                                <span class="line"></span>
                            </div>   

                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileInformation