import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../../config/Params';
import { getItemFromLocalStorage } from '../../utils/storageUtils';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../ScrollToTop ';
import { useParams, useNavigate } from "react-router-dom";

const EditOffer = () => {
    const { offerId } = useParams()
    const [activeIndex, setActiveIndex] = useState(null);
    const [offerCategoryList, setOfferCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [offerTitle, setOfferTitle] = useState('');
    const [discountType, setDiscountType] = useState('');
    const [allUserDiscount, setAllUserDiscount] = useState('');
    const [maxLengthStay, setMaxLengthStay] = useState('');
    const [maxLengthStayValue, setMaxLengthStayValue] = useState('');
    const [minLengthStay, setMinLengthStay] = useState('');
    const [minLengthStayValue, setMinLengthStayValue] = useState('');
    const [earlyBirdRule, setEarlyBirdRule] = useState('');
    const [lastMinuteDeal, setLastMinuteDeal] = useState('');
    const [selectedDays, setSelectedDays] = useState('');
    const [blackoutOffer, setBlackoutOffer] = useState('');
    const [isRefundable, setIsRefundable] = useState('');
    const [isPayAtHotel, setIsPayAtHotel] = useState('');
    const [isApplicableAllRooms, setIsApplicableAllRooms] = useState('');
    const [daysList, setDaysList] = useState([]);
    const [daysOptions, setDaysOptions] = useState([]);
    const [isFormVisible, setIsFormVisible] = useState(false);
    const [selectedRatePlan, setSelectedRatePlan] = useState('');
    const [selectedRooms, setSelectedRooms] = useState({
        familyRoom: false,
        doubleRoom: false,
        tripleRoom: false,
    });
    const [roomSubOptions, setRoomSubOptions] = useState({
        familyRoom: { EP: false, CP: false },
        doubleRoom: { EP: false, CP: false },
        tripleRoom: { EP: false, CP: false },
    });
    const navigate = useNavigate();
  
    useEffect(() => {

        const fetchOfferDataList = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
    
            try {
            const response = await axios({
                method: 'post',
                headers: {
                'Content-Type': 'multipart/form-data',
                },
                url: `${params.baseURL}${params.endpoints.offerDataList}`,
                data: formData,
            });
    
            if (response.data.status === 'ok') {
                const dataList = response.data.list;
                const offerCategoryOptions = dataList.find(item => item.offer_category)?.offer_category || [];
                const daysOptionList = dataList.find(item => item.days_list)?.days_list || [];
                setOfferCategoryList(offerCategoryOptions);
                setDaysOptions(daysOptionList);
            } else {
                toast.error('An error occurred while fetching data.');
            }
            } catch (err) {
            console.error(err.message);
            }
        };
      
        const fetchOfferData = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);
        
            try {
              const response = await axios({
                method: 'post',
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
                url: `${params.baseURL}${params.endpoints.getOffers}`,
                data: formData,
              });
        
              if (response.data.status === 'ok') {
                const selectedOffer = response.data.offers.find(
                    (offer) => offer.stay_listing_offer_id === parseInt(offerId, 10)
                );

                //console.log(selectedOffer)

                if (selectedOffer) {
                    setOfferTitle(selectedOffer.offer_name);
                    setSelectedCategory(selectedOffer.offer_category);
                    setDiscountType(selectedOffer.offer_type?.toString());
                    setAllUserDiscount(selectedOffer.offer_type_value || '');
                    setMaxLengthStay(selectedOffer.is_max_length_stay?.toString() || '');
                    setMaxLengthStayValue(selectedOffer.max_length_stay_value?.toString() || '');
                    setMinLengthStay(selectedOffer.is_min_length_stay?.toString() || '');
                    setMinLengthStayValue(selectedOffer.min_length_stay_value?.toString() || '');
                    setEarlyBirdRule(selectedOffer.is_early_bird_rule?.toString() || '');
                    setLastMinuteDeal(selectedOffer.is_last_minute_deal?.toString() || '');
                    setSelectedDays(selectedOffer.is_selected_days?.toString() || '');
                    setDaysList(selectedOffer.selected_days_value ? selectedOffer.selected_days_value.split(',').map(day => parseInt(day, 10)) : []);
                    setBlackoutOffer(selectedOffer.is_blackout_offer?.toString() || '');
                    setIsRefundable(selectedOffer.is_non_refundable?.toString() || '');
                    setIsPayAtHotel(selectedOffer.is_pay_at_hotel?.toString() || '');
                    setIsApplicableAllRooms(selectedOffer.is_applicale_all_rooms?.toString() || '');
                    setSelectedRatePlan(selectedOffer.offer_apply_on_rooms?.toString() || '');

                    const roomList = selectedOffer.offer_apply_b2c_rooms?.split(',') || [];
                    setSelectedRooms({
                        familyRoom: roomList.includes('familyRoom'),
                        doubleRoom: roomList.includes('doubleRoom'),
                        tripleRoom: roomList.includes('tripleRoom'),
                    });
                    const subRoomList = selectedOffer.offer_apply_b2c_subrooms?.split(',') || [];
                    setRoomSubOptions({
                        familyRoom: {
                        EP: subRoomList.includes('familyRoom-EP'),
                        CP: subRoomList.includes('familyRoom-CP'),
                        },
                        doubleRoom: {
                        EP: subRoomList.includes('doubleRoom-EP'),
                        CP: subRoomList.includes('doubleRoom-CP'),
                        },
                        tripleRoom: {
                        EP: subRoomList.includes('tripleRoom-EP'),
                        CP: subRoomList.includes('tripleRoom-CP'),
                        },
                    });
                    //console.log(selectedOffer);
                } else {
                    toast.error('Offer not found.');
                }
              } else {
                toast.error('An error occurred while fetching data.');
              }
            } catch (err) {
              console.log(err.message);
            }
        };
  
        fetchOfferDataList();
        fetchOfferData();
    }, []);

    const daysOfWeek = [
        { id: 1, title: "MON" },
        { id: 2, title: "TUE" },
        { id: 3, title: "WED" },
        { id: 4, title: "THU" },
        { id: 5, title: "FRI" },
        { id: 6, title: "SAT" },
        { id: 7, title: "SUN" },
    ];

    const handleDaySelection = (dayId) => {
        if (daysList.includes(dayId)) {
          setDaysList(daysList.filter((id) => id !== dayId));
        } else {
          setDaysList([...daysList, dayId]);
        }
    };

    const handleRoomSelection = (room) => {
        setSelectedRooms((prev) => {
          const updatedRooms = { ...prev, [room]: !prev[room] };
          if (!prev[room]) {
            setRoomSubOptions((prevSubOptions) => ({
              ...prevSubOptions,
              [room]: { EP: true, CP: true },
            }));
          } else {
            setRoomSubOptions((prevSubOptions) => ({
              ...prevSubOptions,
              [room]: { EP: false, CP: false },
            }));
          }
          return updatedRooms;
        });
    };
    
    const handleSubOptionChange = (room, subOption) => {
        setRoomSubOptions((prev) => ({
          ...prev,
          [room]: { ...prev[room], [subOption]: !prev[room][subOption] },
        }));
    };
  
    const handleFormSubmit = async e => {
      e.preventDefault();
      const hostData = getItemFromLocalStorage('hostProfile');
      const status = 1;
      const selectedDaysCSV = daysList.join(",");
      const selectedRoomsCSV = Object.keys(selectedRooms)
        .filter((room) => selectedRooms[room])
        .join(",");

      const selectedSubRoomsCSV = Object.keys(roomSubOptions)
        .map((room) => {
          const subOptions = Object.keys(roomSubOptions[room])
            .filter((subOption) => roomSubOptions[room][subOption])
            .map((subOption) => `${room}-${subOption}`);
          return subOptions.join(",");
        })
        .filter((entry) => entry !== "")
        .join(",");

      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('offer_category', selectedCategory);
      formData.append('offer_name', offerTitle);
      formData.append('offer_type', discountType);
      formData.append('offer_type_value', allUserDiscount);
      formData.append('is_max_length_stay', maxLengthStay);
      formData.append('max_length_stay_value', maxLengthStayValue);
      formData.append('is_min_length_stay', minLengthStay);
      formData.append('min_length_stay_value', minLengthStayValue);
      formData.append('is_early_bird_rule', earlyBirdRule);
      formData.append('is_last_minute_deal', lastMinuteDeal);
      formData.append('is_selected_days', selectedDays);
      formData.append('selected_days_value', selectedDaysCSV);
      formData.append('is_blackout_offer', blackoutOffer);
      formData.append('is_non_refundable', isRefundable);
      formData.append('is_pay_at_hotel', isPayAtHotel);
      formData.append('is_applicale_all_rooms', isApplicableAllRooms);
      formData.append('offer_apply_on_rooms', selectedRatePlan);
      formData.append('offer_apply_b2c_rooms', selectedRoomsCSV);
      formData.append('offer_apply_b2c_subrooms', selectedSubRoomsCSV);
      formData.append('offer_status', status);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.saveOffer}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          toast.success('Offer saved successfully!');
          navigate('/hotel/promotions/active');
        } else {
          toast.error('Failed to save the offer.');
        }
      } catch (err) {
        console.error(err.message);
        toast.error('An error occurred while saving the offer.');
      }
    };

    const faqs = [
        {
            question: "How do I access the Edit Offer page?",
            answer: "Navigate to the Promotions section in your dashboard and click on the ‘modify’ button of the specific offer you want to edit."
        },
        {
            question: "What details can I edit in an existing promotion?",
            answer: "You can update the promotion name, discount type, length of stay, booking window, and applicable days or rate plans."
        },
        {
            question: "Can I apply this promotion to selected days only?",
            answer: "Yes, use the 'Day-Based Availability' feature to make the offer valid on specific days like weekends or weekdays."
        },
        {
            question: "How do I set blackout dates for this promotion?",
            answer: "Use the 'Advance Settings' section to specify any dates where the promotion won’t apply, like peak holiday seasons."
        },
        {
            question: "Can I make the offer non-refundable or allow payment at the hotel?",
            answer: "Yes, toggle these options under the 'Advance Settings' to match your policies."
        },
    ];
    
    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };
  
    return (
        <div>
          <Helmet>
            <title>New Promoion</title>
            <meta name="description" content="" />
          </Helmet>
          <ScrollToTop />

          <div className="hotel-register-section offer-section">
    
            <div className='row'>
                <div className='col-md-9'>
                    <div className='clp-header'>
                        <h1 className='ws-title'>Edit Offer</h1>
                        <p className='ws-desc'>Tweaking time! Update the details of your promotion to ensure it aligns perfectly with your property goals and guest expectations!</p>
                    </div>
                    <div className="modify-offer-form" style={{ marginTop: '20px' }}>
                        <div>
                        <div className="form-box">
                            <label for="roomName" class="form-label">Promotion Name</label>
                            <input
                                type="text"
                                className="form-control"
                                value={offerTitle}
                                onChange={(e) => setOfferTitle(e.target.value)}
                                required
                            />
                        </div>
                        <div className="form-box">
                            <label class="form-label">Discount Type</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="discountType" id="percentage" type="radio" label="Percentage" checked={discountType === "1"} onChange={(e) => setDiscountType(e.target.value)} />
                                    <input value="2" name="discountType" id="fixed" type="radio" label="Fixed" checked={discountType === "2"} onChange={(e) => setDiscountType(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="form-box">
                            <label for="roomName" class="form-label">Discount for all users</label>
                            <input
                                type="text"
                                className="form-control"
                                value={allUserDiscount}
                                onChange={(e) => setAllUserDiscount(e.target.value)}
                                required
                            />
                        </div>
                        
                        <div>
                            <p className='rdb-title'>Length of stay</p>
                        </div>
                        <div className="form-box">
                            <label class="form-label">Do you want to set max. length of stay?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="maxLengthStay" id="yesMax" type="radio" label="Yes" checked={maxLengthStay === "1"} onChange={(e) => setMaxLengthStay(e.target.value)} />
                                    <input value="2" name="maxLengthStay" id="noMax" type="radio" label="No" checked={maxLengthStay === "2"} onChange={(e) => setMaxLengthStay(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        {maxLengthStay === "1" && (
                            <div className="days-list">
                                <div className='days-list-box'>
                                {daysOptions.map((list) => (
                                    <>
                                    <input
                                    className='day-btn'
                                    id={`maxStay${list.id}`}
                                    type="radio"
                                    name='maxStayValue'
                                    value={list.id}
                                    checked={list.id.toString() === maxLengthStayValue}
                                    onChange={(e) => setMaxLengthStayValue(e.target.value)}
                                    />
                                    <label key={list.id} htmlFor={`maxStay${list.id}`} className='day-label' style={{ cursor: 'pointer', border:'none', borderRadius:'2px', boxShadow: ' rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' }}>
                                        {list.short_title}
                                        <span style={{color:'#ccc'}}>|</span>
                                        <img
                                            src={
                                                list.id.toString() === maxLengthStayValue
                                                ? "/assets/img/icons/sunny_day_white.png"
                                                : "/assets/img/icons/sunny_day.png"
                                            }
                                            className="img-fluid"
                                            alt={list.title}
                                        />
                                    </label>
                                    </>
                                ))}
                                </div>
                            </div>
                        )}
                        <div className="form-box">
                            <label class="form-label">Do you want to set min. length of stay?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="minLengthStay" id="yesMin" type="radio" label="Yes" checked={minLengthStay === "1"} onChange={(e) => setMinLengthStay(e.target.value)} />
                                    <input value="2" name="minLengthStay" id="noMin" type="radio" label="No" checked={minLengthStay === "2"} onChange={(e) => setMinLengthStay(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        {minLengthStay === "1" && (
                            <div className="days-list">
                                <div className='days-list-box'>
                                {daysOptions.map((list) => (
                                    <>
                                    <input
                                    className='day-btn'
                                    id={`minStay${list.id}`}
                                    type="radio"
                                    name='minStayValue'
                                    value={list.id}
                                    checked={list.id.toString() === minLengthStayValue}
                                    onChange={(e) => setMinLengthStayValue(e.target.value)}
                                    />
                                    <label key={list.id} htmlFor={`minStay${list.id}`} className='day-label' style={{ cursor: 'pointer', border:'none', borderRadius:'2px', boxShadow: ' rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px' }}>
                                        {list.short_title}
                                        <span style={{color:'#ccc'}}>|</span>
                                        <img
                                            src={
                                                list.id.toString() === minLengthStayValue
                                                ? "/assets/img/icons/sunny_day_white.png"
                                                : "/assets/img/icons/sunny_day.png"
                                            }
                                            className="img-fluid"
                                            alt={list.title}
                                        />
                                    </label>
                                    </>
                                ))}
                                </div>
                            </div>
                        )}
                        <div>
                            <p className='rdb-title'>Booking Window</p>
                        </div>
                        <div className="form-box">
                            <label class="form-label">Do you want to set Early Bird rule?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="earlyBirdRule" id="yesEarly" type="radio" label="Yes" checked={earlyBirdRule === "1"} onChange={(e) => setEarlyBirdRule(e.target.value)} />
                                    <input value="2" name="earlyBirdRule" id="noEarly" type="radio" label="No" checked={earlyBirdRule === "2"} onChange={(e) => setEarlyBirdRule(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="form-box">
                            <label class="form-label">Do you want to set Last Minute Deal?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="lastMinuteDea" id="yesMinute" type="radio" label="Yes" checked={lastMinuteDeal === "1"} onChange={(e) => setLastMinuteDeal(e.target.value)} />
                                    <input value="2" name="lastMinuteDea" id="noMinute" type="radio" label="No" checked={lastMinuteDeal === "2"} onChange={(e) => setLastMinuteDeal(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div>
                            <p className='rdb-title'>Day Based Availability</p>
                        </div>
                        <div className="form-box">
                            <label class="form-label">is this applicable on the selected days?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="selectedDays" type="radio" label="Yes" checked={selectedDays === "1"} onChange={(e) => setSelectedDays(e.target.value)} />
                                    <input value="2" name="selectedDays" type="radio" label="No" checked={selectedDays === "2"} onChange={(e) => setSelectedDays(e.target.value)} />
                                </div>
                            </div>
                            {selectedDays === "1" && (
                                <div className="days-list">
                                    <div style={{ display: "flex", gap: "10px" }}>
                                        {daysOfWeek.map((day) => (
                                            <>
                                            <input
                                            className='day-btn'
                                            id={day.id}
                                            type="checkbox"
                                            value={day.id}
                                            checked={daysList.includes(day.id)}
                                            onChange={() => handleDaySelection(day.id)}
                                            />
                                            <label key={day.id} htmlFor={day.id} className='day-label' style={{ cursor: "pointer" }}>
                                                <img
                                                    src={
                                                        daysList.includes(day.id)
                                                        ? "/assets/img/icons/calendar_white.png"
                                                        : "/assets/img/icons/calendar_black.png"
                                                    }
                                                    className="img-fluid"
                                                    alt={day.title}
                                                />
                                                {day.title}
                                            </label>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        
                        <div>
                            <p className='rdb-title'>Advance Settings</p>
                        </div>
                        <div className="form-box">
                            <label class="form-label">Do you want to blackout the offer for specific stay dates?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="blackoutOffer" type="radio" label="Yes" checked={blackoutOffer === "1"} onChange={(e) => setBlackoutOffer(e.target.value)} />
                                    <input value="2" name="blackoutOffer" type="radio" label="No" checked={blackoutOffer === "2"} onChange={(e) => setBlackoutOffer(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="form-box">
                            <label class="form-label">Do you want to make this offer non refundable?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="isRefundable" type="radio" label="Yes" checked={isRefundable === "1"} onChange={(e) => setIsRefundable(e.target.value)} />
                                    <input value="2" name="isRefundable" type="radio" label="No" checked={isRefundable === "2"} onChange={(e) => setIsRefundable(e.target.value)} />
                                </div>
                            </div>
                        </div>
                        <div className="form-box">
                            <label class="form-label">Do you want to offer pay at Hotel?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="isPayAtHotel" type="radio" label="Yes" checked={isPayAtHotel === "1"} onChange={(e) => setIsPayAtHotel(e.target.value)} />
                                    <input value="2" name="isPayAtHotel" type="radio" label="No" checked={isPayAtHotel === "2"} onChange={(e) => setIsPayAtHotel(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className="form-box">
                            <label class="form-label">Will the promotion be applicable for all rooms and rateplans?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="isApplicableAllRooms" type="radio" label="Yes" checked={isApplicableAllRooms === "1"} onChange={(e) => setIsApplicableAllRooms(e.target.value)} />
                                    <input value="2" name="isApplicableAllRooms" type="radio" label="No" checked={isApplicableAllRooms === "2"} onChange={(e) => setIsApplicableAllRooms(e.target.value)} />
                                </div>
                            </div>
                        </div>

                        <div className="form-box">
                            <label className="form-label">Where do you want to apply the promotion?</label>
                            <div className='custom-radio-box'>
                                <div class="radio">
                                    <input value="1" name="selectedRatePlan" type="radio" label="B2C" checked={selectedRatePlan === "1"} onChange={(e) => setSelectedRatePlan(e.target.value)} />
                                    <input value="2" name="selectedRatePlan" type="radio" label="Bundled Rates" checked={selectedRatePlan === "2"} onChange={(e) => setSelectedRatePlan(e.target.value)} />
                                </div>
                            </div>

                                { isApplicableAllRooms === "2" && selectedRatePlan === "1" && (
                                    <div className='prappbx'>
                                        <div>
                                            <label class="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRooms.familyRoom}
                                                    onChange={() => handleRoomSelection("familyRoom")}
                                                />
                                                <div class="checkmark">
                                                    <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                    <path
                                                        stroke-linejoin="round"
                                                        stroke-linecap="round"
                                                        stroke-width="3"
                                                        d="M20 6L9 17L4 12"
                                                    ></path>
                                                    </svg>
                                                </div>
                                                <span class="label">Family Room with Private Bathroom</span>
                                            </label>
                                            {selectedRooms.familyRoom && (
                                            <div className="sub-options">
                                                <label class="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        checked={roomSubOptions.familyRoom.EP}
                                                        onChange={() => handleSubOptionChange("familyRoom", "EP")}
                                                    />
                                                    <div class="checkmark">
                                                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                        <path
                                                            stroke-linejoin="round"
                                                            stroke-linecap="round"
                                                            stroke-width="3"
                                                            d="M20 6L9 17L4 12"
                                                        ></path>
                                                        </svg>
                                                    </div>
                                                    <span class="label">EP</span>
                                                </label>
                                                <label class="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        checked={roomSubOptions.familyRoom.CP}
                                                        onChange={() => handleSubOptionChange("familyRoom", "CP")}
                                                    />
                                                    <div class="checkmark">
                                                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                        <path
                                                            stroke-linejoin="round"
                                                            stroke-linecap="round"
                                                            stroke-width="3"
                                                            d="M20 6L9 17L4 12"
                                                        ></path>
                                                        </svg>
                                                    </div>
                                                    <span class="label">CP</span>
                                                </label>
                                            </div>
                                            )}
                                        </div>
                                        <div>
                                            <label class="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRooms.doubleRoom}
                                                    onChange={() => handleRoomSelection("doubleRoom")}
                                                />
                                                <div class="checkmark">
                                                    <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                    <path
                                                        stroke-linejoin="round"
                                                        stroke-linecap="round"
                                                        stroke-width="3"
                                                        d="M20 6L9 17L4 12"
                                                    ></path>
                                                    </svg>
                                                </div>
                                                <span class="label">Double Room with External Private Bathroom</span>
                                            </label>
                                            {selectedRooms.doubleRoom && (
                                            <div className="sub-options">
                                                <label class="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        checked={roomSubOptions.doubleRoom.EP}
                                                        onChange={() => handleSubOptionChange("doubleRoom", "EP")}
                                                    />
                                                    <div class="checkmark">
                                                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                        <path
                                                            stroke-linejoin="round"
                                                            stroke-linecap="round"
                                                            stroke-width="3"
                                                            d="M20 6L9 17L4 12"
                                                        ></path>
                                                        </svg>
                                                    </div>
                                                    <span class="label">EP</span>
                                                </label>
                                                <label class="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        checked={roomSubOptions.doubleRoom.CP}
                                                        onChange={() => handleSubOptionChange("doubleRoom", "CP")}
                                                    />
                                                    <div class="checkmark">
                                                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                        <path
                                                            stroke-linejoin="round"
                                                            stroke-linecap="round"
                                                            stroke-width="3"
                                                            d="M20 6L9 17L4 12"
                                                        ></path>
                                                        </svg>
                                                    </div>
                                                    <span class="label">CP</span>
                                                </label>
                                            </div>
                                            )}
                                        </div>
                                        <div>
                                            <label class="checkbox-wrapper">
                                                <input
                                                    type="checkbox"
                                                    checked={selectedRooms.tripleRoom}
                                                    onChange={() => handleRoomSelection("tripleRoom")}
                                                />
                                                <div class="checkmark">
                                                    <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                    <path
                                                        stroke-linejoin="round"
                                                        stroke-linecap="round"
                                                        stroke-width="3"
                                                        d="M20 6L9 17L4 12"
                                                    ></path>
                                                    </svg>
                                                </div>
                                                <span class="label">Triple Room with Private Bathroom</span>
                                            </label>
                                            {selectedRooms.tripleRoom && (
                                            <div className="sub-options">
                                                <label class="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        checked={roomSubOptions.tripleRoom.EP}
                                                        onChange={() => handleSubOptionChange("tripleRoom", "EP")}
                                                    />
                                                    <div class="checkmark">
                                                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                        <path
                                                            stroke-linejoin="round"
                                                            stroke-linecap="round"
                                                            stroke-width="3"
                                                            d="M20 6L9 17L4 12"
                                                        ></path>
                                                        </svg>
                                                    </div>
                                                    <span class="label">EP</span>
                                                </label>
                                                <label class="checkbox-wrapper">
                                                    <input
                                                        type="checkbox"
                                                        checked={roomSubOptions.tripleRoom.CP}
                                                        onChange={() => handleSubOptionChange("tripleRoom", "CP")}
                                                    />
                                                    <div class="checkmark">
                                                        <svg stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                                        <path
                                                            stroke-linejoin="round"
                                                            stroke-linecap="round"
                                                            stroke-width="3"
                                                            d="M20 6L9 17L4 12"
                                                        ></path>
                                                        </svg>
                                                    </div>
                                                    <span class="label">CP</span>
                                                </label>
                                            </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                        </div>

                        <button onClick={handleFormSubmit} className="btn btn-black hlbtn">Save Offer</button>
                        </div>
                    </div>
                </div>
                <div className='col-md-3'>
                    <div className="faq-sticky">
                        <div className='fas-desc-box'>
                        <div className='faq-title-box'>
                            <h4 className='faq-title-txt'>What to do here ?</h4>
                        </div>
                        <p className='faq-desc'>Spice up your bookings! Edit your offer to stand out and attract more guests.</p>
                        </div>
                        <hr></hr>
                        <div className='faq-txt-box'>
                        {/*<h4 className='faq-title-txt'>Frequently Asked Questions</h4>*/}
                        <img src='/assets/img/faq_promotion.gif' className='img-fluid' alt='' />
                        </div>
                        {faqs.map((faq, index) => (
                        <div key={index} className='faqs-list-section'>
                            <div
                            onClick={() => toggleAccordion(index)} className='faqs-qustn-box'
                            style={{
                                backgroundColor: activeIndex === index ? "#f7f7f7" : "#ffffff",
                                borderBottom: activeIndex === index ? "1px solid #ccc" : "none",
                            }}
                            >
                            {faq.question}
                            <i
                                className={`fas ${
                                activeIndex === index ? "fa-chevron-up" : "fa-chevron-down"
                                }`}
                                style={{
                                transition: "transform 0.3s ease",
                                }}
                            ></i>
                            </div>
                            {activeIndex === index && (
                            <div className='faqs-ans-box'>
                                {faq.answer}
                            </div>
                            )}
                        </div>
                        ))}
                    </div>
                </div>
            </div> 

          </div>
        </div>
      );
    };
    
    export default EditOffer;