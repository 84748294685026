import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../ScrollToTop ';
import { useParams, useNavigate } from 'react-router-dom';

const Checkout = () => {
  const { bookingId } = useParams();
  const [confirmationCode, setConfirmationCode] = useState(bookingId || '');
  const [bookingDetails, setBookingDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (bookingId) {
      handleSearch();
    }
  }, [bookingId]);

  const handleSearch = async () => {
    if (!confirmationCode.trim()) {
      setError('Please enter a valid confirmation code.');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');
    setBookingDetails(null);

    const hostData = getItemFromLocalStorage('hostProfile');
    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('confirmation_code', confirmationCode);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.getBookingDetails}`,
        data: formData,
      });

      if (response.data.status === 'success') {
        setBookingDetails(response.data.data);
      } else {
        setError(response.data.message || 'Booking not found.');
      }
    } catch (err) {
      setError('Error fetching booking details.');
    } finally {
      setLoading(false);
    }
  };

  const handleCheckout = async () => {
    if (!bookingDetails) {
      setError('No booking details available to process checkout.');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    const hostData = getItemFromLocalStorage('hostProfile');
    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('stay_booking_id', bookingDetails.stay_booking_id);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.saveCheckout}`,
        data: formData,
      });

      if (response.data.status === 'success') {
        setSuccess('Checkout successful!');
        setBookingDetails(null);
        navigate('/home');
      } else {
        setError(response.data.message || 'Failed to process checkout.');
      }
    } catch (err) {
      setError('Error processing checkout.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <Helmet>
        <title>Manage Check-out</title>
        <meta name="description" content="Manage booking check-out for guests." />
      </Helmet>
      <ScrollToTop />
      <div className="checkout-page">
        <div className="clp-header">
          <h1 className="ws-title">Manage Check-out</h1>
        </div>

        <div className="row booking-search-box">
          <div className="col-md-10">
            <input
              type="text"
              className="form-control"
              placeholder="Enter Confirmation Code"
              style={{ height: '50px' }}
              value={confirmationCode}
              onChange={(e) => setConfirmationCode(e.target.value)}
            />
          </div>
          <div className="col-md-2">
            <button
              onClick={handleSearch}
              className="btn btn-black hlbtn w-100"
              disabled={loading}
            >
              {loading ? 'Searching...' : 'Search'}
            </button>
          </div>
        </div>

        {error && <p className="error text-danger">{error}</p>}
        {success && <p className="success text-success">{success}</p>}

        {bookingDetails && (
          <div className="booking-details">
            <p className="blttxt">Booking Details</p>
            
            <div className='bdch-table'>
              <table className="table mb-0">
                <thead>
                  <tr>
                    <th>
                      <div className='bdthd'>
                        <img src={'/assets/img/icons/room_icon_2.png'} alt="Profile" className="pointer-icon" />
                        <span>Room No</span>
                      </div>
                    </th>
                    <th>
                      <div className='bdthd'>
                        <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                        <span>Guest Name</span>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {bookingDetails.guests.map((guest, index) => (
                    <tr key={index}>
                      <td>
                        <div className='bdtdd'>
                          <img src={'/assets/img/icons/room_icon_2.png'} alt="Profile" className="pointer-icon" />
                          <span>{guest.room_no}</span>
                        </div>
                      </td>
                      <td>
                        <div className='bdtdd'>
                          <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
                          <span>{guest.checkin_guest_name}</span>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>

            <button
              onClick={handleCheckout}
              disabled={loading}
              className="btn btn-black hlbtn"
            >
              {loading ? 'Processing...' : 'Checkout'}
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkout;
