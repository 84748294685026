import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Loader from '../components/Loader';

const RoomsPrice = () => {
  const { listingId } = useParams();
  const [rooms, setRooms] = useState([]);
  const [weekDates, setWeekDates] = useState([]);
  const [weekDatesISO, setWeekDatesISO] = useState([]);
  const [selectedWeek, setSelectedWeek] = useState(new Date());
  const [daywisePrice, setDaywisePrice] = useState({});
  const [roomwisePrice, setRoomwisePrice] = useState({});
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [newPrice, setNewPrice] = useState('');
  const [selectedPriceDate, setSelectedPriceDate] = useState(null);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [refreshKey, setRefreshKey] = useState(0);
  const [priceStatus, setPriceStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
      setLoading(true);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getRoomList}`,
          data: formData,
        });

        if (response.data.status === 'found') {
          setRooms(response.data.rooms);
        } else {
          toast.error('An error occurred while fetching room data.');
        }
      } catch (err) {
        console.log(err.message);
      } finally {
        setLoading(false);
      }
    };

    const fetchDaywisePrice = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);

      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getDaywisePrice}`,
          data: formData,
        });

        if (response.data.status === 'found') {
            const roomPrices = response.data.day_wise_prices.reduce((acc, roomPrice) => {
                acc[roomPrice.price_date] = {
                    listing_price: parseFloat(roomPrice.listing_price),
                    status: roomPrice.price_date_status,
                    hotel_room_id:roomPrice.hotel_room_id,
                };
                return acc;
            }, {});
            setRoomwisePrice(roomPrices);

            const prices = response.data.day_wise_prices.reduce((acc, price) => {
                const roomId = price.hotel_room_id;
                acc[roomId] = acc[roomId] || {};
                acc[roomId][price.price_date] = {
                listing_price: parseFloat(price.listing_price),
                status: price.price_date_status,
                };
                return acc;
           }, {});
           setDaywisePrice(prices);
           //console.log(prices);
        } else {
          toast.error('An error occurred while fetching daywise prices.');
        }
      } catch (err) {
        console.log(err);
      }
    };

    fetchListingData();
    fetchDaywisePrice();
  }, [listingId]);

  const fetchDaywisePrice = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('listing_id', listingId);

    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        url: `${params.baseURL}${params.endpoints.getDaywisePrice}`,
        data: formData,
      });

      if (response.data.status === 'found') {
          const roomPrices = response.data.day_wise_prices.reduce((acc, roomPrice) => {
              acc[roomPrice.price_date] = {
                  listing_price: parseFloat(roomPrice.listing_price),
                  status: roomPrice.price_date_status,
                  hotel_room_id:roomPrice.hotel_room_id,
              };
              return acc;
          }, {});
          setRoomwisePrice(roomPrices);

          const prices = response.data.day_wise_prices.reduce((acc, price) => {
              const roomId = price.hotel_room_id;
              acc[roomId] = acc[roomId] || {};
              acc[roomId][price.price_date] = {
              listing_price: parseFloat(price.listing_price),
              status: price.price_date_status,
              };
              return acc;
         }, {});
         setDaywisePrice(prices);
         //console.log(prices);
      } else {
        toast.error('An error occurred while fetching daywise prices.');
      }
    } catch (err) {
      console.log(err);
    }
  };

  const generateWeekDays = (startDate) => {
    const week = [];
    const weekISO = [];
    const currentDate = new Date(startDate);
    currentDate.setDate(currentDate.getDate() - currentDate.getDay()); // Start from Sunday

    for (let i = 0; i < 7; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() + i);
      week.push(date.toLocaleDateString('en-GB', { day: 'numeric', month: 'short' })); // Format: 21st Dec
      weekISO.push(date.toISOString().split('T')[0]); // Format: YYYY-MM-DD
    }

    setWeekDates(week);
    setWeekDatesISO(weekISO);
  };

  useEffect(() => {
    generateWeekDays(selectedWeek);
  }, [selectedWeek]);

  // Handle week selection change
  const handleWeekChange = (e) => {
    setSelectedWeek(new Date(e.target.value));
  };

  useEffect(() => {
    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const formatDate = (date) => {
      const day = date.getUTCDate(); // Use getUTCDate
      const monthNames = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
      ];
      const month = monthNames[date.getUTCMonth()]; // Use getUTCMonth
      return `${day}${getOrdinalSuffix(day)} ${month}`;
    };

    const today = new Date();
    const dayOfWeek = today.getUTCDay(); // Use getUTCDay

    // Calculate the start of the week (Sunday)
    const startOfWeek = new Date(today);
    startOfWeek.setUTCDate(today.getUTCDate() - dayOfWeek); // Move back to Sunday

    // Now get the 7 days of the week, from Sunday to Saturday
    const dates = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startOfWeek);
      date.setUTCDate(startOfWeek.getUTCDate() + i); // Adjust date for each day in the week
      return formatDate(date); // Human-readable format (e.g., 15th Dec)
    });

    // Get the ISO format dates (YYYY-MM-DD) for the same week
    const datesISO = Array.from({ length: 7 }, (_, i) => {
      const date = new Date(startOfWeek);
      date.setUTCDate(startOfWeek.getUTCDate() + i); // Adjust date for each day in the week
      return date.toISOString().split('T')[0]; // YYYY-MM-DD format
    });

    // Set the state for both human-readable and ISO format dates
    setWeekDates(dates);  // Human-readable dates for display
    setWeekDatesISO(datesISO);  // ISO format dates for processing

  }, []);

  const openCalendarPage = (room) => {
    navigate(`/calendar-view/${room.stay_listing_id}/${room.hotel_room_id}`);
  };

  // Handle price box click to open the popup
  const handlePriceBoxClick = (roomId, price, date, status) => {
    setSelectedPrice({ price, date, status });
    setShowPopup(true);
    setSelectedRoomId(roomId);
    setNewPrice(price);
    setPriceStatus(status);
    setSelectedPriceDate(new Date(date));
  };

  // Close popup
  const closePopup = () => {
    setShowPopup(false);
    setSelectedPrice(null);
  };

  const parseDate = (dateString) => {
    const currentYear = new Date().getFullYear();
    const cleanedDate = dateString.replace(/(st|nd|rd|th)/, '');
    return new Date(`${cleanedDate} ${currentYear}`);
  };

  const handlePriceUpdate = async () => {
    if (!newPrice || isNaN(newPrice)) {
      toast.error('Please enter a valid price.');
      return;
    }

    if (selectedPriceDate < new Date()) {
      toast.error('You cannot set a price for a past date.');
      return;
    }

    const hostData = getItemFromLocalStorage('hostProfile');
    const listingGuestFees = (parseFloat(newPrice) * 0.10).toFixed(2);

    // Start by constructing the updated pricing data with the selected date
    const updatedPricingData = [
        {
            listing_guest_fees: listingGuestFees,
            listing_host_fees: 0.0,
            listing_price: parseFloat(newPrice),
            price_date: selectedPriceDate.toISOString().split('T')[0],
            price_date_status: parseInt(priceStatus, 10),
            hotel_room_id: selectedRoomId,
        },
    ];

    // Include the existing daywise prices for other dates
    Object.keys(roomwisePrice).forEach((priceDate) => {
        if (priceDate !== selectedPriceDate.toISOString().split('T')[0]) {
            const priceValue = roomwisePrice[priceDate];
            const normalizedPrice = typeof priceValue === 'object' ? priceValue.listing_price : priceValue;
            const hotelRoomId = typeof priceValue === 'object' ? priceValue.hotel_room_id : selectedRoomId;

            updatedPricingData.push({
                listing_guest_fees: (normalizedPrice * 0.10).toFixed(2),
                listing_host_fees: 0.0,
                listing_price: normalizedPrice,
                price_date: priceDate,
                price_date_status: 1,
                hotel_room_id: hotelRoomId,
            });
        }
    });

    // Prepare data to send to the backend
    const data = JSON.stringify({ pricing: updatedPricingData });

    let formData = new FormData();
    formData.append('token', hostData.host_access_token);
    formData.append('listing_id', listingId);
    formData.append('data', data);

    try {
        const response = await axios({
            method: 'post',
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            url: `${params.baseURL}${params.endpoints.updateDaywisePrice}`,
            data: formData,
        });

        if (response.data.status === 'done') {
            const updatedDaywisePrice = { ...daywisePrice };

            // Update the selected date with the new price
            const selectedDateStr = selectedPriceDate.toISOString().split('T')[0];
            updatedDaywisePrice[selectedDateStr] = {
                listing_price: parseFloat(newPrice),
                status: updatedDaywisePrice[selectedDateStr]?.status,
            };

            // Ensure other daywise prices remain the same
            Object.keys(daywisePrice).forEach((priceDate) => {
                if (priceDate !== selectedDateStr) {
                    updatedDaywisePrice[priceDate] = {
                        listing_price: daywisePrice[priceDate],
                        status: updatedDaywisePrice[priceDate]?.status,
                    };
                }
            });

            //setDaywisePrice(updatedDaywisePrice);
            setRefreshKey((prevKey) => prevKey + 1);
            await fetchDaywisePrice();
            closePopup();
        } else {
            toast.error('Failed to update the price.');
        }
    } catch (error) {
        toast.error('Error updating price.');
    }
  };

  return (
    <div style={{ padding: '20px' }}>
      <Helmet>
        <title>Rooms Prices</title>
      </Helmet>

      {loading && <Loader />}

      <div className="clp-header">
        <h4 className="ws-title itwac"> 
          <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" />
          <span>Rooms Price</span>
        </h4>
      </div>

      <div className='swsdb' style={{ marginBottom: '20px' }}>
        <label className='text-uppercase' style={{marginRight:'10px'}}>
            <img src={'/assets/img/icons/pointer_icon.svg'} alt="Profile" className="pointer-icon" style={{marginRight:'10px'}} />
            <span>Select Week Start Date:</span> 
        </label>
        
        <DatePicker selected={selectedWeek} onChange={(date) => setSelectedWeek(date)} />
        {/*<button onClick={() => generateWeekDays(selectedWeek)}>Submit</button>*/}
      </div>

      <div key={refreshKey} className='dayprice-container' style={styles.container}>
        {/* Date Headers */}
        <div style={styles.dateRow}>
          <div id='style-4' className='room-name' style={styles.roomNameHeader}>
            <img src="/assets/img/icons/room_icon_2.png" class="img-fluid" />
            <span style={{color:'#ccc'}}>|</span>
            Room Name
          </div>
          {weekDates.map((date, index) => {
            const parsedDate = parseDate(date);
            const isPast = parsedDate < new Date().setHours(0, 0, 0, 0);
            //console.log(parsedDate);
            return (
              <div
                key={index}
                style={{
                  ...styles.dateBox,
                  backgroundColor: isPast ? '#f0f0f0' : 'white',
                  color: isPast ? '#a0a0a0' : 'black',
                  pointerEvents: isPast ? 'none' : 'auto',
                  opacity: isPast ? 0.6 : 1,
                }}
                className='room-price-days'
              >
                <img src="/assets/img/icons/calendar_black.png" class="img-fluid" />
                <span>{date}</span>
              </div>
            );
          })}
          <div className="room-action">Action</div>
        </div>

        {/* Prices for Each Room */}
        {loading ? (
          <Loader />
        ) : rooms.length > 0 ? (
          rooms.map((room) => (
            <div key={room.hotel_room_id} style={styles.priceRow}>
              <div id="style-4" className="room-name" style={styles.roomName}>
                <img src="/assets/img/icons/room_icon_2.png" className="img-fluid" />
                <span style={{ color: '#ccc' }}>|</span>
                {room.room_name || 'Unnamed Room'}
              </div>
              {weekDatesISO.map((isoDate, index) => {
                const price = daywisePrice[room.hotel_room_id]?.[isoDate]?.listing_price;
                const status = daywisePrice[room.hotel_room_id]?.[isoDate]?.status;
                const roomId = room.hotel_room_id;

                const isPastDate = new Date(isoDate) < new Date().setHours(0, 0, 0, 0);

                return (
                  <div
                    key={index}
                    style={{
                      ...styles.priceBox,
                      backgroundColor: isPastDate ? '#f0f0f0' : 'white',
                      color: isPastDate ? '#a0a0a0' : 'black',
                      pointerEvents: isPastDate ? 'none' : 'auto',
                      opacity: isPastDate ? 0.6 : 1,
                    }}
                    className="room-price-section"
                    onClick={
                      !isPastDate ? () => handlePriceBoxClick(roomId, price, isoDate, status) : undefined
                    }
                  >
                    {price ? (
                      <div
                        className="room-price-box"
                        style={{
                          color: status === '2' ? 'red' : 'inherit',
                        }}
                      >
                        <img src="/assets/img/icons/calendar_rupee.svg" className="img-fluid" />
                        <span style={{ color: '#ccc' }}>|</span>
                        <span style={{ fontSize: '20px' }}>{price}</span>
                      </div>
                    ) : (
                      <div className="room-price-box">
                        <img src="/assets/img/icons/calendar_rupee.svg" className="img-fluid" />
                        <span style={{ color: '#ccc' }}>|</span>
                        <span style={{ fontSize: '20px' }}>0.00</span>
                      </div>
                    )}
                  </div>
                );
              })}
              <div className="room-action" onClick={() => openCalendarPage(room)}>
                <i className="fas fa-pencil-alt"></i>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center mb-3">
            <img src="/assets/img/no_room_available.png" alt="" className="img-fluid" />
          </div>
        )}

      </div>

        {/* Popup Modal */}
        {showPopup && (
            <div style={styles.popupOverlay}>
                <div style={styles.popup}>
                    <div className='popup-text-box'>
                      <img src={'/assets/img/price_room.gif'} alt="Profile" className="w-100" />
                    </div>
                    <div className='popup-text-box'>
                      <div>
                        <img src={'/assets/img/icons/calendar_black.png'} alt="Profile" className="pointer-icon" />
                      </div>
                      <p><span>Date:</span> {selectedPrice?.date}</p>
                    </div>
                    <div className='popup-text-box'>
                      <div>
                        <img src={'/assets/img/icons/calendar_rupee.svg'} alt="Profile" className="pointer-icon" />
                      </div>
                      <p><span>Current Price:</span> ₹{selectedPrice?.price || '0.00'}</p>
                    </div>
                    <div className='popup-text-box'>
                      <div className='w-100 mb-2'>
                          <label htmlFor="newPrice" className='mb-2'>Enter New Price:</label>
                          <input
                              className='form-control'
                              type="number"
                              id="newPrice"
                              value={newPrice}
                              onChange={(e) => setNewPrice(e.target.value)}
                              style={{border:'1px solid #ccc'}}
                          />
                      </div>
                    </div>
                    <div className='popup-text-box'>
                      <div class="radio">
                        <input value="1" name="priceStatus" id="open" type="radio" label="Open" checked={priceStatus === "1"} onChange={(e) => setPriceStatus(e.target.value)} />
                        <input value="2" name="priceStatus" id="close" type="radio" label="Close" checked={priceStatus === "2"} onChange={(e) => setPriceStatus(e.target.value)} />
                      </div>
                    </div>
                    <button onClick={handlePriceUpdate} className='btn btn-dark text-uppercase w-100 mb-2' style={{fontSize:'14px'}}>Update</button>
                    <button onClick={closePopup} className='room-popup-close-btn'><i class="fas fa-times"></i></button>
                </div>
            </div>
        )}

    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  dateRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    marginBottom: '10px',
  },
  roomNameHeader: {
    minWidth: '150px',
    textAlign: 'left',
    backgroundColor: '#fff',
    color: '#000',
    border: '2px solid #ccc',
    padding: '10px 15px',
    borderRadius: '2px',
  },
  dateBox: {
    flex: 1,
    backgroundColor: '#fff',
    color: '#000',
    border: '2px solid #ccc',
    textAlign: 'center',
    padding: '10px',
    borderRadius: '2px',
  },
  priceRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
    alignItems: 'center',
  },
  roomName: {
    minWidth: '150px',
    textAlign: 'left',
    backgroundColor: '#fff',
    color: '#000',
    border: '2px solid #ccc',
    padding: '10px 15px',
    borderRadius: '2px',
  },
  priceBox: {
    flex: 1,
    backgroundColor: '#f5f5f5',
    padding: '10px',
    textAlign: 'center',
    cursor: 'pointer',
    borderRadius: '2px',
    minWidth: '100px',
  },
  popupOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  popup: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '2px',
    width: '400px',
    textTransform: 'uppercase',
    position: 'relative',
    border: '5px solid #6d6d6d',
  },
  closeButton: {
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px',
    border: 'none',
    borderRadius: '2px',
    cursor: 'pointer',
  }
};

export default RoomsPrice;
