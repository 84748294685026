import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../../config/Params';
import { getItemFromLocalStorage } from '../../utils/storageUtils';
import { toast } from 'react-toastify';
import { useParams, useNavigate } from "react-router-dom";

const Category = () => {
  const { listingId } = useParams();
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [propertyItem, setPropertyItem] = useState(null);
  const [categoryId, setCategoryId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategories = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getHotelCategories}`,
          data: formData,
        });
  
        if (response.data.status === 'category') {
          setCategories(response.data.property_cat_list);
          setLoading(false);
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        toast.error(err.message);
        setLoading(false);
      }
    };
  
    const fetchListingData = async () => {
      const hostData = getItemFromLocalStorage('hostProfile');
      let formData = new FormData();
      formData.append('token', hostData.host_access_token);
      formData.append('listing_id', listingId);
  
      try {
        const response = await axios({
          method: 'post',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          url: `${params.baseURL}${params.endpoints.getListingData}`,
          data: formData,
        });
  
        if (response.data.status === 'ok') {
          setPropertyItem(response.data.updated_property_item);
          setCategoryId(String(response.data.updated_property_item.hotel_category_id));
        } else {
          toast.error('An error occurred while fetching data.');
        }
      } catch (err) {
        console.log(err.message);
      }
    };
  
    fetchCategories();
    fetchListingData();
  }, []);
  

  const saveListing = async () => {
    const hostData = getItemFromLocalStorage('hostProfile');
    const listing_id = listingId;
    const ts = Date.now();
  
    const categoryInfo = categories.find(cat => cat.category_id === parseInt(categoryId));
  
    const updatedPropertyItem = {
      hotel_category_id: categoryId,
    };
  
    let catData = new FormData();
    catData.append('token', hostData.host_access_token);
    catData.append('listing_id', listing_id);
    catData.append('data', JSON.stringify(updatedPropertyItem)); 
    catData.append('ts', ts); 
  
    try {
      const response = await axios({
        method: 'post',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: `${params.baseURL}${params.endpoints.updateListing}`,
        data: catData,
      });
  
      if (response.data.status === 'done') {
        navigate(`/hotel/onboarding/${listingId}`);
      } else {
        toast.error('Failed to save data');
      }
    } catch (err) {
      toast.error('An error occurred while saving the listing');
    }
  };

  const handlePrev = () => {
    navigate(`/my-listings`);
  };
  
  const handleNext = () => {
    saveListing();
  };

  const handleCategoryChange = (e) => {
    setCategoryId(e.target.value);
  };

  return (
    <div>
      <div className='row'>     
        <div className='col-md-12'>
          <h4 className='st1-title'>Which hotel type would you like to list?</h4>
          {loading ? <p>Loading...</p> : null}
          <div className='st1-desc-box'>
            {categories.length > 0 ? (
              <div className='cat-box'>
                {categories.map((data) => (
                  <label className='cat-label hotel-label' key={data.category_id}>
                    <input
                      type="radio"
                      name="category"
                      value={String(data.category_id)}
                      checked={categoryId === String(data.category_id)}
                      onChange={handleCategoryChange}
                    />
                    <span>
                      <img
                        src={data.category_image_url || 'https://stcontent.blr1.digitaloceanspaces.com/stays/1722843850_stct.png'}
                        alt={data.category_name || 'Untitled Listing'}
                        className='cat-img'
                      />
                      <p>{data.category_name}</p>
                    </span>
                  </label>
                ))}
              </div>
            ) : (
              <p>No data found</p>
            )}
          </div>

          <div className='clp-footer'>
            <button className='btn btn-blank' onClick={handlePrev}>Back</button>
            <button className='btn btn-black' onClick={handleNext}>Next</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Category;
