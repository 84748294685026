import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { toast } from 'react-toastify';

function PendingDocumentsPopup({ show, onClose, data }) {

    const [showDocumentPopup, setShowDocumentPopup] = useState(false);
    const [selectedDocumentType, setSelectedDocumentType] = useState('');
    const [uploadedFile, setUploadedFile] = useState(null);
    const [currentVerification, setCurrentVerification] = useState(null);
    const [logId, setLogId] = useState('');
    const [fileName, setFileName] = useState('');
    const [verificationList, setVerificationList] = useState(data.verification_list || []);

    useEffect(() => {
        setVerificationList(data.verification_list || []);
    }, [data]);

    const handleDocumentClick = (verification) => {
        setCurrentVerification(verification);
        setLogId(verification.log_id)
        setShowDocumentPopup(true);
        setSelectedDocumentType('');
        setUploadedFile(null);
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setUploadedFile(file);
        if (file) {
            setFileName(file.name);
        }
    };

    const handleSubmit = async () => {
        const hostData = getItemFromLocalStorage('hostProfile');

        const formData = new FormData();

        formData.append('token', hostData.host_access_token);
        formData.append('listing_id', logId);
        formData.append('id', selectedDocumentType); 
        formData.append('side', '1');  

        formData.append('docnull', uploadedFile);

        try {
            const response = await axios({
                method: 'post',
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                url: `${params.baseURL}${params.endpoints.verifyDocument}`,
                data: formData
            });
      
            if (response.data.status === 'completed') {
                toast.success('Your document updated successfully.');
                setVerificationList((prevList) => 
                    prevList.map((verification) => 
                        verification.log_id === logId 
                        ? { ...verification, verification_status: 4, verification_status_text: 'under review' }
                        : verification
                    )
                );
                setShowDocumentPopup(false);
                setUploadedFile(null);
                setLogId('');
            } else if (response.data.status === 'error') {
                const errorMessage = response.data.list.join(", ");
                toast.error(errorMessage);
            } else {
                toast.error('Error in saving data.');
            }
        } catch (err) {
            toast.error('An error occurred while saving data.');
        }
    };

    if (!show) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className='popup-header'>
                    <span>Document verification</span>
                    <button onClick={onClose} className="cross-btn">X</button>
                </div>
                <h2 className='pdpld-title'>Key details to take care of</h2>
                <div className='pdpldb'>
                    <img className='pdpld-img' src={data.cover_photo} alt='' />
                    <div>
                        <p>{data.listing_title}</p>
                        <p>{data.property_address}</p>
                    </div>
                </div>

                <div className="pdpdls">
                    {verificationList.map((verification) => {
                        const isDisabled = verification.verification_status === 4;

                        return (
                            <div
                                key={verification.log_id}
                                className={`plvs ${isDisabled ? 'disabled' : ''}`}
                                onClick={() => !isDisabled && handleDocumentClick(verification)}
                            >
                                <div className='plvsts'>
                                    <p>{verification.verification_title}</p>
                                    <p><i className="fas fa-angle-right"></i></p>
                                </div>
                                <div>
                                    <p>{verification.verification_desc}</p>
                                </div>
                                <p>{verification.verification_status_text || 'Pending'}</p>
                            </div>
                        );
                    })}
                </div>

                {showDocumentPopup && (
                    <div className="popup-overlay">
                        <div className="popup-content">
                            <div className='popup-header'>
                                <span>Document verification</span>
                                <button onClick={() => setShowDocumentPopup(false)} className="cross-btn">X</button>
                            </div>
                            <h3>{currentVerification.instruction_title}</h3>
                            <p>{currentVerification.instruction_desc}</p>

                            <div className="plans">
                                {currentVerification.id_types.map((type) => (
                                    <label class="plan" key={type.id}>
                                        <input type="radio" name="document"
                                            value={type.id} 
                                            checked={selectedDocumentType === type.id}
                                            onChange={() => setSelectedDocumentType(type.id)}
                                        />
                                        <div class="plan-content">
                                            <img loading="lazy" src={"/assets/img/icons/doc_icon_2.png"} alt="" />
                                            <div class="plan-details">
                                                <span>{type.title}</span>
                                            </div>
                                        </div>
                                  </label>
                                ))}
                            </div>

                            <label class="Documents-btn">
                                <span class="folderContainer">
                                    <svg
                                    class="fileBack"
                                    width="146"
                                    height="113"
                                    viewBox="0 0 146 113"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M0 4C0 1.79086 1.79086 0 4 0H50.3802C51.8285 0 53.2056 0.627965 54.1553 1.72142L64.3303 13.4371C65.2799 14.5306 66.657 15.1585 68.1053 15.1585H141.509C143.718 15.1585 145.509 16.9494 145.509 19.1585V109C145.509 111.209 143.718 113 141.509 113H3.99999C1.79085 113 0 111.209 0 109V4Z"
                                        fill="url(#paint0_linear_117_4)"
                                    ></path>
                                    <defs>
                                        <linearGradient
                                        id="paint0_linear_117_4"
                                        x1="0"
                                        y1="0"
                                        x2="72.93"
                                        y2="95.4804"
                                        gradientUnits="userSpaceOnUse"
                                        >
                                        <stop stop-color="#a040fd"></stop>
                                        <stop offset="1" stop-color="#5f41f3"></stop>
                                        </linearGradient>
                                    </defs>
                                    </svg>
                                    <svg
                                    class="filePage"
                                    width="88"
                                    height="99"
                                    viewBox="0 0 88 99"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <rect width="88" height="99" fill="url(#paint0_linear_117_6)"></rect>
                                    <defs>
                                        <linearGradient
                                        id="paint0_linear_117_6"
                                        x1="0"
                                        y1="0"
                                        x2="81"
                                        y2="160.5"
                                        gradientUnits="userSpaceOnUse"
                                        >
                                        <stop stop-color="white"></stop>
                                        <stop offset="1" stop-color="#686868"></stop>
                                        </linearGradient>
                                    </defs>
                                    </svg>

                                    <svg
                                    class="fileFront"
                                    width="160"
                                    height="79"
                                    viewBox="0 0 160 79"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    >
                                    <path
                                        d="M0.29306 12.2478C0.133905 9.38186 2.41499 6.97059 5.28537 6.97059H30.419H58.1902C59.5751 6.97059 60.9288 6.55982 62.0802 5.79025L68.977 1.18034C70.1283 0.410771 71.482 0 72.8669 0H77H155.462C157.87 0 159.733 2.1129 159.43 4.50232L150.443 75.5023C150.19 77.5013 148.489 79 146.474 79H7.78403C5.66106 79 3.9079 77.3415 3.79019 75.2218L0.29306 12.2478Z"
                                        fill="url(#paint0_linear_117_5)"
                                    ></path>
                                    <defs>
                                        <linearGradient
                                        id="paint0_linear_117_5"
                                        x1="38.7619"
                                        y1="8.71323"
                                        x2="66.9106"
                                        y2="82.8317"
                                        gradientUnits="userSpaceOnUse"
                                        >
                                        <stop stop-color="#a040fd"></stop>
                                        <stop offset="1" stop-color="#5251f2"></stop>
                                        </linearGradient>
                                    </defs>
                                    </svg>
                                </span>
                                <input type="file" className='d-none' onChange={handleFileChange} />
                                <p class="text">Choose a file</p>
                            </label>

                            {fileName && <div className="file-name">{fileName}</div>}
                            
                            <div className='form-group'>
                                <button onClick={handleSubmit} className='btn wt-btn'>Submit</button>
                           </div>
                            
                        </div>
                    </div>
                )}

            </div>
        </div>
    );
}

export default PendingDocumentsPopup;
