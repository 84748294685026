import React, { useState, useEffect } from 'react';
import axios from 'axios';
import params from '../config/Params';
import { getItemFromLocalStorage } from '../utils/storageUtils';
import { Helmet } from 'react-helmet';

const MyListings = () => {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchListings = async () => {
            const hostData = getItemFromLocalStorage('hostProfile');
            let formData = new FormData();
            formData.append('token', hostData.host_access_token);

            try {
                const response = await axios({
                    method: 'post',
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    },
                    url: `${params.baseURL}${params.endpoints.getMyListings}`,
                    data: formData
                });
            
                if (response.data.items && Array.isArray(response.data.items)) {
                    setListings(response.data.items);
                    setLoading(false);
                } else {
                    setError('An error occurred while fetching data.');
                }
            } catch (err) {
                setError(err);
                setLoading(false);
            }
        };

        fetchListings();
    }, []);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>Error loading listings: {error.message}</p>;
    }

    return (
        <div>
            <Helmet>
                <title>My Listing</title>
                <meta name="description" content="" />
            </Helmet>
            <div className='yl-header'>
                <div>
                    <h1 className='ws-title m-0'>Your Listings</h1>
                    <p className='ws-desc m-0'>Your hosting HQ— create, view, and own your listings with ease here.</p>
                </div>
                <a href='/create-listing/overview'>
                    <span className='yl-create-btn'>
                        <i class="fas fa-plus"></i>
                    </span>
                </a>
            </div>

            {listings.length > 0 ? (
                <div className='row'>
                    {listings.map((listing) => (
                        <div className='col-md-4 lb mb-3' key={listing.stay_listing_id}>
                            <a href={`/hotel/edit-listing/${listing.stay_listing_id}`}>
                                <div className='lcibox'>
                                    <div className='listing-cover-image'>
                                        <img
                                            src={listing.cover_photo ? listing.cover_photo : '/assets/img/default_listing.jpg'}
                                            alt={listing.listing_title ? listing.listing_title : 'Untitled Listing'}
                                        />
                                    </div>
                                    <div className='lsbox'>
                                        <span>In Progress</span>
                                    </div>
                                </div>
                                <h4>{listing.listing_title ? listing.listing_title : ""} </h4>
                                <p>{listing.property_location_info ? listing.property_location_info : null}</p>
                            </a>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No listings found</p>
            )}
        </div>
    );
};

export default MyListings;
